import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/users`;

const UsersService = {
  userExists: (emailAddress) =>
    axios.get(`${BASE_URL}/exists`, {
      params: {
        emailAddress,
      },
    }),
  signup: (user) => axios.post(`${BASE_URL}/signup`, user),
  sendRequestToCompany: (body) =>
    axios.post(`${BASE_URL}/sendRequestToCompany`, body),
  login: (user) => axios.post(`${BASE_URL}/login`, user),
  logout: () => axios.post(`${BASE_URL}/logout`),
  changePassword: (body) => axios.post(`${BASE_URL}/changePassword`, body),
  getEmployees: (employerId) =>
    axios.post(`${BASE_URL}/getEmployees`, employerId),
  getEmployerDetails: (emailAddress) =>
  axios.post(`${BASE_URL}/getEmployerDetails`, emailAddress),
  updateAccountInformation: (body) =>
    axios.post(`${BASE_URL}/updateAccountInformation`, body),
  updateTimesheetSettings: (body) =>
    axios.post(`${BASE_URL}/updateTimesheetSettings`, body),
  updateTabletViewSettings: (body) =>
    axios.post(`${BASE_URL}/updateTabletViewSettings`, body),
  updateVehiclesSettings: (body) =>
    axios.post(`${BASE_URL}/updateVehiclesSettings`, body),
  updateNotifications: (body) =>
    axios.post(`${BASE_URL}/updateNotifications`, body),
  updateEmailAddress: (body) =>
    axios.post(`${BASE_URL}/updateEmailAddress`, body),
  updateIsAdmin: (body) => axios.post(`${BASE_URL}/updateIsAdmin`, body),
  updateIsOvertime: (body) => axios.post(`${BASE_URL}/updateIsOvertime`, body),
  updateExpectedHoursPerWeek: (body) =>
    axios.post(`${BASE_URL}/updateExpectedHoursPerWeek`, body),
  updatePublicHolidayMultiplier: (body) =>
    axios.post(`${BASE_URL}/updatePublicHolidayMultiplier`, body),
  updateWage: (body) => axios.post(`${BASE_URL}/updateWage`, body),
  updateIsSubscribed: (body) =>
    axios.post(`${BASE_URL}/updateIsSubscribed`, body),
  createEmployee: (body) => axios.post(`${BASE_URL}/createEmployee`, body),
  importEmployee: (body) => axios.post(`${BASE_URL}/importEmployee`, body),
  removeEmployee: (body) => axios.post(`${BASE_URL}/removeEmployee`, body),
  generateNewEmployeeCode: (body) =>
    axios.post(`${BASE_URL}/generateNewEmployeeCode`, body),
  leaveCompany: (body) => axios.post(`${BASE_URL}/leaveCompany`, body),
  uploadCompanyLogo: (body) =>
    axios.post(`${BASE_URL}/uploadCompanyLogo`, body),
  switchToContractor: (body) =>
    axios.post(`${BASE_URL}/switchToContractor`, body),
  switchToEmployee: (body) => axios.post(`${BASE_URL}/switchToEmployee`, body),
  getUsers: (body) => axios.post(`${BASE_URL}/getUsers`, body),
  getUsersCount: (body) => axios.post(`${BASE_URL}/getUsersCount`, body),
};

export default UsersService;
