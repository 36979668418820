import { useState, useEffect } from 'react';

import { ReviewsService } from '../services';

const useReviews = ({ vehicleId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const getReviews = async () => {
      try {
        const response = await ReviewsService.get({
          params: { vehicleId },
        });

        setReviews(response.data);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    };

    getReviews();
  }, []);

  return { isLoading, reviews };
};

export default useReviews;
