import React, { useState, useRef, useContext } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { UserContext } from '../../contexts';
import { Card, Icon, IconButton, Text } from '../../components/elements';
import { iconButtonTypes, userTypes } from '../../globals';
import { useOnClickOutside } from '../../hooks';
import IconLogoWhite from '../../static/images/Logo/icon-white.png';

const Navbar = ({ isSidebarToggled, handleToggleSidebar, isSubscribed }) => {
  const ref = useRef();
  const [isDropdownToggled, toggleDropdown] = useState(false);
  useOnClickOutside(ref, () => toggleDropdown(false));

  const { user } = useContext(UserContext);
  const userTypeInsensitive = user.userType.toLowerCase();

  let companyLogo = user?.companyLogo;
  if (user.userType === userTypes.EMPLOYEE) {
    companyLogo = user?.employerDetails?.companyLogo;
  }

  return (
    <div className={styles.Navbar}>
      <div className={styles.Navbar_section}>
        {isSubscribed && !user?.hasOpenedTabletView && (
          <IconButton
            icon="menu"
            type={iconButtonTypes.SOLID.LG}
            onClick={() => {
              handleToggleSidebar(!isSidebarToggled);
            }}
          />
        )}
      </div>

      <div className={cn(styles.Navbar_section, styles.Navbar_logo)}>
        <img
          src={IconLogoWhite}
          className={styles.Navbar_logo_icon}
          alt="TimeSheet Logo"
        />

        <Text
          type="heading___xs"
          colorClass="n___0"
          className={styles.Navbar_logo_text}
        >
          TimeSheet
        </Text>

        <div className={styles.Navbar_userType}>
          <Text type="heading___xxxs" className={styles.Navbar_userType_text}>
            {user.userType}
          </Text>
        </div>
      </div>

      <div
        ref={ref}
        className={cn(styles.Navbar_section, styles.Navbar_navUser)}
      >
        {!user?.hasOpenedTabletView && (
          <>
            {companyLogo && (
              <div className={styles.Navbar_navUser_companyLogoWrapper}>
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  className={styles.Navbar_navUser_companyLogo}
                />
              </div>
            )}

            <button
              data-test="dropdownButton"
              className={styles.Navbar_navUser_button}
              type="button"
              onClick={() => toggleDropdown(!isDropdownToggled)}
              tabIndex={0}
            >
              <span className={styles.Navbar_navUser_name}>
                {user.fullName}
              </span>
              <Icon
                icon="keyboard_arrow_down"
                className={styles.Navbar_navUser_caret}
              />
            </button>

            <Card
              className={cn({
                [styles.Navbar_navUser_dropdown]: !isDropdownToggled,
                [styles.Navbar_navUser_dropdown___toggled]: isDropdownToggled,
              })}
            >
              {isSubscribed && (
                <Link
                  className={styles.Navbar_navUser_dropdown_link}
                  to={`/${userTypeInsensitive}/settings/account-information`}
                  onClick={() => toggleDropdown(!isDropdownToggled)}
                >
                  <Icon
                    icon="settings"
                    className={styles.Navbar_navUser_dropdown_link_icon}
                  />
                  Settings
                </Link>
              )}

              <Link
                className={styles.Navbar_navUser_dropdown_link}
                to="/logout"
              >
                <Icon
                  icon="exit_to_app"
                  className={styles.Navbar_navUser_dropdown_link_icon}
                />
                Logout
              </Link>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  isSubscribed: false,
};

Navbar.propTypes = {
  isSidebarToggled: PropTypes.bool.isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
  isSubscribed: PropTypes.bool,
};

export default Navbar;
