import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/vehicles`;

const VehiclesService = {
  get: ({ params: { employerId } }) =>
    axios.get(`${BASE_URL}/`, {
      params: {
        employerId,
      },
    }),
  retrieve: ({ vehicleId }) => axios.get(`${BASE_URL}/${vehicleId}`),
  create: ({ body }) => axios.post(`${BASE_URL}/`, body),
  update: ({ vehicleId, body }) =>
    axios.patch(`${BASE_URL}/${vehicleId}`, body),
  delete: ({ vehicleId }) => axios.delete(`${BASE_URL}/${vehicleId}`),
  generateAllReport: (body) =>
    axios.post(`${BASE_URL}/generateAllReport`, body, {
      responseType: body.generateType === 'Download' ? 'blob' : 'json',
    }),
  generateSingleReport: (body) =>
    axios.post(`${BASE_URL}/generateSingleReport`, body, {
      responseType: body.generateType === 'Download' ? 'blob' : 'json',
    }),
};

export default VehiclesService;
