import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import DailyView from './DailyView';
import WeeklyView from './WeeklyView';
import FortnightlyView from './FortnightlyView';
import MonthlyView from './MonthlyView';

import { timesheetTypes } from '../../globals';
import {TimesheetContext, UserContext} from '../../contexts';
import { getDayNameFromDayId } from '../../utils/datetime';
import useGetEmployee from "../../hooks/useGetEmployee";

const TimesheetGrid = () => {
  const {
    viewType,
    dayIds,
    isEmployeesLoading,
    isRostersLoading,
    isTimesheetsLoading,
    windowSize,
  } = useContext(TimesheetContext);

  const { user, loginUpdate } = useContext(UserContext);

  const {isLoading: isUserLoading, employee: getEmployeeDetail} = useGetEmployee({
    emailAddress: user.userType == "Employee" ? user.employerDetails.employerEmailAddress : user.emailAddress,
  });
  
  const [narrowTitles,setNarrowTitles] = useState(null);

  useEffect(() => {
    if(getEmployeeDetail){
      if(getEmployeeDetail.narrowTitles !== undefined){
          setNarrowTitles(getEmployeeDetail.narrowTitles);
      }
    }
  },[!isUserLoading]);

  const childState = (childState) => {
    if(!isUserLoading){
      if(getEmployeeDetail.narrowTitles !== undefined){
        if(getEmployeeDetail.narrowTitles){
          if(childState != null){
            setNarrowTitles(false);
          }else{
            setNarrowTitles(true);
          }
        }
      }
    }
    
  }


  if (isEmployeesLoading || isRostersLoading || isTimesheetsLoading) {
    return <Preloader type={viewType} />;
  }

  return (
    <div className={styles.TimesheetGrid}>
      {/* Header of TimesheetGrid starts here */}
      <div
        className={cn(
          styles.TimesheetGrid_grid,
          styles[
            isUserLoading !== undefined ? 
            (narrowTitles) ? 
            `TimesheetGrid_grid___${viewType}_narrow` : `TimesheetGrid_grid___${viewType}`
            : 
            `TimesheetGrid_grid___${viewType}`]
        )}
      >
        <div
          className={cn(
            styles.TimesheetGrid_column,
            styles.TimesheetGrid_header,
            styles.TimesheetGrid_header_clickable
          )}
        />

        <div
          className={cn(
            styles.TimesheetGrid_column,
            styles.TimesheetGrid_header
          )}
        >
          Status
        </div>

        <div
          className={cn(
            styles.TimesheetGrid_column,
            styles.TimesheetGrid_header
          )}
        >
          Name
        </div>

        {windowSize.width > 767 &&
          (viewType === timesheetTypes.FORTNIGHTLY ||
            viewType === timesheetTypes.MONTHLY) && (
            <div
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_header
              )}
            >
              Week
            </div>
          )}

        {windowSize.width > 767 &&
          (viewType === timesheetTypes.WEEKLY ||
            viewType === timesheetTypes.FORTNIGHTLY ||
            viewType === timesheetTypes.MONTHLY) && (
            <>
              {dayIds.map((dayId) => (
                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_header
                  )}
                  key={getDayNameFromDayId(dayId)}
                >
                  {
                    narrowTitles !== null ?
                        <>
                          {
                            narrowTitles ?
                                <>
                                  {getDayNameFromDayId(dayId).slice(0,3)}
                                </>
                                :
                                <>
                                  {getDayNameFromDayId(dayId)}
                                </>
                          }
                        </>
                        :
                        <>
                          {getDayNameFromDayId(dayId)}
                        </>
                  }

                </div>
              ))}
            </>
          )}

        <div
          className={cn(
            styles.TimesheetGrid_column,
            styles.TimesheetGrid_header,
            styles.TimesheetGrid_header_total
          )}
        >
          TOTAL
        </div>
      </div>
      {/* Header of TimesheetGrid ends here */}

      {/* Body of TimesheetGrid starts here */}
      <div
        className={cn(
          styles.TimesheetGrid_grid,
          styles.TimesheetGrid_grid_bodyWrapper,
          styles[getEmployeeDetail !== undefined ? 
            (narrowTitles !== undefined && narrowTitles) ? 
            `TimesheetGrid_grid___${viewType}_narrow` : `TimesheetGrid_grid___${viewType}`
            : 
            `TimesheetGrid_grid___${viewType}`]
        )}
      >
        {viewType === timesheetTypes.DAILY && <DailyView />}

        {viewType === timesheetTypes.WEEKLY && <WeeklyView handleChildState={childState} />}

        {viewType === timesheetTypes.FORTNIGHTLY && <FortnightlyView handleChildState={childState} />}

        {viewType === timesheetTypes.MONTHLY && <MonthlyView handleChildState={childState} />}
      </div>
      {/* Body of TimesheetGrid ends here */}
    </div>
  );
};

export default TimesheetGrid;
