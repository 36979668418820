import { useState, useEffect } from 'react';

import { ReviewsService } from '../services';

const useReview = ({ vehicleId, reviewId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [review, setReview] = useState(null);

  useEffect(() => {
    const getReview = async () => {
      try {
        const response = await ReviewsService.retrieve({
          vehicleId,
          reviewId,
        });

        setReview(response.data);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    };

    getReview();
  }, []);

  return { isLoading, review };
};

export default useReview;
