import { useState } from 'react';
import { useAlert } from 'react-alert';
import FileDownload from 'js-file-download';
import dateFormat from 'dateformat';

import { TimesheetsService } from '../services';

const useGenerateTimesheetReport = () => {
  const alert = useAlert();
  const [isGenerating, setIsGenerating] = useState(false);

  // Only usable if timesheet is under a project
  const filterType = (projectType, timesheet) => {
    if (projectType === 'Complete') {
      return true;
    }

    if (projectType === 'Billable') {
      if (timesheet.isBillable) {
        return true;
      }

      return false;
    }

    if (projectType === 'Non-billable') {
      if (timesheet.isBillable) {
        return false;
      }

      return true;
    }

    return false;
  };

  const generateReport = async ({
    viewType,
    startDate,
    endDate,
    emailAddress,
    isAllEmployees,
    selectedEmployees,
    employees,
    timesheets,
    employerFullName,
    employerEmailAddress,
    reportType,
    isProject,
    projectType,
    hoursAndMinutesFormat,
  }) => {
    setIsGenerating(true);

    const fileName = `${isProject ? 'Project ' : ''}Timesheets ${
      isProject ? `${projectType} ` : ''
    }${viewType} Report___${dateFormat(
      new Date(startDate),
      'mmm d'
    )} - ${dateFormat(new Date(endDate), 'mmm d, yyyy')}`;

    // Filtered employees
    const filteredEmployees = [];

    // Filtered timesheets
    const filteredTimesheets = [];

    if (!isAllEmployees) {
      selectedEmployees.forEach(({ value: employeeId }) => {
        // Filtering employees
        const filteredEmployee = employees.filter(
          (femployee) => femployee.id === employeeId
        );

        filteredEmployees.push(...filteredEmployee);

        // Filtering timesheets
        const filteredTimesheet = timesheets.filter((ftimesheet) => {
          if (!isProject) {
            return ftimesheet.userId === employeeId;
          }

          if (ftimesheet.userId === employeeId) {
            // Filtering project timesheets for Complete/Billable/Non-billable
            return filterType(projectType, ftimesheet);
          }

          return false;
        });

        filteredTimesheets.push(...filteredTimesheet);
      });
    } else if (isProject) {
      // Filtering project timesheets for Complete/Billable/Non-billable
      const filteredTimesheet = timesheets.filter((ftimesheet) =>
        filterType(projectType, ftimesheet)
      );

      filteredTimesheets.push(...filteredTimesheet);
    }

    const { data: generateReportResponse } =
      await TimesheetsService.generateReport({
        employerFullName: reportType === 'Send' ? employerFullName : undefined,
        employerEmailAddress:
        reportType === 'Send' ? employerEmailAddress : employerEmailAddress,
        emailAddress: reportType === 'Send' ? emailAddress : undefined,
        fileName,
        viewType,
        employees: filteredEmployees.length ? filteredEmployees : employees,
        // eslint-disable-next-line no-nested-ternary
        timesheets: !isProject
          ? filteredTimesheets.length
            ? filteredTimesheets
            : timesheets
          : filteredTimesheets,
        type: reportType,
        hoursAndMinutesFormat,
      });

    // Download the file if reportType is 'Download'
    if (reportType === 'Download') {
      FileDownload(generateReportResponse, `${fileName}.xlsx`);
      alert.success('Timesheet report downloaded!');
    }

    // Send an alert if reportType is 'Send'
    if (reportType === 'Send') {
      if (generateReportResponse.message === 'report_sent') {
        alert.success('Timesheet report sent!');
      } else {
        alert.error('Oops, something went wrong.');
      }
    }

    setIsGenerating(false);

    return { message: 'generate_report_successful' };
  };

  return { isGenerating, generateReport };
};

export default useGenerateTimesheetReport;
