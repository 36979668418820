/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { Text } from '../elements';
import { textTypes } from '../../globals';

const SubscriptionCard = ({
  planName,
  planDescription,
  planPrice,
  isEmployer,
  isCurrentPlan,
  disabled,
  onClick,
}) => (
  <a
    className={cn(styles.SubscriptionCard, {
      [styles.SubscriptionCard___active]: isCurrentPlan,
      [styles.SubscriptionCard___disabled]: disabled,
    })}
    onClick={onClick}
  >
    <div className={styles.SubscriptionCard_planName}>
      <Text type={textTypes.HEADING.XXS}>{planName}</Text>
    </div>
    <div className={styles.SubscriptionCard_planDescription}>
      <Text type={textTypes.BODY.LG}>{planDescription}</Text>
    </div>
    <div className={styles.SubscriptionCard_planPrice}>
      <Text type={textTypes.HEADING.XXS}>
        ${planPrice}
        {isEmployer && ' / employee'}
      </Text>
    </div>
  </a>
);

SubscriptionCard.defaultProps = {
  isEmployer: true,
  isCurrentPlan: false,
  disabled: false,
};

SubscriptionCard.propTypes = {
  planName: PropTypes.string.isRequired,
  planDescription: PropTypes.string.isRequired,
  planPrice: PropTypes.number.isRequired,
  isEmployer: PropTypes.bool,
  isCurrentPlan: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SubscriptionCard;
