import React from 'react';
import styles from './styles.module.scss';

import { Card, Shine } from '../../../../../components/elements';

const PreloaderLarge = () => (
  <Card className={styles.PreloaderLarge}>
    <Shine className={styles.PreloaderLarge_shine} />
  </Card>
);

export default PreloaderLarge;
