const reportingCoumns = [
    {
        label: "Project Name",
        value: "projectName"
    },
    {
        label: "Date",
        value: "date"
    },
    {
        label: "Day",
        value: "day"
    },
    {
        label: "Client Name",
        value: "clientName"
    },
    {
        label: "Employee Name",
        value: "fullName"
    },
    {
        label: "Start",
        value: "startTime"
    },
    {
        label: "Actual Start",
        value: "actualStart"
    },
    {
        label: "End",
        value: "endTime"
    },
    {
        label: "Actual End",
        value: "actualEnd"
    },
    {
        label: "Lunch",
        value: "lunch"
    },
    {
      label: "Travel Charge",
      value: "travelCharge",
    },
    {
        label: "Leave",
        value: "leave"
    },
    {
        label: "Hours",
        value: "hours"
    },
    {
        label: "Wage",
        value: "wage"
    },
    {
        label: "Income",
        value: "income"
    },
    {
        label: "Notes",
        value: "notes"
    },
    {
        label: "Performed For",
        value: "performedFor"
    },
    {
        label: "Cost",
        value: "cost"
    },
    {
        label: "Total Cost",
        value: "totalCost"
    }
];

export default reportingCoumns;