import { useState, useEffect } from 'react';

import { VehiclesService } from '../services';

const useVehicle = ({ vehicleId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState(null);

  useEffect(() => {
    const getVehicle = async () => {
      let responseCode;

      try {
        const response = await VehiclesService.retrieve({
          vehicleId,
        });
        responseCode = response.status;

        setVehicle(response.data);
      } catch (error) {
        responseCode = error.response.status;
      }

      setIsLoading(false);

      return { responseCode };
    };

    getVehicle();
  }, [vehicleId]);

  return { isLoading, vehicle };
};

export default useVehicle;
