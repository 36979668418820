import { isStaging, isLocal } from '../utils/destinations';

let apiUrl = null;

if (isLocal) {
  apiUrl = 'http://127.0.0.1:9001';
} else if (isStaging) {
  apiUrl = 'https://timesheet-api-staging-rpfqqtgqpa-as.a.run.app';
} else {
  // Production
  apiUrl = 'https://timesheet-api-production-rpfqqtgqpa-as.a.run.app';  
}

const config = {
  API_URL: apiUrl,
};

export default config;
