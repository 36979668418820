import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  ControlledTextArea,
  Spinner,
} from '../../../components/elements';
import { isValidEmail, isValidPhoneNumber } from '../../../utils/string';
import { buttonKinds, colorNames, spinnerSizes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { useClient, useUpdateClient } from '../../../hooks';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'This field is required.';
  }

  if (values.emailAddress && !isValidEmail(values.emailAddress)) {
    errors.emailAddress = 'This must be a valid email address.';
  }

  if (values.phoneNumber) {
    if (!isValidPhoneNumber(values.phoneNumber)) {
      errors.phoneNumber = 'Only numbers are allowed.';
    } else if (values.phoneNumber.length > 15) {
      errors.phoneNumber = 'The maximum length of this field is 15 characters.';
    }
  }

  return errors;
};

const UpdateClient = () => {
  const { user } = useContext(UserContext);
  const { clientId } = useParams();
  const { isLoading: isClientLoading, client } = useClient({
    clientId,
  });
  const { isUpdating: isClientUpdating, updateClient } = useUpdateClient();
  const userTypeInsensitive = user.userType.toLowerCase();

  return (
    <div className={styles.UpdateClient}>
      <Breadcrumbs
        pageTitle="Update Client"
        pages={[
          {
            name: 'Dashboard',
            link: `/${userTypeInsensitive}/dashboard`,
          },
          {
            name: 'Clients',
            link: `/${userTypeInsensitive}/clients`,
          },
        ]}
      />

      {isClientLoading ? (
        <Preloader />
      ) : (
        <Card className={styles.UpdateClient_card}>
          <Formik
            initialValues={client}
            onSubmit={(values, { setErrors }) => {
              const errors = validate(values);
              if (!isEmpty(errors)) {
                setErrors(errors);
                return;
              }

              // Create new client object
              const newClient = {
                clientId,
                ...values,
              };

              updateClient(newClient);
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <ControlledInput
                  name="name"
                  placeholder="Name*"
                  icon="person"
                  value={values?.name}
                  error={errors.name}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />

                <ControlledInput
                  className={styles.UpdateClient_withMargin}
                  name="emailAddress"
                  placeholder="Email Address"
                  icon="mail"
                  value={values?.emailAddress}
                  error={errors.emailAddress}
                  onChange={(e) =>
                    setFieldValue('emailAddress', e.target.value)
                  }
                />

                <ControlledInput
                  className={styles.UpdateClient_withMargin}
                  name="phoneNumber"
                  placeholder="Phone Number"
                  icon="phone"
                  value={values?.phoneNumber}
                  error={errors.phoneNumber}
                  onChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                />

                <ControlledInput
                  className={styles.UpdateClient_withMargin}
                  name="address"
                  placeholder="Address"
                  icon="home"
                  value={values?.address}
                  error={errors.address}
                  onChange={(e) => setFieldValue('address', e.target.value)}
                />

                <ControlledTextArea
                  className={styles.UpdateClient_withMargin}
                  name="notes"
                  placeholder="Notes"
                  icon="description"
                  value={values?.notes}
                  error={errors.notes}
                  onChange={(e) => setFieldValue('notes', e.target.value)}
                />

                <div style={{marginTop: '8%'}}>
                  <input type="checkbox" 
                  name='isImportant' 
                  value={values.isImportant}
                  checked={ values.isImportant }
                  onChange={ (e) => { setFieldValue('isImportant', e.target.checked) } } 
                  /> Important
                </div>

                <div className={styles.UpdateClient_buttonGroup}>
                  <Button
                    className={styles.UpdateClient_buttonGroup_button}
                    kind={buttonKinds.SUBMIT}
                    icon="add"
                    disabled={isClientUpdating}
                    onClick={() => {}}
                  >
                    <span
                      className={styles.UpdateClient_buttonGroup_buttonText}
                    >
                      Update Client
                      {isClientUpdating && (
                        <Spinner
                          size={spinnerSizes.XS}
                          colorName={colorNames.WHITE}
                          className={styles.UpdateClient_buttonGroup_spinner}
                        />
                      )}
                    </span>
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      )}
    </div>
  );
};

export default UpdateClient;
