import { useState } from 'react';
import { useAlert } from 'react-alert';

import { ClientsService } from '../services';

const useCreateClient = () => {
  const alert = useAlert();
  const [isCreating, setIsCreating] = useState(false);

  const createClient = async (client) => {
    setIsCreating(true);

    const { data: createClientResponse } = await ClientsService.create(client);

    setIsCreating(false);

    let message = null;

    if (createClientResponse.message === 'client_created') {
      alert.success('Client created!');
      message = 'client_created';
    } else {
      alert.error('Oops, something went wrong.');
      message = 'client_not_created';
    }

    return { message };
  };

  return { isCreating, createClient };
};

export default useCreateClient;
