import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import styles from '../styles.module.scss';

import { ControlledSelect, Modal } from '../../../elements';
import { buttonTypes, modalPositions, modalSizes } from '../../../../globals';

const validate = (values) => {
  const errors = {};

  if (!values.employee) {
    errors.employee = 'This field is required.';
  }

  return errors;
};

const SelectTimesheetEmployeeModal = ({
  isOpen,
  handleClose,
  timesheetEmployees,
  handleSuccess,
}) => {
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const employeeOptions = [];
  timesheetEmployees.forEach(({ id, fullName }) => {
    const option = {
      label: fullName,
      value: id,
    };

    employeeOptions.push(option);
  });

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Select a TimeSheet Employee"
      className={styles.ForgotPasswordModals}
      actions={[
        {
          text: 'Proceed',
          type: buttonTypes.PRIMARY.VIOLET,
          disabled: isSubmitting === true,
          onClick: () => {
            formRef.current.handleSubmit();
          },
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ employee: null }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          setIsSubmitting(true);

          handleSuccess({
            employeeId: values.employee.value,
          });

          setIsSubmitting(false);
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ControlledSelect
              options={employeeOptions}
              name="employee"
              placeholder="Employee*"
              value={values.employee}
              error={errors.employee}
              onChange={(val) => {
                setFieldValue('employee', {
                  label: val.label,
                  value: val.value,
                });
              }}
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

SelectTimesheetEmployeeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  timesheetEmployees: PropTypes.array.isRequired,
  // doesn't accept any parameter. This will just
  // proceed to the next modal
  handleSuccess: PropTypes.func.isRequired,
};

export default SelectTimesheetEmployeeModal;
