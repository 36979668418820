import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { Bar } from 'react-chartjs-2';
import styles from './styles.module.scss';

import PreloaderSmall from './Preloader/Small';
import PreloaderLarge from './Preloader/Large';
import { Card, Grid, Icon, Text } from '../../../components/elements';
import {
  colorClasses,
  colorHexCodes,
  gridTypes,
  textTypes,
  timesheetTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { useClients, useTimesheets } from '../../../hooks';
import { ProjectsService } from '../../../services';
import {
  getDayNameFromDayId,
  getPrevOrAfterDate,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import {
  generateDayIds,
  getDateEquivalence,
  getHours,
} from '../../../utils/timesheets';

const options = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
};

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [previousWeekHours, setPreviousWeekHours] = useState([]);
  const [currentWeekHours, setCurrentWeekHours] = useState([]);
  const [finalTotalEarnings, setFinalTotalEarnings] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [isProjectsLoading, setIsProjectsLoading] = useState(true);

  const { isLoading: isClientsLoading, clients } = useClients({
    userId: user.id,
  });

  useEffect(async () => {
    if (!isClientsLoading) {
      const getProjectsCount = async () => {
        let projectsCount = 0;
        for (const client of clients) {
          const { data: getProjectsResponse } = await ProjectsService.get({
            clientId: client.id,
          });

          projectsCount += getProjectsResponse?.projects.length;
        }

        setTotalProjects(projectsCount);
      };

      await getProjectsCount();

      setIsProjectsLoading(false);
    }
  }, [isClientsLoading]);

  const dayIds = generateDayIds(
    timesheetTypes.WEEKLY,
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'start'),
    user.timesheetStartWeek
  );

  const dayNames = [];
  dayIds.forEach((dayId) => {
    dayNames.push(getDayNameFromDayId(dayId));
  });

  // Data for the graph
  const data = {
    labels: dayNames,
    datasets: [
      {
        label: 'Previous Week Hours',
        data: previousWeekHours,
        backgroundColor: colorHexCodes.RED['400'],
      },
      {
        label: 'Current Week Hours',
        data: currentWeekHours,
        backgroundColor: colorHexCodes.GREEN['400'],
      },
    ],
  };

  const currentWeekStartDate = getDateEquivalence(
    timesheetTypes.WEEKLY,
    user.timesheetStartDate,
    'start'
  );
  const currentWeekEndDate = getDateEquivalence(
    timesheetTypes.WEEKLY,
    user.timesheetStartDate,
    'end'
  );
  const previousWeekStartDate = getPrevOrAfterDate(
    currentWeekStartDate,
    7,
    'subtract'
  );
  const previousWeekEndDate = getPrevOrAfterDate(
    currentWeekEndDate,
    7,
    'subtract'
  );

  const {
    isLoading: isPreviousWeekTimesheetsLoading,
    timesheets: previousWeekTimesheets,
  } = useTimesheets({
    userId: user.id,
    startDate: previousWeekStartDate,
    endDate: previousWeekEndDate,
  });

  const {
    isLoading: isCurrentWeekTimesheetsLoading,
    timesheets: currentWeekTimesheets,
  } = useTimesheets({
    userId: user.id,
    startDate: currentWeekStartDate,
    endDate: currentWeekEndDate,
  });

  useEffect(() => {
    if (!isPreviousWeekTimesheetsLoading && !isCurrentWeekTimesheetsLoading) {
      // Get total earnings for the current week
      let totalEarnings = 0;

      // Get hours for each day for the current week
      const tempCurrentWeekHours = [];

      [...Array(7)].forEach((_, i) => {
        const timesheetDate = getPrevOrAfterDate(
          currentWeekStartDate,
          i,
          'add'
        );
        const timesheetsForDay = currentWeekTimesheets.filter(
          (timesheet) => timesheet.date === timesheetDate
        );

        let totalHours = 0;
        timesheetsForDay.forEach(
          ({
            date,
            startTimeDetails = {
              endTime: undefined,
              actualTime: undefined,
            },
            endTimeDetails = {
              endTime: undefined,
              actualTime: undefined,
            },
            isLunchBreak,
            lunchBreakDuration,
            leaveType,
            publicHolidayMultiplier,
            wage,
          }) => {
            const startTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(startTimeDetails.startTime)
            );
            const endTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(endTimeDetails.endTime)
            );

            totalHours += Number(
              getHours(
                startTimeTimestamp,
                endTimeTimestamp,
                isLunchBreak,
                lunchBreakDuration,
                // leaveType,
                // publicHolidayMultiplier
              )
            );

            totalEarnings +=
              wage *
              Number(
                getHours(
                  startTimeTimestamp,
                  endTimeTimestamp,
                  isLunchBreak,
                  lunchBreakDuration,
                  // leaveType,
                  // publicHolidayMultiplier
                )
              );
          }
        );

        tempCurrentWeekHours.push(totalHours);
      });

      setCurrentWeekHours(tempCurrentWeekHours);
      setFinalTotalEarnings(totalEarnings);

      // Get hours for each day for the previous week
      const tempPreviousWeekHours = [];

      [...Array(7)].forEach((_, i) => {
        const timesheetDate = getPrevOrAfterDate(
          previousWeekStartDate,
          i,
          'add'
        );
        const timesheetsForDay = previousWeekTimesheets.filter(
          (timesheet) => timesheet.date === timesheetDate
        );

        let totalHours = 0;
        timesheetsForDay.forEach(
          ({
            date,
            startTimeDetails = {
              endTime: undefined,
              actualTime: undefined,
            },
            endTimeDetails = {
              endTime: undefined,
              actualTime: undefined,
            },
            isLunchBreak,
            leaveType,
            publicHolidayMultiplier,
          }) => {
            const startTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(startTimeDetails.startTime)
            );
            const endTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(endTimeDetails.endTime)
            );

            totalHours += Number(
              getHours(
                startTimeTimestamp,
                endTimeTimestamp,
                isLunchBreak,
                leaveType,
                publicHolidayMultiplier
              )
            );
          }
        );

        tempPreviousWeekHours.push(totalHours);
      });

      setPreviousWeekHours(tempPreviousWeekHours);
    }
  }, [isPreviousWeekTimesheetsLoading, isCurrentWeekTimesheetsLoading]);

  return (
    <div className={styles.Dashboard}>
      <Text type={textTypes.HEADING.XS}>Updates of the Week</Text>

      <Grid type={gridTypes.FOUR} className={styles.Dashboard_cards}>
        {isClientsLoading ||
        isProjectsLoading ||
        isPreviousWeekTimesheetsLoading ||
        isCurrentWeekTimesheetsLoading ? (
          <>
            <PreloaderSmall />
            <PreloaderSmall />
            <PreloaderSmall />
            <PreloaderSmall />
          </>
        ) : (
          <>
            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.GREEN['400']}
                  >
                    {user?.hoursAndMinutesFormat
                      ? hoursWithDecimalToHoursAndMinutes(
                          currentWeekHours.reduce((a, b) => a + b, 0).toFixed(2)
                        )
                      : currentWeekHours.reduce((a, b) => a + b, 0).toFixed(2)}
                  </Text>
                  <Text type={textTypes.BODY.LG}>Total Hours</Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="schedule"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___green
                    )}
                  />
                </div>
              </Grid>
            </Card>

            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.BLUE['400']}
                  >
                    ${finalTotalEarnings.toFixed(2)}
                  </Text>
                  <Text type={textTypes.BODY.LG}>Total Income</Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="attach_money"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___blue
                    )}
                  />
                </div>
              </Grid>
            </Card>

            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.YELLOW['400']}
                  >
                    {clients.length}
                  </Text>
                  <Text type={textTypes.BODY.LG}>Total Clients</Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="people"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___yellow
                    )}
                  />
                </div>
              </Grid>
            </Card>

            <Card className={styles.Dashboard_cards_card}>
              <Grid className={styles.Dashboard_cards_card_grid}>
                <div className={styles.Dashboard_cards_card_leftColumn}>
                  <Text
                    type={textTypes.HEADING.SM}
                    colorClass={colorClasses.RED['400']}
                  >
                    {totalProjects}
                  </Text>
                  <Text type={textTypes.BODY.LG}>Total Projects</Text>
                </div>

                <div className={styles.Dashboard_cards_card_rightColumn}>
                  <Icon
                    icon="apps"
                    className={cn(
                      styles.Dashboard_cards_card_icon,
                      styles.Dashboard_cards_card_icon___red
                    )}
                  />
                </div>
              </Grid>
            </Card>
          </>
        )}
      </Grid>

      <Text type={textTypes.HEADING.XS} className={styles.Dashboard_withMargin}>
        Previous Week Hours v.s. Current Week Hours
      </Text>

      {isPreviousWeekTimesheetsLoading || isCurrentWeekTimesheetsLoading ? (
        <PreloaderLarge />
      ) : (
        <Card className={styles.Dashboard_graph}>
          <Bar data={data} options={options} />
        </Card>
      )}
    </div>
  );
};

export default Dashboard;
