import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { RosterContext } from '../../contexts';
import { getDayNameFromDayId } from '../../utils/datetime';

import RosterCard from './RosterCard';

const RosterGrid = () => {
  const { dayIds, isRostersLoading, rosters } = useContext(RosterContext);

  return (
    <div className={styles.RosterGrid}>
      {/* Header of RosterGrid starts here */}
      {dayIds.map((dayId) => (
        <div
          className={cn(styles.RosterGrid_column, styles.RosterGrid_header)}
          key={getDayNameFromDayId(dayId)}
        >
          {getDayNameFromDayId(dayId)}
        </div>
      ))}
      {/* Header of RosterGrid ends here */}

      {/* Body of RosterGrid starts here */}
      {dayIds.map((dayId, i) => (
        <div
          className={cn(
            styles.RosterGrid_column,
            styles.RosterGrid_column_data,
            {
              [styles.RosterGrid_column_even]: (i + 1) % 2 === 0,
            }
          )}
          key={i}
        >
          {isRostersLoading ? (
            <Preloader />
          ) : (
            <>
              {rosters.sort().map((roster) => {
                const schedule = roster.schedules.find(
                  (sched) => sched.day === dayId
                );

                if (!schedule) return;

                return (
                  <RosterCard
                    roster={roster}
                    schedule={schedule}
                    key={roster.id}
                  />
                );
              })}
            </>
          )}
        </div>
      ))}
      {/* Body of RosterGrid ends here */}
    </div>
  );
};

export default RosterGrid;
