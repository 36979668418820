import React, { useState, useContext } from 'react';
import dateFormat from 'dateformat';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

import PreloaderClient from './Preloader/Client';
import PreloaderProject from './Preloader/Project';
import { Breadcrumbs, SweetAlert } from '../../../components';
import {
  Badge,
  ButtonLink,
  IconButton,
  ControlledInput,
  IconLink,
  Text,
  Card,
  Grid,
  Icon,
} from '../../../components/elements';
import {
  buttonTypes,
  colorClasses,
  colorNames,
  textTypes,
  gridTypes,
  userTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import {
  useTimesheets,
  useClient,
  useProjects,
  useWindowSize,
} from '../../../hooks';
import {
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import { getHours } from '../../../utils/timesheets';
import NoResults from '../../../static/images/Misc/no-results.webp';
import person from '../../../static/images/Icons/user.png';
import useGetEmployee from "../../../hooks/useGetEmployee";

const ViewClient = () => {
  const { user } = useContext(UserContext);
  const { employee: getEmployeeDetail} = useGetEmployee({
    emailAddress: user?.employerDetails?.employerEmailAddress,
  });

  const { clientId } = useParams();
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const userTypeInsensitive = user.userType.toLowerCase();

  const { timesheets } = useTimesheets({
    userId:
      userTypeInsensitive === 'employee' || userTypeInsensitive === 'contractor'
        ? user.id
        : undefined,
    clientId,
    startDate: dateFormat(new Date('January 1, 2018'), 'yyyy-mm-dd'),
    endDate: dateFormat(new Date('January 1, 2100'), 'yyyy-mm-dd'),
  });

  const { isLoading: isClientLoading, client } = useClient({
    clientId,
  });
  const [search, setSearch] = useState("");

  const handleOnChange = (e) => {
    e.preventDefault()
    setSearch(e.target.value)
  }
  const {
    isLoading: isProjectsLoading,
    projects,
    deleteProject,
  } = useProjects({
    clientId,
    search
  });

  const { windowSize } = useWindowSize();
  let overallTotalHours = 0;
  timesheets.forEach(
    ({
      date,
      startTimeDetails = {
        endTime: undefined,
        actualTime: undefined,
      },
      endTimeDetails = {
        endTime: undefined,
        actualTime: undefined,
      },
      isLunchBreak,
      isTravelCharge,
      leaveType,
      publicHolidayMultiplier,
    }) => {
      const startTimeTimestamp = convertDateTimeToTimestamp(
        date,
        convertTimeTo24HourFormat(startTimeDetails.startTime)
      );
      const endTimeTimestamp = convertDateTimeToTimestamp(
        date,
        convertTimeTo24HourFormat(endTimeDetails.endTime)
      );

      overallTotalHours += Number(
        getHours(
          startTimeTimestamp,
          endTimeTimestamp,
          isLunchBreak,
          isTravelCharge,
          leaveType,
          publicHolidayMultiplier
        )
      );
    }
  );

  return (
    <div className={styles.ViewClient}>
      <div className={styles.ViewClient_firstRow}>
        <Breadcrumbs
          pageTitle="View Client"
          pages={[
            {
              name: 'Dashboard',
              link: `/${userTypeInsensitive}/dashboard`,
            },
            {
              name: 'Clients',
              link: `/${userTypeInsensitive}/clients`,
            },
          ]}
        />

        {(user.userType === userTypes.EMPLOYER ||
          user.userType === userTypes.CONTRACTOR ||
          getEmployeeDetail?.employeeCanCreateProject) && (
            <ButtonLink
              to={`/${userTypeInsensitive}/clients/view/${clientId}/projects/create`}
              type={buttonTypes.PRIMARY.GREEN}
              icon="add"
            >
              Create Project
            </ButtonLink>
          )}
      </div>

      <Text type={textTypes.HEADING.XS}>Client Details</Text>
      <Text
        type={textTypes.HEADING.XS}
        className={styles.ViewClient_withMargin}
      >
        Summary
      </Text>
      <Grid type={gridTypes.TWOBYTHREE} className={styles.ViewClient_cards}>
          <Card className={styles.ViewClient_cards_card}>
            <Grid className={styles.ViewClient_cards_card_grid}>
            <img
                src={person}
                alt="Task"
                className={styles.ViewProject_noProjects_image}
              />
              <div className={styles.ViewClient_cards_card_leftColumn1}>
                <Text
                  type={textTypes.HEADING.SM}
                  colorClass={colorClasses.NEUTRAL}
                  className={styles.ViewClient_cards_card_nameText}
                >
                  {client?.name}
                </Text>
                <div className={styles.ViewClient_cards_card_leftColumn}>
                  <div className={styles.ViewClient_cards_card_leftColumnEmail}>
                    <Icon
                      icon="mail"
                      className={cn(
                        styles.ViewClient_cards_card_icon,
                        styles.ViewClient_cards_card_icon___nuetral
                      )}
                    />
                    <Text
                      type={textTypes.BODY.MD}
                      colorClass={colorClasses.NEUTRAL['500']}
                    >
                      {client?.emailAddress || '--'}
                    </Text>
                  </div>
                  <div className={styles.ViewClient_cards_card_leftColumnPhone}>
                    <Icon
                      icon="phone"
                      className={cn(
                        styles.ViewClient_cards_card_icon,
                        styles.ViewClient_cards_card_icon___nuetral
                      )}
                    />
                    <Text
                      type={textTypes.BODY.MD}
                      colorClass={colorClasses.NEUTRAL['500']}
                    >
                      {client?.phoneNumber || '--'}
                    </Text>
                  </div>
                  <div className={styles.ViewClient_cards_card_leftColumnLocation}>
                    <Icon
                      icon="location_on"
                      className={cn(
                        styles.ViewClient_cards_card_icon,
                        styles.ViewClient_cards_card_icon___nuetral
                      )}
                    />
                    <Text
                      type={textTypes.BODY.MD}
                      colorClass={colorClasses.NEUTRAL['500']}
                    >
                      {client?.address || '--'}
                    </Text>
                  </div>
                </div>
              </div>

            </Grid>
          </Card>

          <Card className={styles.ViewClient_cards_card1}>
            <Grid className={styles.ViewClient_cards_card1_Right}>
              <div className={styles.ViewClient_cards_card1_leftColumn}>
                <Text type={textTypes.BODY.LG} className={styles.ViewClient_cards_card1_nameText}>Total Hours</Text>
                <Text
                  type={textTypes.HEADING.MD}
                  colorClass={colorClasses.GREEN['400']}
                >
                  {user?.hoursAndMinutesFormat
                    ? hoursWithDecimalToHoursAndMinutes(overallTotalHours.toFixed(2))
                    : overallTotalHours.toFixed(2)}
                </Text>
              </div>

              <div className={styles.ViewClient_cards_card1_rightColumn}>
                <Icon
                  icon="schedule"
                  className={cn(
                    styles.ViewClient_cards_card1_icon,
                    styles.ViewClient_cards_card1_icon___green
                  )}
                />
              </div>
            </Grid>
          </Card>
      </Grid>

      <Text
        type={textTypes.HEADING.XS}
        className={styles.ViewClient_withMargin}
      >
        Project List
      </Text>
      <div className={styles.ViewClient_projectSearchDiv}>

        <ControlledInput
          className={cn(styles.ViewClient_searchDesign, styles.ViewClient_inputSearchBox)}
          name="search"
          placeholder="Search by project name..."
          value={search}
          onChange={(e) => handleOnChange(e)}
        />
        <ButtonLink
          className={styles.ViewClient_searchButton}
          to="#"
          type={buttonTypes.PRIMARY.VIOLET}
          icon="search"
        ></ButtonLink>

      </div>
      {isProjectsLoading ? (
        <PreloaderProject />
      ) : (
        <>
          {projects.length ? (
            <div
              className={cn(
                styles.ViewClient_grid,
                styles.ViewClient_projectGrid
              )}
            >
              {/* Header of ProjectGrid starts here */}
              <div
                className={cn(
                  styles.ViewClient_grid_column,
                  styles.ViewClient_grid_header,
                  styles.ViewClient_grid_header_action
                )}
              >
                Status
              </div>
              <div
                className={cn(
                  styles.ViewClient_grid_column,
                  styles.ViewClient_grid_header
                )}
              >
                Name
              </div>
              <div
                className={cn(
                  styles.ViewClient_grid_column,
                  styles.ViewClient_grid_header
                )}
              >
                Start Date
              </div>
              <div
                className={cn(
                  styles.ViewClient_grid_column,
                  styles.ViewClient_grid_header
                )}
              >
                Projected Finish Date
              </div>
              <div
                className={cn(
                  styles.ViewClient_grid_column,
                  styles.ViewClient_grid_header
                )}
              >
                Address
              </div>
              <div
                className={cn(
                  styles.ViewClient_grid_column,
                  styles.ViewClient_grid_header
                )}
              >
                Expected Hours
              </div>
              <div
                className={cn(
                  styles.ViewClient_grid_column,
                  styles.ViewClient_grid_header
                )}
              >
                Notes
              </div>
              <div
                className={cn(
                  styles.ViewClient_grid_column,
                  styles.ViewClient_grid_header,
                  styles.ViewClient_grid_header_action
                )}
              >
                Action
                {user.userType === userTypes.EMPLOYER ||
                  user.userType === userTypes.CONTRACTOR
                  ? 's'
                  : ''}
              </div>
              {/* Header of ProjectGrid ends here */}

              {/* Body of ProjectGrid starts here */}
              {projects.map(
                (
                  {
                    id,
                    name,
                    startDate,
                    projectedFinishDate,
                    address,
                    expectedHours,
                    notes,
                    status,
                    isFinshed,
                  },
                  i
                ) => (
                  <div className={styles.ViewClient_keyWrapper} key={id}>
                    <div
                      className={styles.ViewClient_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 7} / 2 / ${(i + 1) * 7
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      <Badge
                          text={status}
                          colorName={
                            status === 'Ongoing'
                              ? colorNames.BLUE
                              : colorNames.RED
                          }
                        />
                      
                    </div>
                    <div
                      className={styles.ViewClient_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 7} / 1 / ${(i + 1) * 7 + 6
                              } / 1`,
                          }
                          : undefined
                      }
                    >
                      {name}
                    </div>
                    <div
                      className={styles.ViewClient_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 7 + 1} / 2 / ${(i + 1) * 7 + 1
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {startDate ? dateFormat(startDate, 'dd/mm/yyyy') : '--'}
                    </div>
                    <div
                      className={styles.ViewClient_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 7 + 2} / 2 / ${(i + 1) * 7 + 2
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {projectedFinishDate
                        ? dateFormat(projectedFinishDate, 'dd/mm/yyyy')
                        : '--'}
                    </div>
                    <div
                      className={styles.ViewClient_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 7 + 3} / 2 / ${(i + 1) * 7 + 3
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {address || '--'}
                    </div>
                    <div
                      className={styles.ViewClient_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 7 + 4} / 2 / ${(i + 1) * 7 + 4
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {expectedHours || '--'}
                    </div>
                    <div
                      className={styles.ViewClient_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 7 + 5} / 2 / ${(i + 1) * 7 + 5
                              } / 2`,
                          }
                          : undefined
                      }
                    >
                      {notes || '--'}
                    </div>
                    <div
                      className={styles.ViewClient_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                            gridArea: `${(i + 1) * 7} / 3 / ${(i + 1) * 7 + 6
                              } / 3`,
                          }
                          : undefined
                      }
                    >
                      <IconLink
                        to={`/${userTypeInsensitive}/clients/view/${clientId}/projects/view/${id}`}
                        className={styles.ViewClient_grid_viewButton}
                        icon="visibility"
                      />

                      {(user.userType === userTypes.EMPLOYER ||
                        user.userType === userTypes.CONTRACTOR) && (
                          <>
                            <IconLink
                              to={`/${userTypeInsensitive}/clients/view/${clientId}/projects/update/${id}`}
                              className={styles.ViewClient_grid_editButton}
                              icon="edit"
                            />

                            <IconButton
                              className={styles.ViewClient_grid_deleteButton}
                              icon="highlight_off"
                              onClick={() => setSelectedProjectId(id)}
                            />
                          </>
                        )}

                        {
                          (getEmployeeDetail?.employeeCanCreateProject) && (
                            <IconLink
                              to={`/${userTypeInsensitive}/clients/view/${clientId}/projects/update/${id}`}
                              className={styles.ViewClient_grid_editButton}
                              icon="edit"
                            />
                          )
                        }
                    </div>
                  </div>
                )
              )}
              {/* Body of ProjectGrid ends here */}
            </div>
          ) : (
            <div className={styles.ViewClient_noProjects}>
              <img
                src={NoResults}
                alt="TimeSheet No Projects"
                className={styles.ViewClient_noProjects_image}
              />

              <Text
                colorClass={colorClasses.RED['400']}
                type={textTypes.HEADING.XS}
              >
                THIS CLIENT DOESN'T HAVE PROJECTS.
              </Text>

              <Text
                type={textTypes.HEADING.XXS}
                className={styles.ViewClient_noProjects_withMargin}
              >
                Add one by clicking the button at the upper side of the screen.
              </Text>
            </div>
          )}
        </>
      )}

      <SweetAlert
        show={selectedProjectId !== null}
        title="Are you sure?"
        onConfirm={async () => {
          await deleteProject(selectedProjectId);
          setSelectedProjectId(null);
        }}
        onCancel={() => setSelectedProjectId(null)}
      >
        This will delete all of the timesheets under this project.
      </SweetAlert>
    </div>
  );
};

export default ViewClient;
