import React, { useRef, useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import ImageUploading from 'react-images-uploading';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import {
  Checkbox,
  ControlledInput,
  ControlledTextArea,
  IconButton,
  Modal,
  Text,
} from '../../elements';
import { isValidDecimal } from '../../../utils/string';
import { buttonTypes, colorClasses, textTypes } from '../../../globals';
import { UserContext } from '../../../contexts';
import {
  useVehicle,
  useCreateReview,
  useUploadReviewPhoto,
} from '../../../hooks';

const validate = (values) => {
  const errors = {};

  if (!values.rego && values.name === 'Hired Vehicle/Plant') {
    errors.rego = 'This field is required.';
  }

  if (!values.hours) {
    errors.hours = 'This field is required.';
  } else if (!isValidDecimal(values.hours)) {
    errors.hours = 'Only numbers with a maximum of 2 decimals are allowed.';
  }

  if (!values.photos.length) {
    errors.photos = 'This field is required.';
  }

  return errors;
};

const CreateReviewModal = ({
  isOpen,
  handleClose,
  vehicleId,
  createNewTimesheetFunc,
}) => {
  const alert = useAlert();
  const { user } = useContext(UserContext);
  const formRef = useRef();

  const { isLoading: isVehicleLoading, vehicle } = useVehicle({
    vehicleId,
  });

  const { isCreating: isReviewCreating, createReview } = useCreateReview();
  const { isUploading: isReviewPhotoUploading, uploadReviewPhoto } =
    useUploadReviewPhoto();

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Review Vehicle"
      actions={[
        {
          text: 'Submit Review',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isReviewCreating || isReviewPhotoUploading,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      {isVehicleLoading ? (
        <Text>Loading...</Text>
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{
            name: vehicle?.name,
            rego: vehicle?.rego ? vehicle?.rego : '',
            hours: '',
            isOilChecked: false,
            isVehicleChecked: false,
            isVehicleBroken: false,
            photos: [],
            notes: '',
          }}
          onSubmit={async (values, { setErrors, resetForm }) => {
            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            // Upload the photos to GCS
            const photos = [];
            await Promise.all(
              values.photos.map(async ({ base64Photo }) => {
                const { responseCode: uploadPhotoResponseCode, fileName } =
                  await uploadReviewPhoto(
                    base64Photo.replace(/^data:image\/\w+;base64,/, '')
                  );

                const uploadReviewPhotoCallbacks = {
                  uploaded: () => photos.push(fileName),
                  invalidFields: () => alert.error('Invalid fields.'),
                  internalError: () =>
                    alert.error('Oops, something went wrong.'),
                };

                switch (uploadPhotoResponseCode) {
                  case 200:
                    uploadReviewPhotoCallbacks.uploaded();
                    break;
                  case 400:
                    uploadReviewPhotoCallbacks.invalidFields();
                    break;
                  case 500:
                    uploadReviewPhotoCallbacks.internalError();
                    break;
                  default:
                    break;
                }
              })
            );

            // Then we create the review
            const newReview = {
              employeeId: user.id,
              employeeFullName: user.fullName,
              vehicleId,
              rego:
                values.name === 'Hired Vehicle/Plant' ? values.rego : undefined,
              hours: values.hours,
              isOilChecked: values.isOilChecked,
              isVehicleChecked: values.isVehicleChecked,
              isVehicleBroken: values.isVehicleBroken,
              photos,
              notes: values.notes,
            };

            const {
              responseCode: createReviewResponseCode,
              reviewId: newlyCreatedReviewId,
            } = await createReview(newReview);

            const createReviewCallbacks = {
              created: () => {
                alert.success('Review created!');
                createNewTimesheetFunc(newlyCreatedReviewId);
              },
              invalidFields: () => alert.error('Invalid fields.'),
              internalError: () => alert.error('Oops, something went wrong.'),
            };

            switch (createReviewResponseCode) {
              case 200:
                createReviewCallbacks.created();
                break;
              case 400:
                createReviewCallbacks.invalidFields();
                break;
              case 500:
                createReviewCallbacks.internalError();
                break;
              default:
                break;
            }

            handleClose();
          }}
        >
          {({ errors, values, setFieldValue }) => (
            <>
              <Text type={textTypes.BODY.XS}>Vehicle Name</Text>

              <Text
                type={textTypes.HEADING.XXS}
                className={styles.CreateReviewModal_name}
              >
                {vehicle?.name}
              </Text>

              {(vehicle?.rego || values.name === 'Hired Vehicle/Plant') && (
                <ControlledInput
                  className={styles.CreateReviewModal_withMarginBottom}
                  name="rego"
                  placeholder={`WOF/Rego${
                    values.name === 'Hired Vehicle/Plant' ? '*' : ''
                  }`}
                  value={values.rego}
                  error={errors.rego}
                  onChange={(e) => setFieldValue('rego', e.target.value)}
                  disabled={vehicle?.rego}
                />
              )}

              <ControlledInput
                className={styles.CreateReviewModal_withMarginBottom}
                name="hours"
                placeholder="Hours Traveled*"
                icon="schedule"
                value={values.hours}
                error={errors.hours}
                onChange={(e) => setFieldValue('hours', e.target.value)}
              />

              <Text
                type={textTypes.HEADING.XXXS}
                className={styles.CreateReviewModal_withMarginBottom}
              >
                Condition
              </Text>

              <Checkbox
                className={styles.CreateReviewModal_checkbox}
                name="isOilChecked"
                label="Oil Checked"
                onChange={() => {
                  setFieldValue('isOilChecked', !values.isOilChecked);
                }}
                checked={values.isOilChecked}
              />

              <Checkbox
                className={styles.CreateReviewModal_checkbox}
                name="isVehicleChecked"
                label="Vehicle/Plant Checked"
                onChange={() => {
                  setFieldValue('isVehicleChecked', !values.isVehicleChecked);
                }}
                checked={values.isVehicleChecked}
              />

              <Checkbox
                className={cn(
                  styles.CreateReviewModal_checkbox,
                  styles.CreateReviewModal_checkbox___red,
                  styles.CreateReviewModal_withMarginBottom
                )}
                name="isVehicleBroken"
                label="Vehicle Broken"
                onChange={() => {
                  setFieldValue('isVehicleBroken', !values.isVehicleBroken);
                }}
                checked={values.isVehicleBroken}
              />

              <Text type={textTypes.HEADING.XXXS}>Photos</Text>

              {values.isVehicleBroken && (
                <Text
                  type={textTypes.BODY.SM}
                  colorClass={colorClasses.RED['300']}
                  className={styles.CreateReviewModal_warning}
                >
                  *We will notify the company, please take photos to record the
                  damage.
                </Text>
              )}

              <ImageUploading
                multiple
                value={values.photos}
                onChange={(photoList) => setFieldValue('photos', photoList)}
                maxNumber={10}
                dataURLKey="base64Photo"
              >
                {({ imageList, onImageUpload, onImageRemove }) => (
                  <div className={styles.CreateReviewModal_photos}>
                    <div
                      className={styles.CreateReviewModal_photos_addPhotoBox}
                    >
                      <IconButton
                        icon="add"
                        className={styles.CreateReviewModal_photos_addPhoto}
                        onClick={onImageUpload}
                      />
                      Add New Photo
                    </div>

                    {errors.photos && (
                      <Text
                        className={styles.CreateReviewModal_photos_error}
                        type={textTypes.BODY.XS}
                        colorClass={colorClasses.RED['400']}
                      >
                        {errors.photos}
                      </Text>
                    )}

                    {imageList.length ? (
                      <div className={styles.CreateReviewModal_photos_grid}>
                        {imageList.map((image, index) => (
                          <div
                            className={
                              styles.CreateReviewModal_photos_photoWrapper
                            }
                            key={index}
                          >
                            <img
                              src={image.base64Photo}
                              className={
                                styles.CreateReviewModal_photos_photoWrapper_photo
                              }
                              alt=""
                            />
                            <div
                              className={
                                styles.CreateReviewModal_photos_photoWrapper_remove
                              }
                            >
                              <IconButton
                                icon="close"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </ImageUploading>

              <Text type={textTypes.HEADING.XXXS}>Other Notes</Text>

              <ControlledTextArea
                className={cn(styles.CreateReviewModal_notes)}
                name="notes"
                placeholder="Add Notes"
                value={values.notes}
                error={errors.notes}
                onChange={(e) => setFieldValue('notes', e.target.value)}
              />
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};

CreateReviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  vehicleId: PropTypes.string.isRequired,
  createNewTimesheetFunc: PropTypes.func.isRequired,
};

export default CreateReviewModal;
