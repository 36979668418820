import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { Bar, Pie } from 'react-chartjs-2';

import styles from './styles.module.scss';
import PreloaderSmall from './Preloader/Small';
import PreloaderLarge from './Preloader/Large';
import { Card, ControlledSelect, Grid, Icon, Text } from '../../../components/elements';
import {
  colorClasses,
  colorHexCodes,
  gridTypes,
  textTypes,
  timesheetTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { useClients, useEmployees, useProjects, useTimesheets } from '../../../hooks';
import {
  getDayNameFromDayId,
  getPrevOrAfterDate,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
  timeStringToMinutes,
} from '../../../utils/datetime';
import {
  generateDayIds,
  getDateEquivalence,
  getHours,
} from '../../../utils/timesheets';
import { useParams } from 'react-router-dom';
import moment from "moment";
import axios from "axios";
import config from "../../../services/config";


const options = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },

      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: window.innerWidth < 992 ? 'top' : 'left',
      align: window.innerWidth < 992 ? 'center' : 'start',
      fullSize: true,
      labels: {
        font: {
          size: window.innerWidth < 768 ? 12 : 14,
        },
        boxWidth: 60,
        usePointStyle: true,
        pointStyle: 'rectRounded',
      },
    },
  },
  layout: {
    padding: window.innerWidth > 768 ? {
      top: 5,
      bottom: 10,
      right: 5,
      left: 0
    } : "",
  },

};
const pychartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      labels: {
        font: {
          size: 12
        },
        usePointStyle: true,
        pointStyle: 'rectRounded',
      },
    },
    title: {
      display: true,
    },
  },

}
const projectOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      align: 'start',
      column: 1,
      labels: {
        font: {
          size: 12,
        },
        usePointStyle: true,
        pointStyle: 'rectRounded',
      },
    },
    title: {
      display: true,
    },
  },

}


const Dashboard = () => {
  const { user, projectId } = useContext(UserContext);

  let { clientId } = useParams()

  const numberOfStillAnEmployee = user?.employees.filter(
    (e) => e.isStillAnEmployee === true
  ).length - 1;

  const [previousWeekHours, setPreviousWeekHours] = useState([]);
  const [currentWeekHours, setCurrentWeekHours] = useState([]);
  const [clientTotalHours, setClientTotalHours] = useState([]);
  const [projectsTotalHours, setProjectsHours] = useState([]);
  const [totalLeaveTaken, setTotalLeaveTaken] = useState([]);
  const [totalProject, setTotalProject] = useState([]);
  const [totalclientsWork, setTotalClientsWorks] = useState([]);

  const [totalLunchBreakHours, setTotalLunchBreakHours] = useState([]);

  const [totalSpentOnClientHr,setTotalSpentOnClientHr] = useState(0);
  const [projectHours, setProjectHour] = useState(0);
  const [avgEmployeeHours,setAvgEmployeeHours] = useState("0.00");

  const [selectedEmployee, setSelectedEmployee] = useState({
    label: 'All',
    value: 'All',
  });

  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [projectsOptions, setProjectsOptions] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [clientValue, setClientValue] = useState(undefined);
  const [clientName, setClientName] = useState('');
  const [projectValue, setProjectValue] = useState(undefined);


  const [selectClient, setSelectClient] = useState('');

  const { isLoading: isEmployeesLoading, employees } = useEmployees({
    employerId: user.id,
  });

  const { isLoading: isProjectsLoading, projects } = useProjects({
    clientId: clientValue,
  })

  const { isLoading: isClientsLoading, clients } = useClients({
    userId: user.id,
  });

  const selectedClient = clients.findIndex(val => val.id === clientId);

  const dayIds = generateDayIds(
    timesheetTypes.WEEKLY,
    getDateEquivalence(timesheetTypes.WEEKLY, moment().startOf('week').format('Y-MM-DD'), 'start'),
    user.timesheetStartWeek
  );

  // const dayNames = [];
 const [dayNames,setDayNames] = useState([]);
 const [barChartCurrentTitle,setBarCharCurrentTitle] = useState('This Week');
 const [barChartPreviousTitle,setBarCharPreviousTitle] = useState('Last Week');
 const [clientBackgroundColors,setClientBackgroundColors] = useState([]);
  // dayIds.forEach((dayId) => {
  //   dayNames.push(getDayNameFromDayId(dayId));
  // });
  useEffect(() => {
    setDayNames([]);
    
    setTimeout(() => {
      dayIds.forEach((dayId) => {
        dayNames.push(getDayNameFromDayId(dayId));
      });
      setDayNames(dayNames);
    },1000);
  }, []);

  // Data for the graph
  
  const data = {
    labels: dayNames,
    datasets: [
      {
        label: barChartCurrentTitle,
        data: currentWeekHours,
        backgroundColor: selectedEmployee.value === "All" ? colorHexCodes.VIOLET['500'] : colorHexCodes.BLUE['600'],
        barThickness: window.innerWidth < 768 ? 15 : 30,
      },
      {
        label: barChartPreviousTitle,
        data: previousWeekHours,
        backgroundColor: selectedEmployee.value === "All" ? colorHexCodes.VIOLET['600'] : colorHexCodes.BLUE['500'],
        barThickness: window.innerWidth < 768 ? 15 : 30,
      },
    ],
  };

  // Data for the client graph
  const clientData = {
    labels: Object.keys(clientTotalHours).map((key) => key),
    datasets: [
      {
        data: Object.keys(clientTotalHours).map((key) => clientTotalHours[key]),
        backgroundColor: [
          selectedEmployee.value === "All" ? colorHexCodes.VIOLET['700'] : colorHexCodes.BLUE['500'],
          selectedEmployee.value === "All" ? colorHexCodes.VIOLET['600'] : colorHexCodes.BLUE['400'],
          selectedEmployee.value === "All" ? colorHexCodes.VIOLET['500'] : colorHexCodes.BLUE['300'],
        ],
        borderWidth: 0,
      },
    ],
  }
  // Data for the project graph
  const projectData = {
    labels: Object.keys(projectsTotalHours).map((key) => key),
    datasets: [
      {
        data: Object.keys(projectsTotalHours).map((key) => projectsTotalHours[key]),
        backgroundColor:[
          selectedEmployee.value === "All" ? colorHexCodes.VIOLET['100'] : colorHexCodes.BLUE['200'],
          selectedEmployee.value === "All" ? colorHexCodes.VIOLET['700'] : colorHexCodes.BLUE['500'],
          selectedEmployee.value === "All" ? colorHexCodes.VIOLET['600'] : colorHexCodes.BLUE['400'],
        ],
        borderWidth: 0,
      },
    ],
  }

  const [viewType, setViewType] = useState({
    value: timesheetTypes.WEEKLY,
    label: `View by ${timesheetTypes.WEEKLY}`,
  });

  let currentWeekStartDate = moment().startOf('week').format('Y-MM-DD');

  let currentWeekEndDate = moment().endOf('week').format('Y-MM-DD');
  
  const previousWeekStartDate = getPrevOrAfterDate(
    currentWeekStartDate,
    7,
    'subtract'
  );

  const previousWeekEndDate = getPrevOrAfterDate(
    currentWeekEndDate,
    7,
    'subtract'
  );

  useEffect(() => {
    if (viewType.value === "Weekly") {
      currentWeekStartDate = moment().startOf('week').format('Y-MM-DD');
      currentWeekEndDate = moment().endOf('week').format('Y-MM-DD');
    }

    if (viewType.value === "Fortnightly") {
      currentWeekEndDate = moment().endOf('week').format('Y-MM-DD');
      currentWeekStartDate = moment(currentWeekEndDate).subtract(14, 'days').format('Y-MM-DD');
    }

    if (viewType.value === "Monthly") {
      currentWeekStartDate = moment().startOf('month').format('Y-MM-DD');
      currentWeekEndDate = moment().endOf('month').format('Y-MM-DD');
    }
    getTimesheetData();
  }, [viewType.value]);


  const {
    isLoading: isPreviousWeekTimesheetsLoading,
    timesheets: previousWeekTimesheets,
  } = useTimesheets({
    employerId: user.id,
    userId: selectedEmployee.value,
    startDate: previousWeekStartDate,
    endDate: previousWeekEndDate,
  });


  const {
    isLoading: isCurrentWeekTimesheetsLoading,
    timesheets: currentWeekTimesheets,
  } = useTimesheets({
    employerId: user.id,
    userId: selectedEmployee.value,
    startDate: currentWeekStartDate,
    endDate: currentWeekEndDate,
  });

  useEffect(() => {
    getTimesheetData();
  }, [isPreviousWeekTimesheetsLoading, previousWeekTimesheets]);

  useEffect(() => {
    getTimesheetData();
  }, [isCurrentWeekTimesheetsLoading, currentWeekTimesheets]);

  const getTimesheetData = (filterByClientId = "",filterViewType = "") => {

    if (isPreviousWeekTimesheetsLoading || isCurrentWeekTimesheetsLoading) {
      return; // Early return if loading
    }

    // Memoized arrays
    const tempLeave = [];
    const count = new Set(); // Use a Set to ensure unique values
    const totalClient = new Set(); // Use a Set to ensure unique values
    const totalLunchDuration = [];
    const clientHours = {};
    let clientIds = [];
    let clientColorCodes = [];
    const projectHours = {};
    const employeeIds = [];
    
    let tempProjectHours = 0;
    let tempClientHours = 0;

    // Get hours for each day for the current week and previous week
    const tempCurrentWeekHours = [];
    const tempPreviousWeekHours = [];

    let arrayLength = 7;
    if (viewType.value === "Weekly") {
      arrayLength = 7;

      setDayNames([]);
      const tempArray = [];
      dayIds.forEach((dayId) => {
        tempArray.push(getDayNameFromDayId(dayId));
      });
      setDayNames(tempArray);

      setBarCharCurrentTitle('This Week');
      setBarCharPreviousTitle('Last Week');
    }

    if (viewType.value === "Fortnightly") {
      arrayLength = 14;
    }
    if (viewType.value === "Monthly") {
      arrayLength = 30;
    }

    const processTimesheets = (timesheets, startDate, hoursArray, arrayLength,countForCurrent) => {

      [...Array(arrayLength)].forEach((_, i) => {
        const timesheetDate = getPrevOrAfterDate(startDate, i, 'add');

        const timesheetsForDay = timesheets.filter(
          (timesheet) => timesheet.date === timesheetDate
        );

        let totalHours = 0;

        timesheetsForDay.forEach(
          async ({
            date,
            startTimeDetails = {
              endTime: undefined,
              actualTime: undefined,
            },
            endTimeDetails = {
              endTime: undefined,
              actualTime: undefined,
            },
            isLunchBreak,
            lunchBreakDuration,
            isTravelCharge,
            leaveType,
            publicHolidayMultiplier,
            projectId,
            clientId,
            clientName,
            projectName,
            userId,
          }) => {
            const startTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(startTimeDetails.startTime)
            );
            const endTimeTimestamp = convertDateTimeToTimestamp(
              date,
              convertTimeTo24HourFormat(endTimeDetails.endTime)
            );

            totalHours += Number(
              getHours(
                startTimeTimestamp,
                endTimeTimestamp,
                isLunchBreak,
                lunchBreakDuration,
                isTravelCharge,
                leaveType,
                publicHolidayMultiplier
              )
            );

            const countHours = Number(
              getHours(
                startTimeTimestamp,
                endTimeTimestamp,
                isLunchBreak,
                lunchBreakDuration,
                isTravelCharge,
                leaveType,
                publicHolidayMultiplier
              )
            );

            if (leaveType !== 'None') {
              tempLeave.push(leaveType);
            }

            if (projectId){
              count.add(projectId);
            }
            if (clientId) {
              totalClient.add(clientId);
            }

            if (lunchBreakDuration) {
              totalLunchDuration.push(lunchBreakDuration);
            }
            if (countForCurrent) {
              if (clientName) {
                if (!clientHours[clientName]) {
                  clientHours[clientName] = 0;
                }
                clientHours[clientName] += countHours;
                clientIds.push(clientId);

                tempClientHours += countHours;
              }

              if (projectName) {
                if (filterByClientId === "") {
                  if (!projectHours[projectName]) {
                    projectHours[projectName] = 0;
                  }
                  projectHours[projectName] += countHours;
                  tempProjectHours += countHours;  
                } else {
                  if (filterByClientId === clientId){
                    if (!projectHours[projectName]) {
                      projectHours[projectName] = 0;
                    }
                    projectHours[projectName] += countHours;
                    tempProjectHours += countHours;  
                  }
                }
              }
              if (userId) {
                employeeIds.push(userId);
              }
            }
          }
        );
        hoursArray.push(totalHours);
      });
    };

    processTimesheets(currentWeekTimesheets, currentWeekStartDate, tempCurrentWeekHours, arrayLength,true);
    processTimesheets(previousWeekTimesheets, previousWeekStartDate, tempPreviousWeekHours, arrayLength,false);


    const uniqueSet = new Set(employeeIds);

    // Convert the Set back to an array (if needed)
    const uniqueArray = [...uniqueSet];

    // Count the unique elements
    let uniqueCount = uniqueArray.length;

    setTotalLunchBreakHours(totalLunchDuration);
    setTotalClientsWorks(Array.from(totalClient));
    setTotalProject(Array.from(count));

    setCurrentWeekHours(tempCurrentWeekHours);
    setPreviousWeekHours(tempPreviousWeekHours);
    setTotalLeaveTaken(tempLeave);
    setClientTotalHours(clientHours);
    setProjectsHours(projectHours);

    clientIds.map(async(item) => {
      const getClient = await getClientDetails(item);
      clientColorCodes.push(getClient);
      return item;
    });
    setClientBackgroundColors(clientColorCodes);
    if (uniqueCount == 0) {
      uniqueCount = 1;
    }

    const totalHours = tempCurrentWeekHours.reduce((a, b) => a + b, 0).toFixed(2) / uniqueCount;

    if (user?.hoursAndMinutesFormat) {
      setProjectHour(hoursWithDecimalToHoursAndMinutes(tempProjectHours.toFixed(2)));
      setAvgEmployeeHours(hoursWithDecimalToHoursAndMinutes(totalHours.toFixed(2)));
      setTotalSpentOnClientHr(hoursWithDecimalToHoursAndMinutes(tempClientHours.toFixed(2)));
    } else {
      setProjectHour(tempProjectHours.toFixed(2));
      setAvgEmployeeHours(totalHours.toFixed(2));
      setTotalSpentOnClientHr(tempClientHours.toFixed(2));
    }
  }

  const getFortNightlyTimesheetData = async (value) => {
    let formattedCurrentWeekEndDate = "";
    let formattedLastWeekStartDate = "";

    let formattedPreviousStartDate = "";
    let formattedPreviousEndDate = "";

    if (value === "Fortnightly") {
      const today = moment(); // Get the current date

      const currentWeekEndDate = today.clone().endOf('week');
      const lastWeekStartDate = currentWeekEndDate.clone().subtract(1, 'week').startOf('week');

      formattedCurrentWeekEndDate = currentWeekEndDate.format('YYYY-MM-DD');
      formattedLastWeekStartDate = lastWeekStartDate.format('YYYY-MM-DD');

      formattedPreviousStartDate = moment(formattedLastWeekStartDate).subtract(14,'days').format('Y-MM-DD');
      formattedPreviousEndDate = moment(formattedLastWeekStartDate).subtract(1,'day').format('Y-MM-DD');
    }

    if (value === "Monthly") {
      formattedCurrentWeekEndDate = moment().endOf('month').format('YYYY-MM-DD');
      formattedLastWeekStartDate = moment().startOf('month').format('YYYY-MM-DD');

      formattedPreviousStartDate = moment(formattedLastWeekStartDate).subtract(1,'month').format('Y-MM-DD');
      formattedPreviousEndDate = moment(formattedLastWeekStartDate).subtract(1,'day').format('Y-MM-DD');
    }

    if (value === "Monthly" || value === "Fortnightly") {
      if (formattedLastWeekStartDate !== "" && formattedLastWeekStartDate !== "") {
        const body = {
          employerId: user.id,
          userId: selectedEmployee.value,
          startDate: formattedLastWeekStartDate,
          endDate: formattedCurrentWeekEndDate,
        };
        await callTimesheetApi(body,'current',value);
      }

      if (formattedPreviousStartDate !== "" && formattedPreviousEndDate !== "") {
        const body = {
          employerId: user.id,
          userId: selectedEmployee.value,
          startDate: formattedPreviousStartDate,
          endDate: formattedPreviousEndDate,
        };
        await callTimesheetApi(body,'previous');
      }
    }
    
    if (value === "Weekly") {
      getTimesheetData();
    }
  }

  const getClientDetails = async (clientId) => {
    const BASE_URL = `${config.API_URL}/clients/singleGet`;
    const data = {
      clientId,
    };
    let colorCode = "";
    const response = await axios.post(`${BASE_URL}`, data).then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
    });

    if (response) {
      colorCode = response.client.colorCode ?? "";
    }
    return colorCode;
  }

  const callTimesheetApi = async (data,type,value) => {
    const BASE_URL = `${config.API_URL}/timesheets`;
    const response = await axios.post(`${BASE_URL}/get`, data).then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
    });
    if (response) {
      const weeklyTotals = {};

      response.timesheets.forEach(item => {
        const date = new Date(item.date);
        const weekNumber = Math.ceil(date.getDate() / 7); // Assuming the first week starts on the 1st day of the month

        if (!weeklyTotals[`Week ${weekNumber}`]) {
          weeklyTotals[`Week ${weekNumber}`] = 0;
        }

        const startTimeTimestamp = convertDateTimeToTimestamp(
            date,
            convertTimeTo24HourFormat(item.startTimeDetails.startTime)
        );
        const endTimeTimestamp = convertDateTimeToTimestamp(
            date,
            convertTimeTo24HourFormat(item.endTimeDetails.endTime)
        );

        let totalHours = Number(
            getHours(
                startTimeTimestamp,
                endTimeTimestamp,
                item.isLunchBreak,
                item.lunchBreakDuration,
                item.isTravelCharge,
                item.leaveType,
                item.publicHolidayMultiplier
            )
        );

        weeklyTotals[`Week ${weekNumber}`] += totalHours;
      });

      const labels = Object.keys(weeklyTotals);
      let totalHours = Object.values(weeklyTotals);

      setDayNames(labels);

      if (value === "Monthly") {
        setBarCharCurrentTitle('This Month');
        setBarCharPreviousTitle('Last Month');
      }

      if (value === "Fortnightly") {
        setBarCharCurrentTitle('This Fortnightly');
        setBarCharPreviousTitle('Last Fortnightly');
      }

      if (type === "current") {
        setCurrentWeekHours(totalHours);
      }
      if (type === "previous") {
        setPreviousWeekHours(totalHours);
      }
    }
  }

  const getWeekArray = (startDate,endDate) => {
    const weekNumbers = [];
    let currentWeek = startDate.clone().startOf('week');
    let weekNumber = 1;

    while (currentWeek.isBefore(endDate)) {
      weekNumbers.push(`Week ${weekNumber}`);
      currentWeek.add(1, 'week');
      weekNumber++;
    }

    return weekNumbers;
  }

  const getWeekStartAndEndDates = (startDate,endDate) => {
    const weekDates = [];
    let currentWeekStart = startDate.clone().startOf('week');
    let currentWeekEnd = currentWeekStart.clone().endOf('week');

    let weekNo = 1;
    while (currentWeekStart.isBefore(endDate) || currentWeekStart.isSame(endDate, 'day')) {
      weekDates.push({
        startDate: currentWeekStart.format('YYYY-MM-DD'),
        endDate: currentWeekEnd.format('YYYY-MM-DD'),
        weekNumber: "Week " + weekNo++,
      });

      currentWeekStart.add(1, 'week');
      currentWeekEnd.add(1, 'week');
    }

    return weekDates;
  }

  const onChangeProjectByClient = (value) => {
    getTimesheetData(value);
  }

  function groupDataByFortnights(data) {
    const fortnightlyData = {};

    data.forEach(item => {
      const date = new Date(item.date);
      const fortnight = `${date.getFullYear()}-${Math.floor(date.getMonth() / 2)}`;

      if (!fortnightlyData[fortnight]) {
        fortnightlyData[fortnight] = 0;
      }

      fortnightlyData[fortnight] += item.totalHours;
    });

    return fortnightlyData;
  }

  function transformDataForChart(data) {
    const fortnightlyData = groupDataByFortnights(data);

    // Convert fortnightly data into an array of objects for the chart
    const chartData = Object.keys(fortnightlyData).map((fortnight, index) => ({
      week: index + 1,
      totalHours: fortnightlyData[fortnight],
    }));

    return chartData;
  }
  // generate employee
  useEffect(() => {
    if (!isEmployeesLoading) {
      const options = [
        {
          label: 'All',
          value: 'All',
        },
      ];
      employees.forEach(({ id, fullName }) => {
        const option = {
          label: fullName,
          value: id,
        };
        options.push(option);
      });
      setEmployeeOptions(options);
    }
  }, [isEmployeesLoading]);

  // Generate clients options
  useEffect(() => {
    if (!isClientsLoading) {
      const options = [{
        label: 'All',
        value: ''
      }];
      clients.forEach(({ id, name }) => {
        const option = {
          label: name,
          value: id,
        };
        options.push(option);
      });
      setClientsOptions(options);
      if (clientId !== undefined) {
        setClientValue(clients[selectedClient].id);
      }
    }
  }, [isClientsLoading]);

  // Generate projects options
  useEffect(() => {
    if (!isProjectsLoading) {
      const options = [];
      if (projects.length > 0) {
        projects.forEach(({ id, name, isFinshed }) => {
          const option = {
            label: name,
            value: id,
          };
          if (isFinshed === undefined) {
            options.push(option);
          } else if (!isFinshed) {
            options.push(option);
          }
        });
      }
      setProjectsOptions(options);
    }
  }, [isProjectsLoading]);

  const handlePieChartClick = (e) => {
    console.log(e,'e');
  }

  return (
    <div className={styles.Dashboard}>
      <Grid type={gridTypes.ONE} className={styles.Dashboard_cards}>
        <div className={styles.Dashboard_firstRow}>
          <Text type={textTypes.HEADING.XS}>Hello, {user.fullName}!</Text>
          <Text type={textTypes.BODY.MD}>Here is your current company status report</Text>
        </div>
      </Grid>
      <Grid type={gridTypes.FILTERGRID} className={styles.Dashboard_withMargin}>
        <Text type={textTypes.HEADING.XS}>Select Employee:</Text>
        <ControlledSelect
          className={styles.Dashboard_selectItem}
          options={employeeOptions}
          name="employee"
          placeholder="Employee Name"
          value={selectedEmployee}
          onChange={(val) => {
            setSelectedEmployee({
              label: val.label,
              value: val.value,
            });
          }}
        />
        <Text></Text>
        <ControlledSelect
          className={styles.Dashboard_selectItem}
          options={[
            {
              value: timesheetTypes.WEEKLY,
              label: `View by ${timesheetTypes.WEEKLY}`,
            },
            {
              value: timesheetTypes.FORTNIGHTLY,
              label: `View by ${timesheetTypes.FORTNIGHTLY}`,
            },
            {
              value: timesheetTypes.MONTHLY,
              label: `View by ${timesheetTypes.MONTHLY}`,
            },
          ]}
          name="viewType"
          placeholder="Period of Time"
          value={viewType}
          onChange={async (val) => {
            setViewType(val)
            getTimesheetData()
            await getFortNightlyTimesheetData(val.value)
          }}
        />
      </Grid>

      {selectedEmployee.value === "All" ?
        <Grid type={gridTypes.THREE} className={styles.Dashboard_cards}>
          {isPreviousWeekTimesheetsLoading || isCurrentWeekTimesheetsLoading ? (
            <>
              <PreloaderSmall />
              <PreloaderSmall />
              <PreloaderSmall />
            </>
          ) : (
            <>
              <Card className={styles.Dashboard_cards_card}>
                <Grid className={styles.Dashboard_cards_card_grid}>
                  <div className={styles.Dashboard_cards_card_leftColumn}>
                    <Text
                      type={textTypes.HEADING.SM}
                    >
                      {numberOfStillAnEmployee}
                    </Text>
                    <Text type={textTypes.BODY.LG}>Number Of Employees</Text>
                  </div>
                  <div className={styles.Dashboard_cards_card_rightColumn}>
                    <Icon
                      icon="badge"
                      className={cn(
                        styles.Dashboard_cards_card_icon,
                        styles.Dashboard_cards_card_icon___violet
                      )}
                    />
                  </div>
                </Grid>
              </Card>
              <Card className={styles.Dashboard_cards_card}>
                <Grid className={styles.Dashboard_cards_card_grid}>
                  <div className={styles.Dashboard_cards_card_leftColumn}>
                    <Text
                      type={textTypes.HEADING.SM}
                    // colorClass={colorClasses.VIOLET['400']}
                    >
                      {user?.hoursAndMinutesFormat
                        ? hoursWithDecimalToHoursAndMinutes(
                          currentWeekHours.reduce((a, b) => a + b, 0).toFixed(2)
                        )
                        : currentWeekHours.reduce((a, b) => a + b, 0).toFixed(2)}
                    </Text>
                    <Text type={textTypes.BODY.LG}>
                      Total Employee Hours
                    </Text>
                  </div>

                  <div className={styles.Dashboard_cards_card_rightColumn}>
                    <Icon
                      icon="schedule"
                      className={cn(
                        styles.Dashboard_cards_card_icon,
                        styles.Dashboard_cards_card_icon___green
                      )}
                    />
                  </div>
                </Grid>
              </Card>
              <Card className={styles.Dashboard_cards_card}>
                <Grid className={styles.Dashboard_cards_card_grid}>
                  <div className={styles.Dashboard_cards_card_leftColumn}>

                    <Text
                      type={textTypes.HEADING.SM}
                    // colorClass={colorClasses.BLUE['400']}
                    >
                      {avgEmployeeHours}
                    </Text>
                    <Text type={textTypes.BODY.LG}>Avg. Employee Hours</Text>
                  </div>

                  <div className={styles.Dashboard_cards_card_rightColumn}>
                    <Icon
                      icon="watch"
                      className={cn(
                        styles.Dashboard_cards_card_icon,
                        styles.Dashboard_cards_card_icon___blue
                      )}
                    />
                  </div>
                </Grid>
              </Card>
            </>
          )}
        </Grid>
        :
        <Grid type={gridTypes.FOUR} className={styles.Dashboard_cards}>
          {isPreviousWeekTimesheetsLoading || isCurrentWeekTimesheetsLoading ? (
            <>
              <PreloaderSmall />
              <PreloaderSmall />
              <PreloaderSmall />
              <PreloaderSmall />
            </>
          ) : (
            <>
              <Card className={styles.Dashboard_cards_card}>
                <Grid className={styles.Dashboard_cards_card_grid}>
                  <div className={styles.Dashboard_cards_card_leftColumn}>
                    <Text
                      type={textTypes.HEADING.SM}
                    >
                      {
                        totalLunchBreakHours.length > 0 ?
                          user?.hoursAndMinutesFormat ? (
                            hoursWithDecimalToHoursAndMinutes(totalLunchBreakHours.reduce((a, b) => a + timeStringToMinutes(b), 0) / totalLunchBreakHours.length)
                          ) : (totalLunchBreakHours.reduce((a, b) => a + timeStringToMinutes(b), 0) / totalLunchBreakHours.length).toFixed(2)
                          : "0.00"
                      }
                    </Text>
                    <Text type={textTypes.BODY.LG}>AVG. Lunch Break</Text>
                  </div>

                  <div className={styles.Dashboard_cards_card_rightColumn}>
                    <Icon
                      icon="restaurant"
                      className={cn(
                        styles.Dashboard_cards_card_icon,
                        styles.Dashboard_cards_card_icon___violet
                      )}
                    />
                  </div>
                </Grid>
              </Card>

              <Card className={styles.Dashboard_cards_card}>
                <Grid className={styles.Dashboard_cards_card_grid}>
                  <div className={styles.Dashboard_cards_card_leftColumn}>
                    <Text type={textTypes.HEADING.SM}>
                      {totalLeaveTaken.length}
                    </Text>
                    <Text type={textTypes.BODY.LG}>
                      Total Leaves Taken
                    </Text>
                  </div>

                  <div className={styles.Dashboard_cards_card_rightColumn}>
                    <Icon
                      icon="logout"
                      className={cn(
                        styles.Dashboard_cards_card_icon,
                        styles.Dashboard_cards_card_icon___gray
                      )}
                    />
                  </div>
                </Grid>
              </Card>

              <Card className={styles.Dashboard_cards_card}>
                <Grid className={styles.Dashboard_cards_card_grid}>
                  <div className={styles.Dashboard_cards_card_leftColumn}>
                    <Text type={textTypes.HEADING.SM}>
                      {totalProject.length}
                    </Text>
                    <Text type={textTypes.BODY.LG} >Projects Worked</Text>
                  </div>

                  <div className={styles.Dashboard_cards_card_rightColumn}>
                    <Icon
                      icon="task"
                      className={cn(
                        styles.Dashboard_cards_card_icon,
                        styles.Dashboard_cards_card_icon___red
                      )}
                    />
                  </div>
                </Grid>
              </Card>

              <Card className={styles.Dashboard_cards_card}>
                <Grid className={styles.Dashboard_cards_card_grid}>
                  <div className={styles.Dashboard_cards_card_leftColumn}>
                    <Text type={textTypes.HEADING.SM} >
                      { totalclientsWork.length }
                    </Text>
                    <Text type={textTypes.BODY.LG}>
                      Clients Worked
                    </Text>
                  </div>

                  <div className={styles.Dashboard_cards_card_rightColumn}>
                    <Icon
                      icon="person"
                      className={cn(
                        styles.Dashboard_cards_card_icon,
                        styles.Dashboard_cards_card_icon___blue
                      )}
                    />
                  </div>
                </Grid>
              </Card>
            </>
          )}
        </Grid>
      }

      {isPreviousWeekTimesheetsLoading || isCurrentWeekTimesheetsLoading ? (
        <PreloaderLarge />
      ) : (
        <>
          <Card className={styles.Dashboard_cards_card}>
            <Grid className={styles.Dashboard_displayGrid}>
              {
                viewType.value === 'Weekly' ?
                  <div className={styles.Dashboard_displayGrid}>
                    <Text type={textTypes.HEADING.XS}>This Week v.s. Last Week</Text>
                  </div> : ""
              }
              {
                viewType.value === 'Fortnightly' ?
                  <div className={styles.Dashboard_displayGrid}>
                    <Text type={textTypes.HEADING.XS}>This fortnight v.s. Last fortnight</Text>
                  </div> : ""
              }
              {
                viewType.value === 'Monthly' ?
                  <div className={styles.Dashboard_displayGrid}>
                    <Text type={textTypes.HEADING.XS}>This Month v.s. Last Month</Text>
                  </div> : ""
              }

              {
                selectedEmployee.value !== "All" ?
                  <div className={styles.Dashboard_displayGrid_item}>
                    {
                      viewType.value === 'Weekly' ?
                        <Text type={textTypes.HEADING.XXS} colorClass={colorClasses.NEUTRAL['500']} className={styles.Dashboard_fontBold}>Total Hours this Week:</Text> : ''
                    }
                    {
                      viewType.value === 'Fortnightly' ?
                        <Text type={textTypes.HEADING.XXS} colorClass={colorClasses.NEUTRAL['500']} className={styles.Dashboard_fontBold}>Total Hours this Fortnight:</Text> : ''
                    }
                    {
                      viewType.value === 'Monthly' ?
                        <Text type={textTypes.HEADING.XXS} colorClass={colorClasses.NEUTRAL['500']} className={styles.Dashboard_fontBold}>Total Hours this Month:</Text> : ''
                    }
                    <Text
                      type={textTypes.HEADING.XXS}
                      colorClass={colorClasses.GREEN['400']}
                    >
                      {user?.hoursAndMinutesFormat
                        ? hoursWithDecimalToHoursAndMinutes(
                          currentWeekHours.reduce((a, b) => a + b, 0).toFixed(2)
                        )
                        : currentWeekHours.reduce((a, b) => a + b, 0).toFixed(2)
                      }
                    </Text>
                  </div>
                  : ""
              }
            </Grid>
            <div className={styles.Dashboard_barGraph}>
              <Bar data={data} options={options} />
            </div>
          </Card>

          <Grid type={gridTypes.TWO} className={styles.Dashboard_cards}>
            {isPreviousWeekTimesheetsLoading || isCurrentWeekTimesheetsLoading ? (
              <>
                <PreloaderSmall />
                <PreloaderSmall />
              </>
            ) : (
              <>
                <Card className={styles.Dashboard_cards_card}>
                  <Grid className={styles.Dashboard_displayGrid}>
                    <div className={styles.Dashboard_displayGrid}>
                      <Text type={textTypes.HEADING.XS}>Hours Spent on Clients</Text>
                    </div>
                    <div className={styles.Dashboard_displayGrid_item}>
                      <Text type={textTypes.HEADING.XXS} colorClass={colorClasses.NEUTRAL['500']} className={styles.Dashboard_fontBold}>Total Hours:</Text>
                      <Text
                        type={textTypes.HEADING.XXS}
                        colorClass={colorClasses.GREEN['400']}
                      >
                        {totalSpentOnClientHr}
                      </Text>
                    </div>
                  </Grid>
                  <Grid type={gridTypes.ONE}><Text className={styles.Dashboard_graphMargin}></Text></Grid>
                  <Grid type={gridTypes.ONE} className={styles.Dashboard_pieGraph}>
                    <Pie data={clientData} options={pychartOptions} onClick={(e) => handlePieChartClick(e)} />
                  </Grid>
                </Card>
                <Card className={styles.Dashboard_cards_card}>
                  <Grid className={styles.Dashboard_displayGrid}>
                    <div className={styles.Dashboard_displayGrid}>
                      <Text type={textTypes.HEADING.XS}>Hours Spent on Projects</Text>
                    </div>
                    <div className={styles.Dashboard_displayGrid_item}>
                      <Text type={textTypes.HEADING.XXS} colorClass={colorClasses.NEUTRAL['500']} className={styles.Dashboard_fontBold}>Total Hours:</Text>
                      <Text
                        type={textTypes.HEADING.XXS}
                        colorClass={colorClasses.GREEN['400']}
                      >
                        {projectHours}
                      </Text>
                    </div>
                  </Grid>
                  <Grid type={gridTypes.ONE} className={styles.Dashboard_cards}>
                    <ControlledSelect
                      options={clientsOptions}
                      name="client"
                      placeholder="Select Client"
                      value={selectClient}
                      onChange={(val) => {
                        setSelectClient(val)
                        setClientValue(val.value.toString())
                        setClientName(val.label);
                        onChangeProjectByClient(val.value.toString())
                      }}
                    />
                  </Grid>
                  <Grid type={gridTypes.ONE} className={styles.Dashboard_pieGraph}>
                    <Pie data={projectData} options={projectOptions} />
                  </Grid>
                </Card>
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default Dashboard;
