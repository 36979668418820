import React from 'react';
import PropTypes from 'prop-types';

import { buttonTypes } from '../../../../globals';
import { Modal, Text } from '../../../elements';

const TimesheetSuccessModal = ({ isOpen, handleClose, type }) => (
  <Modal
    isOpen={isOpen}
    handleClose={handleClose}
    title="Success"
    actions={[
      {
        text: 'Finish',
        type: buttonTypes.PRIMARY.GREEN,
        onClick: handleClose,
      },
    ]}
  >
    <Text>
      You have successfully
      {type === 'start' ? ' started a new ' : ' completed the '}
      timesheet entry.
    </Text>
  </Modal>
);

TimesheetSuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['start', 'end']),
};

export default TimesheetSuccessModal;
