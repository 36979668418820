import dateFormat from 'dateformat';
import * as moment from 'moment';

export const convertSecondsToHours = (seconds) => (seconds / 3600).toFixed(2); // Format: hours.minutes

export const getDayIdFromDate = (date) => {
  if (!date) return undefined;

  const d = new Date(date);
  const dayId = d.getDay();

  return dayId;
};

export const getDayShortNameFromDayId = (dayId) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return days[dayId];
};

export const getDayNameFromDayId = (dayId) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  return days[dayId];
};

export const getDayIdFromDayName = (dayName) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  return days.indexOf(dayName);
};

export const convertDateTimeToTimestamp = (date, time) => {
  if (!date || !time) return undefined;

  const dateObject = new Date(date);
  const timeArray = time.split(':'); // Contains hours -> [0] and minutes -> [1]
  dateObject.setHours(Number(timeArray[0]), Number(timeArray[1]), 0, 0);

  return dateObject.getTime() / 1000;
};

export const convertTimestampToDate = (timestamp) => {
  if (!timestamp) return undefined;

  // Timestamp is in seconds, and new Date() only accepts
  // timestamps in milliseconds. So, we multiply 1000 to it.
  const date = new Date(timestamp * 1000);

  return dateFormat(date, 'yyyy-mm-dd');
};

export const checkIfTimeIsValid = (time) => {
  // Regex for 12-hour time format with am/pm
  const timeRegex = /(((0[1-9])|(1[0-2])):([0-5][0-9])\s(A|P|a|p)(M|m))/;
  return timeRegex.test(time);
};

export const checkIf24TimeIsValid = (time) => {
  // Regex for 24-hour time format
  const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  return timeRegex.test(time);
};

export const convertTimeTo24HourFormat = (time) => {
  if (!time) return undefined;
  if (!checkIfTimeIsValid(time)) return undefined;

  let hours = Number(time.match(/^(\d+)/)[1]);
  const minutes = Number(time.match(/:(\d+)/)[1]);
  const AMPM = time.match(/\s(.*)$/)[1];

  if (AMPM.toLowerCase() === 'pm' && hours < 12) hours += 12;
  if (AMPM.toLowerCase() === 'am' && hours === 12) hours -= 12;

  let sHours = hours.toString();
  let sMinutes = minutes.toString();

  if (hours < 10) sHours = `0${sHours}`;
  if (minutes < 10) sMinutes = `0${sMinutes}`;

  return `${sHours}:${sMinutes}`;
};

export const convertTimeTo12HourFormat = (time) => {
  if (!time) return undefined;

  // Check correct time format and split into components
  // eslint-disable-next-line no-param-reassign
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    // eslint-disable-next-line no-param-reassign
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
    if (time[0] < 10) {
      time[0] = `0${time[0]}`;
    }
  }
  return time.join(''); // return adjusted time or original string
};

export const convert24HourTimeToTimestamp = (time) => {
  const today = new Date();
  const timeSplit = time.split(':');

  today.setHours(timeSplit[0], timeSplit[1], 0, 0);

  return today;
};

export const convertTimestampToTimeWithSuffix = (timestamp) => {
  if (!timestamp) return undefined;

  // Timestamp is in seconds, and new Date() only accepts
  // timestamps in milliseconds. So, we multiply 1000 to it.
  const date = new Date(timestamp * 1000);

  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';

  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours < 10 ? '0' : ''}${hours}:${minutes} ${ampm}`;
};

export const getPrevOrAfterDate = (date, value, type) => {
  if (!date || value === undefined || !type) return undefined;

  const d = new Date(date);
  if (type === 'add') {
    d.setDate(d.getDate() + value);
  } else if (type === 'subtract') {
    d.setDate(d.getDate() - value);
  }

  return dateFormat(d, 'yyyy-mm-dd');
};

export const getDifferenceBetweenDates = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const diffTime = Math.abs(d2 - d1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const getFirstDayOfMonth = (date) => {
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), 1);
};

export const getLastDayOfMonth = (date) => {
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth() + 1, 0);
};

export const getMonthFromDate = (date) => {
  const d = new Date(date);
  return d.getMonth() + 1;
};

export const getYearFromDate = (date) => {
  const d = new Date(date);
  return d.getFullYear();
};

export const getWeeksInMonth = (year, monthNumber, startWeek) => {
  const firstOfMonth = new Date(year, monthNumber - 1, 1);
  const lastOfMonth = new Date(year, monthNumber, 0);

  let firstDay = firstOfMonth.getDay();
  const startDay = getDayIdFromDayName(startWeek);

  if (firstDay === 0 && startDay === 1) {
    firstDay += 6;
  } else if (firstDay === 1 || startDay === 1) {
    firstDay -= 1;
  } else if (startDay > 1) {
    firstDay = (firstDay - startDay + 7) % 7;
  }

  const used = firstDay + lastOfMonth.getDate();

  return Math.ceil(used / 7);
};

export const getNumberOfSkipDays = (year, monthNumber, startWeek) => {
  const firstOfMonth = new Date(year, monthNumber - 1, 1);
  const firstDay = firstOfMonth.getDay();
  const startDay = getDayIdFromDayName(startWeek);

  let numberOfSkipDays = 0;
  if (startDay > firstDay) {
    numberOfSkipDays = 7 - Math.abs(firstDay - startDay);
  } else {
    numberOfSkipDays = Math.abs(firstDay - startDay);
  }

  return numberOfSkipDays;
};

export const isMiddleBetweenDates = (startDate, endDate, checkDate) => {
  const d1 = startDate.split('-');
  const d2 = endDate.split('-');
  const c = checkDate.split('-');

  const from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]); // -1 because months are from 0 to 11
  const to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
  const check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

  return check >= from && check <= to;
};

export const dateIsPreviousWeek = (startDateBasis, currentStartDate) =>
  getPrevOrAfterDate(currentStartDate, 7, 'add') === startDateBasis;

export const dateTimeIsPast = (date) => {
  if (!date) return true;

  const today = new Date();
  const check = new Date(date);

  if (check < today) {
    return true;
  }

  return false;
};

export const momentTo24HourTime = (obj) => {
  if (!obj) return undefined;
  const date = moment(obj);
  return `${date.format('HH:mm')}`;
};

export const createMomentWithDummyDate = (timeWithTimezoneString) =>
  moment(`1990-01-01 ${timeWithTimezoneString}`, 'YYYY-MM-DD HH:mm:ss');

export const timeMask = (time) => {
  const startsWithZero = !!(time && time[0] === '0');

  return [
    /[0-1]/,
    startsWithZero ? /[1-9]/ : /[0-2]/,
    ':',
    /[0-5]/,
    /[0-9]/,
    ' ',
    /[a,p]/,
    'm',
  ];
};

export const hoursWithDecimalToHoursAndMinutes = (hoursWithDecimal) => {
  // const roundHoursWithDecimal =
  //   Math.round((Number(hoursWithDecimal) + Number.EPSILON) * 100) / 100;
  
  // const split = `${hoursWithDecimal}`.split('.');
  // console.log(split,'split')
  // const hours = Number(split[0]);
  // let minutes = 0;
  
  // if (split[1]) {
  //   console.log(split[1],'m')
  //   // minutes = Number(Math.ceil((split[1] / 100) * 60));
  //   minutes = Number((split[1]/100) * 60)
  //   console.log(minutes,'new')
  // }
  
  // Separate the whole number and decimal part
  const decimalNumber = hoursWithDecimal;
  
  const hours = Math.floor(decimalNumber);

  const minutes = Math.round((decimalNumber - hours) * 60);


  if (hours === 0 && minutes === 0) {
    return '0';
  }

  return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m` : ''}`;
};

// export const hourToDecimal = (time) =>{
//   var hoursMinutes = time.split(/[.:]/);
//   var hours = parseInt(hoursMinutes[0], 10);
//   var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  
//   return hours + minutes / 60;

// } 
// convert timestring to minutes

export const timeStringToMinutes = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return (hours * 60 + minutes) / 60;
}




