import React, { useState, useContext } from 'react';
import dateFormat from 'dateformat';
import styles from './styles.module.scss';

import { Breadcrumbs, ContractorTimesheetGrid } from '../../../components';
import {
  Button,
  ButtonLink,
  ControlledSelect,
  Text,
} from '../../../components/elements';
import { GenerateContractorTimesheetReportModal } from '../../../components/modals';
import { TimesheetContext, UserContext } from '../../../contexts';
import {
  useTimesheets,
  useWindowSize,
  useGenerateContractorTimesheetReport,
} from '../../../hooks';
import { buttonTypes, timesheetTypes, textTypes } from '../../../globals';
import {
  getPrevOrAfterDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../../utils/datetime';
import { getDateEquivalence, generateDayIds } from '../../../utils/timesheets';

const Timesheets = () => {
  const {
    isGenerating: isGeneratingContractorReport,
    generateContractorReport,
  } = useGenerateContractorTimesheetReport();
  const { user } = useContext(UserContext);
  const [viewType, setViewType] = useState({
    value: timesheetTypes.WEEKLY,
    label: `View by ${timesheetTypes.WEEKLY}`,
  });
  const [startDate, setStartDate] = useState(
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'start')
  );
  const [endDate, setEndDate] = useState(
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'end')
  );
  const [reportType, setReportType] = useState(null);

  const dayIds = generateDayIds(
    viewType.value,
    startDate,
    user.timesheetStartWeek
  );

  const {
    isLoading: isTimesheetsLoading,
    timesheets,
    updateTimesheet,
    deleteTimesheet,
    overrideTimesheets,
  } = useTimesheets({
    userId: user.id,
    startDate,
    endDate,
  });

  const { windowSize } = useWindowSize();

  return (
    <div className={styles.Timesheets}>
      <div className={styles.Timesheets_firstRow}>
        <Breadcrumbs
          pageTitle="Timesheets"
          pages={[
            {
              name: 'Dashboard',
              link: '/contractor/dashboard',
            },
          ]}
        />

        <div className={styles.Timesheets_reportButtons}>
        {/* <Button
            className={styles.Timesheets_reportButtons_button}
            icon="picture_as_pdf"
            type={buttonTypes.PRIMARY.YELLOW}
            onClick={() => setReportType('Send PDF')}
            disabled={isTimesheetsLoading}
          >
            <span className={styles.Timesheets_reportButtons_button_text}>
              Send PDF Report
            </span>
          </Button> */}

          <Button
            className={styles.Timesheets_reportButtons_button}
            icon="send"
            onClick={() => setReportType('Send')}
            disabled={isTimesheetsLoading}
          >
            <span className={styles.Timesheets_reportButtons_button_text}>
              Send Report
            </span>
          </Button>

          <Button
            className={styles.Timesheets_reportButtons_button}
            icon="download"
            type={buttonTypes.PRIMARY.RED}
            // onClick={async () => {
            //   await generateContractorReport({
            //     viewType: viewType.value,
            //     startDate,
            //     endDate,
            //     timesheets,
            //     reportType: 'Download',
            //     hoursAndMinutesFormat: user?.hoursAndMinutesFormat,
            //   });
            // }}
            onClick={() => setReportType('Download')}
            disabled={isTimesheetsLoading || isGeneratingContractorReport}
          >
            <span className={styles.Timesheets_reportButtons_button_text}>
              Download Report
            </span>
          </Button>

          {reportType !== null && (
            <GenerateContractorTimesheetReportModal
              isOpen={reportType !== null}
              handleClose={() => setReportType(null)}
              reportType={reportType}
              viewType={viewType.value}
              startDate={startDate}
              endDate={endDate}
              timesheets={timesheets}
              contractorFullName={user.fullName}
              contractorEmailAddress={user.emailAddress}
              hoursAndMinutesFormat={user?.hoursAndMinutesFormat}
            />
          )}
        </div>
      </div>

      <div className={styles.Timesheets_secondRow}>
        <div className={styles.Timesheets_navigationContainer}>
          <Text type={textTypes.HEADING.XS}>
            {viewType.value !== timesheetTypes.DAILY && (
              <>{dateFormat(new Date(startDate), 'mmm d')} - </>
            )}

            {dateFormat(new Date(endDate), 'mmm d, yyyy')}
          </Text>

          <div className={styles.Timesheets_navigationContainer_navigation}>
            <Button
              className={styles.Timesheets_navigationContainer_navigation_item}
              icon="arrow_back"
              onClick={() => {
                if (viewType.value === timesheetTypes.DAILY) {
                  setStartDate(getPrevOrAfterDate(startDate, 1, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 1, 'subtract'));
                } else if (viewType.value === timesheetTypes.WEEKLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 7, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 7, 'subtract'));
                } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 14, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 14, 'subtract'));
                } else if (viewType.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getPrevOrAfterDate(startDate, 28, 'subtract')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(
                        getPrevOrAfterDate(startDate, 28, 'subtract')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                }
              }}
            >
              <span
                className={
                  styles.Timesheets_navigationContainer_navigation_item_buttonText
                }
              >
                Prev{' '}
                {viewType.value === timesheetTypes.DAILY
                  ? 'Day'
                  : viewType.value.slice(0, -2)}
              </span>
            </Button>

            <ControlledSelect
              className={styles.Timesheets_navigationContainer_navigation_item}
              options={[
                {
                  value: timesheetTypes.DAILY,
                  label: `View by ${timesheetTypes.DAILY}`,
                },
                {
                  value: timesheetTypes.WEEKLY,
                  label: `View by ${timesheetTypes.WEEKLY}`,
                },
                {
                  value: timesheetTypes.FORTNIGHTLY,
                  label: `View by ${timesheetTypes.FORTNIGHTLY}`,
                },
                {
                  value: timesheetTypes.MONTHLY,
                  label: `View by ${timesheetTypes.MONTHLY}`,
                },
              ]}
              name="viewType"
              placeholder="View Type"
              value={viewType}
              onChange={(val) => {
                if (val.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getDateEquivalence(
                          val.value,
                          user.timesheetStartDate,
                          'start'
                        )
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(
                        getDateEquivalence(
                          val.value,
                          user.timesheetStartDate,
                          'start'
                        )
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                } else {
                  setStartDate(
                    getDateEquivalence(
                      val.value,
                      user.timesheetStartDate,
                      'start'
                    )
                  );
                  setEndDate(
                    getDateEquivalence(
                      val.value,
                      user.timesheetStartDate,
                      'end'
                    )
                  );
                }

                setViewType(val);
              }}
            />

            <Button
              className={styles.Timesheets_navigationContainer_navigation_item}
              icon="arrow_forward"
              iconPosition="right"
              onClick={() => {
                if (viewType.value === timesheetTypes.DAILY) {
                  setStartDate(getPrevOrAfterDate(startDate, 1, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 1, 'add'));
                } else if (viewType.value === timesheetTypes.WEEKLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 7, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 7, 'add'));
                } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 14, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 14, 'add'));
                } else if (viewType.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getPrevOrAfterDate(endDate, 28, 'add')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(getPrevOrAfterDate(endDate, 28, 'add')),
                      'yyyy-mm-dd'
                    )
                  );
                }
              }}
            >
              <span
                className={
                  styles.Timesheets_navigationContainer_navigation_item_buttonText
                }
              >
                Next{' '}
                {viewType.value === timesheetTypes.DAILY
                  ? 'Day'
                  : viewType.value.slice(0, -2)}
              </span>
            </Button>
          </div>
        </div>

        <ButtonLink
          to="/contractor/timesheets/create"
          type={buttonTypes.PRIMARY.GREEN}
          icon="add"
        >
          Create Timesheet
        </ButtonLink>
      </div>

      <TimesheetContext.Provider
        value={{
          viewType: viewType.value,
          startDate,
          endDate,
          dayIds,
          isTimesheetsLoading,
          timesheets,
          updateTimesheet,
          deleteTimesheet,
          overrideTimesheets,
          windowSize,
        }}
      >
        <ContractorTimesheetGrid />
      </TimesheetContext.Provider>
    </div>
  );
};

export default Timesheets;
