import React, { useState, useEffect } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';
import styles from './styles.module.scss';

import NoAuthRoute from '../../hocs/NoAuthRoute';

import {
  AccountType,
  AccountDetails,
  InvitationRequest,
  Login,
  DeleteEmployee,
} from '../../screens/public';
import { userTypes } from '../../globals';

/* eslint-disable react/jsx-props-no-spreading */
const LoginSignupContainer = () => {
  const history = useHistory();

  // this state data is to be used for the signup-related screens
  const [userType, setUserType] = useState(null);

  // Redirect user if has selected a userType already
  useEffect(() => {
    if (userType !== null) {
      if (userType === userTypes.EMPLOYEE) {
        history.push('/signup/invitation-request');
      } else {
        history.push('/signup/details');
      }
    }
  }, [userType]);

  return (
    <div className={styles.LoginSignupContainer}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <NoAuthRoute
            path="/login"
            name="Login"
            exact
            render={(props) => <Login {...props} />}
          />

          <NoAuthRoute
            path="/signup"
            name="Sign Up"
            exact
            render={(props) => (
              <AccountType
                userType={userType}
                setUserType={setUserType}
                {...props}
              />
            )}
          />

          <NoAuthRoute
            path="/delete-user/:userToken"
            name="Delete User"
            exact
            render={(props) => (
              <DeleteEmployee {...props} />
            )}
          />

          <NoAuthRoute
            path="/signup/details"
            name="Account Details"
            exact
            render={(props) => (
              <AccountDetails userType={userType} {...props} />
            )}
          />

          <NoAuthRoute
            path="/signup/invitation-request"
            name="Invitation Request"
            exact
            render={(props) => (
              <InvitationRequest userType={userType} {...props} />
            )}
          />

          <Redirect from="*" to="/login" />
        </Switch>
      </React.Suspense>
    </div>
  );
};

export default LoginSignupContainer;
