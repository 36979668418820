import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  Spinner,
} from '../../../components/elements';
import { isValidDecimal } from '../../../utils/string';
import { buttonKinds, colorNames, spinnerSizes } from '../../../globals';
import { useVehicle, useUpdateVehicle } from '../../../hooks';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'This field is required.';
  }

  if (!values.hours) {
    errors.hours = 'This field is required.';
  } else if (!isValidDecimal(values.hours)) {
    errors.hours = 'Only numbers with a maximum of 2 decimals are allowed.';
  }

  return errors;
};

const UpdateVehicle = () => {
  const alert = useAlert();
  const { vehicleId } = useParams();

  const { isLoading: isVehicleLoading, vehicle } = useVehicle({
    vehicleId,
  });

  const { isUpdating: isVehicleUpdating, updateVehicle } = useUpdateVehicle();

  return (
    <div className={styles.UpdateVehicle}>
      <Breadcrumbs
        pageTitle="Update Vehicle"
        pages={[
          {
            name: 'Dashboard',
            link: '/employer/dashboard',
          },
          {
            name: 'Vehicles',
            link: '/employer/vehicles',
          },
        ]}
      />

      {isVehicleLoading ? (
        <Preloader />
      ) : (
        <Card className={styles.UpdateVehicle_card}>
          <Formik
            initialValues={vehicle}
            onSubmit={async (values, { setErrors }) => {
              const errors = validate(values);
              if (!isEmpty(errors)) {
                setErrors(errors);
                return;
              }

              const newVehicle = {
                ...values,
              };

              const { responseCode: updateVehicleResponseCode } =
                await updateVehicle(vehicleId, newVehicle);

              const updateVehicleCallbacks = {
                updated: () => {
                  alert.success('Vehicle updated!');
                },
                invalidFields: () => alert.error('Invalid fields.'),
                internalError: () => alert.error('Oops, something went wrong.'),
              };

              switch (updateVehicleResponseCode) {
                case 200:
                  updateVehicleCallbacks.updated();
                  break;
                case 400:
                  updateVehicleCallbacks.invalidFields();
                  break;
                case 500:
                  updateVehicleCallbacks.internalError();
                  break;
                default:
                  break;
              }
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <ControlledInput
                  name="name"
                  placeholder="Name*"
                  icon="person"
                  value={values.name}
                  error={errors.name}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />

                <ControlledInput
                  className={styles.UpdateVehicle_withMargin}
                  name="hours"
                  placeholder="Hours*"
                  icon="schedule"
                  value={values.hours}
                  error={errors.hours}
                  onChange={(e) => setFieldValue('hours', e.target.value)}
                />

                <ControlledInput
                  className={styles.UpdateVehicle_withMargin}
                  name="rego"
                  placeholder="WOF/Rego"
                  icon="badge"
                  value={values.rego}
                  error={errors.rego}
                  onChange={(e) => setFieldValue('rego', e.target.value)}
                />

                <div className={styles.UpdateVehicle_buttonGroup}>
                  <Button
                    className={styles.UpdateVehicle_buttonGroup_button}
                    kind={buttonKinds.SUBMIT}
                    icon="add"
                    disabled={isVehicleUpdating}
                    onClick={() => {}}
                  >
                    <span
                      className={styles.UpdateVehicle_buttonGroup_buttonText}
                    >
                      Update Vehicle
                      {isVehicleUpdating && (
                        <Spinner
                          size={spinnerSizes.XS}
                          colorName={colorNames.WHITE}
                          className={styles.UpdateVehicle_buttonGroup_spinner}
                        />
                      )}
                    </span>
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      )}
    </div>
  );
};

export default UpdateVehicle;
