import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { Button, IconButton } from '../elements';
import { colorNames } from '../../globals';

const GoogleMapMarker = ({ children, isOpen, close, onClick, markerColor }) => (
  <div className={styles.GoogleMapMarker}>
    {isOpen && (
      <div className={styles.GoogleMapMarker_card}>
        <IconButton
          className={styles.GoogleMapMarker_card_closeButton}
          icon="close"
          onClick={close}
        />
        {children}
      </div>
    )}

    <Button
      className={cn(
        styles.GoogleMapMarker_pin,
        styles[`GoogleMapMarker_pin___${markerColor}`]
      )}
      onClick={onClick}
    />
  </div>
);

GoogleMapMarker.defaultProps = {
  isOpen: false,
  markerColor: colorNames.VIOLET,
};

GoogleMapMarker.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  markerColor: PropTypes.oneOf([
    colorNames.VIOLET,
    colorNames.GREEN,
    colorNames.RED,
  ]),
};

export default GoogleMapMarker;
