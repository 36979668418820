import { useState } from 'react';

import { VehiclesService } from '../services';

const useCreateVehicle = () => {
  const [isCreating, setIsCreating] = useState(false);

  const createVehicle = async (vehicle) => {
    setIsCreating(true);

    let responseCode;

    try {
      const response = await VehiclesService.create({
        body: { ...vehicle },
      });
      responseCode = response.status;
    } catch (error) {
      responseCode = error.response.status;
    }

    setIsCreating(false);

    return { responseCode };
  };

  return { isCreating, createVehicle };
};

export default useCreateVehicle;
