import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import EasyEdit from 'react-easy-edit';
import styles from '../styles.module.scss';

import { Icon, Text } from '../../elements';

const EditableSelect = ({ options, initialValue, onSave }) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  return (
    <EasyEdit
      type="select"
      options={options}
      saveButtonLabel={<Icon icon="check" />}
      cancelButtonLabel={<Icon icon="close" />}
      saveButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_save
      )}
      cancelButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_cancel
      )}
      displayComponent={
        <Text className={styles.Editable_displayValue}>{selectedValue}</Text>
      }
      value={selectedValue}
      cssClassPrefix="styling"
      onSave={(val) => {
        setSelectedValue(val);
        onSave(val);
      }}
    />
  );
};

EditableSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditableSelect;
