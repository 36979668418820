import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';
import dateFormat from 'dateformat';
import {
  ControlledSelect,
  Checkbox,
  Modal,
  DatePicker,
} from '../../elements';
import {
  buttonTypes,
  inputTypes,
  selectTypes,
} from '../../../globals';
import { useClients, useProjects, useTimesheets } from '../../../hooks';
import { useContext } from 'react';
import { UserContext } from '../../../contexts';
import useGenerateClientTimesheetReport from '../../../hooks/useGenerateClientTimesheetReport';
import {ProjectsService} from "../../../services";

const validate = (values) => {
  const errors = {};

  if (!values.includeAllClient) {
    if (!values.selectedClients.length) {
      errors.selectedClients = 'This field is required.';
    }
  }
  if (!values.includeAllProjects && values.selectedClients.length == 1) {
    if (!values.selectedProjects.length) {
      errors.selectedProjects = 'This field is required.';
    }
  }
  if (!values.from) {
    errors.from = 'This field is required.';
  }
  if (!values.to) {
    errors.to = 'This field is required.';
  }
  if (
    values.from &&
    values.to &&
    new Date(values.to).getTime() < new Date(values.from).getTime()
  ) {
    errors.to = 'To date must be after From date.';
  }

  return errors;
};

const GenerateClientReportModal = ({
  isOpen,
  handleClose,
  employees,
  reportType,
  hoursAndMinutesFormat,
}) => {
  isOpen = false;

  const formRef = useRef();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [clientValue, setClientValue] = useState([]);
  const [projectValue, setProjectValue] = useState([]);

  const { isGenerating: isGeneratingReport, generateClientReport } =
    useGenerateClientTimesheetReport();
  const { user } = useContext(UserContext);
  
  const { clients } = useClients({
    userId: user.id,
  });

  let allClient = clients.map((client) => ({
    value: client.id,
    label: client.name,
  }))
  allClient.sort((a, b) => a.label.localeCompare(b.label));

  if(allClient.length > 0){
    isOpen = true;
  }

  const [clientProjects,setClientProject] = useState([]);

  const fetchClientProject = async (value) => {
    if (value.length === 1) {
      const [clientDetails] = value;

      const { data: getProjectsResponse } = await ProjectsService.get({
        clientId: clientDetails.value,
        search: ""
      });
      if (getProjectsResponse) {
        let tempProjects = getProjectsResponse.projects.map((project) => ({
          value: project.id,
          label: project.name,
        }))
        setClientProject(tempProjects);
      }
    }
  }
  // code for add client id on the api call
  const [apiClientId,setApiClientId] = useState([]);
  useEffect(() => {
    let tempClientIds = [];
    let clientIds = clientValue.length === 0 ? allClient : clientValue;

    if (clientIds.length > 0){} {
      clientIds.map((item) => {
        tempClientIds.push(item.value);
      })
    }
    setApiClientId(tempClientIds);
  },[clientValue,isOpen]);

  // code for add project id on the api call
  const [apiProjectId,setApiProjectId] = useState([]);
  useEffect(() => {
    let tempProjectIds = [];
    if(projectValue.length > 0){
      projectValue.map((item) => {
        tempProjectIds.push(item.value);
      });
      setApiProjectId(tempProjectIds);
    }
  }, [projectValue]);

  const { timesheets, isLoading } = useTimesheets({
    adminId: user.id,
    startDate: fromDate,
    endDate: toDate,
    clientId: apiClientId,
    projectId: apiProjectId,
  })

  const { projects } = useProjects({
    clientId: clientValue,
  })

  let allProject = projects.map((project) => ({
    value: project.id,
    label: project.name,
  }))

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={`${reportType} Report`}
      actions={[
        {
          text: isLoading ? 'loading...' : reportType || '',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isGeneratingReport || isLoading,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          includeAllClient: true,
          includeAllProjects: false,
          selectedClients: allClient,
          selectedProjects: [],
        }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          const {
            selectedClients,
            selectedProjects,
            from,
            to,
          } = values;
          const { message } = await generateClientReport({
            from,
            to,
            selectedClients,
            selectedProjects,
            employees,
            timesheets,
            reportType,
            hoursAndMinutesFormat,
            emailAddress: user.emailAddress,
          });

          if (message === 'generate_report_successful') {
            handleClose();
          }else{
            handleClose();
          }
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <>
            
            <ControlledSelect
              type={selectTypes.SLIM}
              label="Select Client to Include:"
              options={allClient}
              name="selectedClients"
              isMulti
              value={values.selectedClients}
              onChange={(val) => {
                setFieldValue('selectedClients', val);
                setClientValue(val.length > 0 ? val : [""]);
                setFieldValue('selectedProjects', []);
                setFieldValue('includeAllProjects', false);
                fetchClientProject(val);
              }}
              error={errors.selectedClients}
              disabled={values.includeAllClient}
            />

            <Checkbox
              className={styles.GenerateTimesheetReportModal_withMarginTop}
              name="includeAllClient"
              label="Select all Client"
              value={values.includeAllClient}
              onChange={(e) => {
                setFieldValue('includeAllClient', !values.includeAllClient);
                setFieldValue('selectedClients', allClient);
                setFieldValue('selectedProjects', []);

                if(e.target.checked){
                  setFieldValue('selectedClients', allClient);
                } else {
                  setFieldValue('selectedClients', []);
                }
              }}
              checked={values.includeAllClient}
            />
            {(values.selectedClients.length === 1 && values.selectedClients.length !== 0) &&
              <>
                <ControlledSelect
                  type={selectTypes.SLIM}
                  label="Select Project to Include:"
                  options={clientProjects}
                  isMulti
                  name="selectedProjects"
                  value={values.selectedClients.length > 1 ? [] : values.selectedProjects}
                  onChange={(val) =>{
                    setFieldValue('selectedProjects', val)
                    setProjectValue(val);
                  }}
                  error={errors.selectedProjects}
                  disabled={values.includeAllProjects}
                />

                <Checkbox
                  className={styles.GenerateTimesheetReportModal_withMarginTop}
                  name="includeAllProjects"
                  label="Select all Projects"
                  onChange={(e) => {
                    setFieldValue('includeAllProjects', e.target.checked);

                    if (!e.target.checked) {
                      setFieldValue('selectedProjects', []);
                    } else {
                      setFieldValue('selectedProjects', values.selectedClients.length > 1 ? [] : clientProjects);
                      setProjectValue(clientProjects);
                    }
                  }}
                  checked={values.includeAllProjects}
                />
              </>
            }
            <DatePicker
              className={styles.GenerateTimesheetReportModal_withMarginTop}
              type={inputTypes.SLIM}
              label="From*"
              dateFormat="dd/MM/yyyy"
              selected={values.from}
              name="from"
              icon="today"
              error={errors.from}
              onChange={(date) => {
                setFieldValue('from', date);
                setFromDate(dateFormat(new Date(date), 'yyyy-mm-dd'),);
              }}
              disableFutureDates
            />

            <DatePicker
              className={styles.GenerateTimesheetReportModal_withMarginTop}
              type={inputTypes.SLIM}
              label="To*"
              dateFormat="dd/MM/yyyy"
              selected={values.to}
              name="to"
              icon="today"
              error={errors.to}
              onChange={(date) => {
                setFieldValue('to', date);
                setToDate(dateFormat(new Date(date), 'yyyy-mm-dd'),);
              }}
              minDate={values.from}
              disableFutureDates
            />
          </>
        )}
      </Formik>
    </Modal>
  );
};

GenerateClientReportModal.defaultProps = {
};

GenerateClientReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reportType: PropTypes.oneOf(['Download', 'Send']).isRequired,
};

export default GenerateClientReportModal;
