import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';
import dateFormat from 'dateformat';
import {
  ControlledInput,
  ControlledSelect,
  Checkbox,
  Modal,
  DatePicker,
  Text,
} from '../../elements';
import {
  buttonTypes,
  inputTypes,
  timesheetTypes,
  selectTypes,
} from '../../../globals';
import { useGenerateTimesheetReport, useTimesheets } from '../../../hooks';
import { isValidEmail } from '../../../utils/string';
import { getHours, checkStatus } from '../../../utils/timesheets';
import pdfHeader from '../../../static/images/PDF/HEADER.png';
import pdfFooter from '../../../static/images/PDF/FOOTER.png';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { convertTimeTo24HourFormat, convertDateTimeToTimestamp } from "../../../utils/datetime";
import config from "../../../services/config";
import axios from "axios";
import { useAlert } from "react-alert";

const validate = (values) => {
  const errors = {};

  if (values.reportType === 'Send') {
    if (!values.emailAddress) {
      errors.emailAddress = 'This field is required.';
    } else if (!isValidEmail(values.emailAddress)) {
      errors.emailAddress = 'This must be a valid email address.';
    }
  }

  if (!values.isAllEmployees) {
    if (!values.selectedEmployees.length) {
      errors.selectedEmployees = 'This field is required.';
    }
  }

  if (
    values.from &&
    values.to &&
    new Date(values.to).getTime() < new Date(values.from).getTime()
  ) {
    errors.to = 'To date must be after From date.';
  }

  return errors;
};

const GenerateTimesheetReportModal = ({
  isOpen,
  handleClose,
  reportType,
  viewType,
  startDate,
  endDate,
  employees,
  timesheets,
  employerFullName,
  employerEmailAddress,
  isProject,
  clientId = null,
  projectId,
  hoursAndMinutesFormat,
  employerId,

}) => {

  const { isGenerating: isGeneratingReport, generateReport } =
    useGenerateTimesheetReport();
  const alert = useAlert();
  const formRef = useRef();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportingColumns, setReportingColumns] = useState([]);

  if (fromDate != '' && toDate != '') {
    startDate = fromDate;
    endDate = toDate;
  }

  const { timesheets: allTimesheets, isLoading: isTimesheetsLoading, } = useTimesheets({
    employerId: employerId,
    projectId: projectId,
    startDate: startDate,
    endDate: endDate,
    clientId: clientId,
  });

  if (allTimesheets.length >= 0) {
    timesheets = allTimesheets;
  }

  useEffect(async () => {
    const BASE_URL = `${config.API_URL}/users/getEmployerDetails`;
    const data = {
      emailAddress: employerEmailAddress,
    };
    const response = await axios.post(`${BASE_URL}`, data).then((res) => {
      return res.data;
    }).catch((err) => {
      console.log(err);
    });

    if (response.employees !== undefined) {
      setReportingColumns(response.employees.reportingColumns);
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={`${reportType} Report`}
      actions={[
        {
          text: isTimesheetsLoading ? 'Loading...' : reportType || '',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isGeneratingReport || isTimesheetsLoading,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          reportType,
          emailAddress: '',
          isAllEmployees: true,
          selectedEmployees: [],
          projectType: isProject
            ? {
              label: 'Complete',
              value: 'Complete',
            }
            : null,
          downloadType: {
            label: 'Excel',
            value: 'excel',
          }
        }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          const {
            emailAddress,
            isAllEmployees,
            selectedEmployees,
            projectType,
          } = values;

          if (values.downloadType.value === "pdf") {
            if (reportType === "Download") {

              const columns = [
                { header: 'Date', dataKey: 'date' },
                { header: 'Employee', dataKey: 'employeeName' },
                { header: 'Client', dataKey: 'clientName' },
                { header: 'Project', dataKey: 'projectName' },
                { header: 'Start', dataKey: 'startTime' },
                { header: 'Actual Start', dataKey: 'actualStartTime' },
                { header: 'End', dataKey: 'endTime' },
                { header: 'Actual End', dataKey: 'actualEndTime' },
                { header: 'Lunch', dataKey: 'isLunchBreak' },
                { header: 'Leave', dataKey: 'leaveType' },
                { header: 'Hours', dataKey: 'hours' },
                { header: 'Wage', dataKey: 'wage' },
                { header: 'Income', dataKey: 'income' },
                { header: 'Notes', dataKey: 'notes' },
                // { header: 'Cost', dataKey: 'cost' },
                { header: 'Total Coast', dataKey: 'totalCost' },
              ];
              let finalColumns = [];

              if (reportingColumns?.length > 0) {
                for (let i = 0; i < reportingColumns.length; i += 1) {
                  const columnName = reportingColumns[i];
                  const findColumn = columns.find((item) => item.dataKey === columnName);
                  if (findColumn !== undefined) {
                    finalColumns.push(findColumn);
                  }
                }
              } else {
                finalColumns = columns;
              }
              const doc = new jsPDF("p", "mm", "a3");
              doc.addImage(pdfHeader, 'PNG', 0, 0, 300, 0);
              doc.setFontSize(18)
              doc.setFont('Lato', 'bold')
              doc.text("Timesheet Report ", 13, 50);
              doc.setFontSize(18)
              doc.setFont('Lato', 'bold')
              // doc.setTextColor('#55595C')
              doc.text(`${moment(startDate).format('MMM DD,Y')} - ${moment(endDate).format('MMM DD,Y')}`, 65, 50);

              let timesheetData = [];
              let filterTimesheetData = []

              if (!isAllEmployees) {
                selectedEmployees.forEach(({ value: employeeId }) => {
                  const filteredTimesheet = timesheets.filter((ele) => {
                    return ele.userId === employeeId;
                  })
                  filterTimesheetData.push(...filteredTimesheet);
                })
              } else {
                filterTimesheetData = timesheets
              }

              for (let i = 0; i < filterTimesheetData.length; i += 1) {
                let timesheet = filterTimesheetData[i];
                const startTimeTimestamp = convertDateTimeToTimestamp(timesheet.date, convertTimeTo24HourFormat(timesheet.startTimeDetails.startTime));
                const endTimeTimestamp = convertDateTimeToTimestamp(timesheet.date, convertTimeTo24HourFormat(timesheet.endTimeDetails.endTime));
                const hours = getHours(
                  startTimeTimestamp,
                  endTimeTimestamp,
                  timesheet.isLunchBreak,
                  timesheet.lunchBreakDuration,
                  timesheet.isTravelCharge,
                  timesheet.leaveType,
                  timesheet.publicHolidayMultiplier,
                );
                const totalCost = timesheet.costs
                  ? Number(timesheet.costs?.reduce((a, b) => a + Number(b.price), 0))
                  : 0;
                const incomeEarned = Number(timesheet.wage) * hours;
                const travelCharge = timesheet.isTravelCharge ? "Yes" : "No";
                const isLunchBreak = timesheet.isLunchBreak ? 'Yes' : 'No';
                const actualStart = timesheet.startTimeDetails.actualTime;
                const actualEnd = timesheet.endTimeDetails.actualTime;

                timesheetData.push({
                  date: timesheet.date,
                  employeeName: timesheet.employeeName,
                  clientName: timesheet.clientName,
                  projectName: timesheet.projectName,
                  startTime: timesheet.startTimeDetails.startTime,
                  endTime: timesheet.endTimeDetails.endTime,
                  actualStartTime: actualStart,
                  actualEndTime: actualEnd,
                  isLunchBreak: isLunchBreak,
                  leaveType: timesheet.leaveType,
                  hours: hours,
                  wage: timesheet.wage,
                  income: incomeEarned,
                  notes: timesheet.notes,
                  totalCost: `$${totalCost}`,
                });
              }

              doc.autoTable({
                headStyles: { fillColor: "#967ADC", minCellHeight: 14, halign: 'left', fontStyle: 'lighter', cellPadding: { top: 4, right: 3, left: 1 } },
                margin: { bottom: 60 },
                startY: 63,
                styles: { cellPadding: { top: 2, right: 1, left: 1, bottom: 1 }, minCellHeight: 14, fontStyle: 'lighter' },
                columnStyles: { text: { cellWidth: 10 } },
                columns: finalColumns.map(e => ({ ...e, dataKey: e.dataKey })),
                body: timesheetData
              })
              doc.addImage(pdfFooter, 'PNG', 0, 350, 300, 0)
              doc.save('report.pdf')
              handleClose();

            } else {
              const BASE_URL = `${config.API_URL}/timesheets/send-timesheet-in-pdf`;
              const data = {
                viewType,
                startDate,
                endDate,
                emailAddress,
                isAllEmployees,
                selectedEmployees,
                employees,
                timesheets,
                employerFullName,
                employerEmailAddress,
                reportType,
                isProject,
                projectType: projectType?.value,
                hoursAndMinutesFormat,
              };
              console.log(data,'data')
              const response = await axios.post(`${BASE_URL}`, data).then((res) => {
                return res.data;
              }).catch((err) => {
                console.log(err);
              });

              if (response.success) {
                console.log(response, 'response');
                console.log(response.success, 'response');
                alert.success('Timesheet report sent successfully!');
                handleClose();
              }

            }

          } else {
            const { message } = await generateReport({
              viewType,
              startDate,
              endDate,
              emailAddress,
              isAllEmployees,
              selectedEmployees,
              employees,
              timesheets,
              employerFullName,
              employerEmailAddress,
              reportType,
              isProject,
              projectType: projectType?.value,
              hoursAndMinutesFormat,
            });
            if (message === 'generate_report_successful') {
              handleClose();
            }
          }

        }}
      >
        {({ errors, values, setFieldValue }) => (
          <>
            {reportType === 'Send' && (
              <ControlledInput
                className={styles.GenerateTimesheetReportModal_withMarginBottom}
                type={inputTypes.SLIM}
                label="Email Address"
                name="emailAddress"
                icon="email"
                value={values.emailAddress}
                error={errors.emailAddress}
                onChange={(e) => setFieldValue('emailAddress', e.target.value)}
              />
            )}

            {isProject && (
              <ControlledSelect
                className={styles.GenerateTimesheetReportModal_withMarginBottom}
                type={selectTypes.SLIM}
                label="Type"
                options={[
                  {
                    label: 'Complete',
                    value: 'Complete',
                  },
                  {
                    label: 'Only Billable',
                    value: 'Billable',
                  },
                  {
                    label: 'Only Non-billable',
                    value: 'Non-billable',
                  },
                ]}
                name="type"
                value={values.projectType}
                onChange={(val) => setFieldValue('projectType', val)}
              />
            )}

            <ControlledSelect
              type={selectTypes.SLIM}
              label="Select Employees to Include:"
              options={employees.map((employee) => ({
                value: employee.id,
                label: employee.fullName,
              }))}
              name="selectedEmployees"
              isMulti
              value={values.selectedEmployees}
              onChange={(val) => setFieldValue('selectedEmployees', val)}
              error={errors.selectedEmployees}
              disabled={values.isAllEmployees}
            />

            <Checkbox
              className={styles.GenerateTimesheetReportModal_withMarginTop}
              name="includeAllEmployees"
              label="Select all Employees"
              onChange={() => {
                setFieldValue('isAllEmployees', !values.isAllEmployees);
                setFieldValue('selectedEmployees', []);
              }}
              checked={values.isAllEmployees}
            />
            <Text className={styles.GenerateTimesheetReportModal_withMarginTop}>*If you leave the dates blank it will download time timesheets according to your current view</Text>
            <DatePicker
              className={styles.GenerateTimesheetReportModal_withMarginTop}
              type={inputTypes.SLIM}
              label="From*"
              dateFormat="dd/MM/yyyy"
              selected={values.from}
              name="from"
              icon="today"
              error={errors.from}
              onChange={(date) => {
                setFieldValue('from', date);
                setFromDate(dateFormat(new Date(date), 'yyyy-mm-dd'),);
              }}
              disableFutureDates
            />

            <DatePicker
              className={styles.GenerateTimesheetReportModal_withMarginTop}
              type={inputTypes.SLIM}
              label="To*"
              dateFormat="dd/MM/yyyy"
              selected={values.to}
              name="to"
              icon="today"
              error={errors.to}
              onChange={(date) => {
                setFieldValue('to', date);
                setToDate(dateFormat(new Date(date), 'yyyy-mm-dd'),);
              }}
              minDate={values.from}
              disableFutureDates
            />
            <div style={{ marginTop: "20px" }}></div>
            <ControlledSelect
              type={selectTypes.SLIM}
              label={reportType === 'Send' ? "Select Send Type" : "Select Download Type"}
              options={[
                {
                  value: "excel",
                  label: "Excel",
                },
                {
                  value: "pdf",
                  label: "PDF",
                }
              ]}
              name="selectedDownloadType"
              value={values.downloadType}
              onChange={(val) => setFieldValue('downloadType', val)}
              error={errors.downloadType}
            />
          </>
        )}
      </Formik>
    </Modal>
  );
};

GenerateTimesheetReportModal.defaultProps = {
  isProject: false,
};

GenerateTimesheetReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reportType: PropTypes.oneOf(['Download', 'Send']).isRequired,
  viewType: PropTypes.oneOf(Object.values(timesheetTypes)).isRequired,
  startDate: PropTypes.string.isRequired, // YYYY-MM-DD
  endDate: PropTypes.string.isRequired, // YYYY-MM-DD
  employees: PropTypes.array.isRequired,
  timesheets: PropTypes.array.isRequired,
  employerFullName: PropTypes.string.isRequired,
  employerEmailAddress: PropTypes.string.isRequired,
  isProject: PropTypes.bool,
  hoursAndMinutesFormat: PropTypes.bool,
  employerId: PropTypes.string,
};

export default GenerateTimesheetReportModal;
