import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import dateFormat from 'dateformat';
import FileDownload from 'js-file-download';
import styles from './styles.module.scss';

import {
  Button,
  Card,
  ControlledSelect,
  DatePicker,
} from '../../../components/elements';
import { DatabaseExportService } from '../../../services';

const DatabaseExport = () => {
  const alert = useAlert();
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: 'Clients',
    value: 'clients',
  });
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className={styles.DatabaseExport}>
      <Card className={styles.DatabaseExport_card}>
        <ControlledSelect
          className={styles.DatabaseExport_select}
          options={[
            {
              label: 'Clients',
              value: 'clients',
            },
            {
              label: 'Confirmation Codes',
              value: 'confirmationCodes',
            },
            {
              label: 'Roles',
              value: 'roles',
            },
            {
              label: 'Rosters',
              value: 'rosters',
            },
            {
              label: 'Timesheets',
              value: 'timesheets',
            },
            {
              label: 'Users',
              value: 'users',
            },
          ]}
          name="databaseTable"
          placeholder="Database Table*"
          value={selectedOption}
          onChange={(val) => setSelectedOption(val)}
        />

        {selectedOption?.value !== 'roles' && (
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            placeholder="Start Date*"
            name="date"
            icon="today"
            onChange={(date) => {
              setStartDate(date);
            }}
          />
        )}

        <Button
          icon="download"
          onClick={async () => {
            setIsDownloading(true);

            const type = selectedOption.value;
            let fileName = '';
            if (type === 'clients') {
              fileName = 'Clients Collection';
            } else if (type === 'confirmationCodes') {
              fileName = 'Confirmation Codes Collection';
            } else if (type === 'roles') {
              fileName = 'Roles Collection';
            } else if (type === 'rosters') {
              fileName = 'Rosters Collection';
            } else if (type === 'timesheets') {
              fileName = 'Timesheets Collection';
            } else if (type === 'users') {
              fileName = 'Users Collection';
            }

            const { data: downloadResponse } =
              await DatabaseExportService.download({
                type,
                startDate:
                  selectedOption.value !== 'roles'
                    ? dateFormat(startDate, 'yyyy-mm-dd')
                    : undefined,
              });

            // Download the file if reportType is 'Download'
            FileDownload(downloadResponse, `${fileName}.xlsx`);
            alert.success(`${selectedOption.label} Collection Downloaded!`);

            setIsDownloading(false);
          }}
          disabled={isDownloading}
        >
          Export Collection as Excel file
        </Button>
      </Card>
    </div>
  );
};

export default DatabaseExport;
