import { createContext } from 'react';

const TimesheetContext = createContext({
  viewType: null,
  startDate: null,
  endDate: null,
  dayIds: [],
  isEmployeesLoading: false,
  employees: [],
  isRostersLoading: false,
  rosters: [],
  isTimesheetsLoading: false,
  timesheets: [],
  updateTimesheet: () => {},
  deleteTimesheet: () => {},
  overrideTimesheets: () => {},
  windowSize: { width: 0, height: 0 },
});

export default TimesheetContext;
