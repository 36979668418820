import { useState, useEffect } from 'react';

import { UsersService } from '../services';

const useUsers = ({
  adminId,
  isEmployersIncluded = false,
  isEmployeesIncluded = false,
  isContractorsIncluded = false,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const { data: getUsersResponse } = await UsersService.getUsers({
        adminId,
        isEmployersIncluded,
        isEmployeesIncluded,
        isContractorsIncluded,
      });

      if (getUsersResponse.users) {
        setUsers(getUsersResponse.users);
      }

      setIsLoading(false);
    };

    getUsers();
  }, []);

  return {
    isLoading,
    users,
  };
};

export default useUsers;
