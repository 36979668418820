import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/database-export`;

const DatabaseExportService = {
  download: (body) =>
    axios.post(`${BASE_URL}/download`, body, {
      responseType: 'blob',
    }),
};

export default DatabaseExportService;
