import { useState, useContext } from 'react';
import { useAlert } from 'react-alert';

import { UserContext } from '../contexts';
import { UsersService } from '../services';

const useAddEmployee = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const [isCreating, setIsCreating] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const employerDetails = {
    employerId: user.id,
    employerFullName: user.fullName,
    employerEmailAddress: user.emailAddress,
    phoneNumber: user.phoneNumber,
    companyName: user.companyName,
    timesheetStartDate: user.timesheetStartDate,
    timesheetStartWeek: user.timesheetStartWeek,
    isLunchBreakByDefault: user.isLunchBreakByDefault,
    isTravelChargeByDefault: user?.isTravelChargeByDefault || false,
    notifications: user.notifications,
    showVehiclesTab: user?.showVehiclesTab || false,
    androidPushToken: user.androidPushToken || null,
    iosPushToken: user.iosPushToken || null,
  };

  const createEmployee = async (fullName, emailAddress) => {
    setIsCreating(true);
    
    const { data: createEmployeeResponse } = await UsersService.createEmployee({
      fullName,
      emailAddress,
      ...employerDetails,
    });

    setIsCreating(false);

    let message = null;

    if (createEmployeeResponse.message === 'employee_created') {
      // Update user data in cookies
      loginUpdate({
        ...user,
        employees: [...user.employees, createEmployeeResponse.employee],
      });

      alert.success('Employee created!');
      message = 'employee_created';
    } else {
      alert.error('Oops, something went wrong.');
      message = 'employee_not_created';
    }

    return { message };
  };

  const importEmployee = async (employeeCode) => {
    setIsImporting(true);

    const { data: importEmployeeResponse } = await UsersService.importEmployee({
      employeeCode,
      ...employerDetails,
    });

    setIsImporting(false);

    let message = null;

    if (importEmployeeResponse.message === 'employee_imported') {
      // Update user data in cookies
      loginUpdate({
        ...user,
        employees: [...importEmployeeResponse.employees],
      });

      alert.success('Employee imported!');
      message = 'employee_imported';
    } else {
      alert.error('Employee Code is invalid.');
      message = 'employee_not_imported';
    }

    return { message };
  };

  return { isCreating, createEmployee, isImporting, importEmployee };
};

export default useAddEmployee;
