import React, { useState, useContext, useEffect } from 'react';
import dateFormat from 'dateformat';
import cn from 'classnames';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Breadcrumbs, SweetAlert } from '../../../components';
import {
  Button,
  ButtonLink,
  ControlledInput,
  IconButton,
  IconLink,
  Text,
} from '../../../components/elements';
import { GenerateVehiclesReportModal } from '../../../components/modals';
import { buttonTypes, colorClasses, textTypes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { useVehicles, useWindowSize } from '../../../hooks';
import NoResults from '../../../static/images/Misc/no-results.webp';

const Vehicles = () => {
  const alert = useAlert();
  const { user } = useContext(UserContext);
  const [vehicles, setVehicles] = useState([]);
  const [generateType, setGenerateType] = useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [search, setSearch] = useState('');

  const {
    isLoading: isVehiclesLoading,
    vehicles: retrievedVehicles,
    deleteVehicle,
  } = useVehicles({
    employerId: user.id,
  });

  const { windowSize } = useWindowSize();

  // Setting vehicles considering the search functionality
  useEffect(() => {
    if (!isVehiclesLoading) {
      if (!search) {
        setVehicles(retrievedVehicles);
      } else {
        // Search functionality
        setVehicles(
          retrievedVehicles.filter((vehicle) =>
            vehicle.name
              .toLowerCase()
              .match(new RegExp(search.toLowerCase(), 'g'))
          )
        );
      }
    }
  }, [isVehiclesLoading, search]);

  // Setting vehicles considering a delete action
  useEffect(() => {
    if (!isVehiclesLoading) {
      setVehicles(retrievedVehicles);
    }
  }, [retrievedVehicles]);

  return (
    <div className={styles.Vehicles}>
      <div className={styles.Vehicles_firstRow}>
        <Breadcrumbs
          pageTitle="Vehicles"
          pages={[
            {
              name: 'Dashboard',
              link: `/employer/dashboard`,
            },
          ]}
        />

        <div className={styles.Vehicles_reportButtons}>
          <ButtonLink
            className={styles.Vehicles_reportButtons_button}
            to="/employer/vehicles/create"
            type={buttonTypes.PRIMARY.GREEN}
            icon="add"
            disabled={isVehiclesLoading}
          >
            Create Vehicle
          </ButtonLink>

          <Button
            className={styles.Vehicles_reportButtons_button}
            icon="send"
            onClick={() => setGenerateType('Send')}
            disabled={isVehiclesLoading}
          >
            <span className={styles.Vehicles_reportButtons_button_text}>
              Send Report
            </span>
          </Button>

          <Button
            className={styles.Vehicles_reportButtons_button}
            icon="download"
            type={buttonTypes.PRIMARY.RED}
            onClick={() => setGenerateType('Download')}
            disabled={isVehiclesLoading}
          >
            <span className={styles.Vehicles_reportButtons_button_text}>
              Download Report
            </span>
          </Button>
        </div>

        {generateType !== null && (
          <GenerateVehiclesReportModal
            isOpen={generateType !== null}
            handleClose={() => setGenerateType(null)}
            employerId={user.id}
            employerFullName={user.fullName}
            employerEmailAddress={user.emailAddress}
            generateType={generateType}
            reportType="Vehicles"
          />
        )}
      </div>

      {isVehiclesLoading ? (
        <Preloader />
      ) : (
        <>
          {retrievedVehicles.length ? (
            <>
              <div className={styles.Vehicles_search}>
                <ControlledInput
                  className={styles.Vehicles_search_input}
                  placeholder="Search for a Vehicle"
                  name="search"
                  icon="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <div className={styles.Vehicles_grid}>
                {/* Header of VehiclesGrid starts here */}
                <div
                  className={cn(
                    styles.Vehicles_grid_column,
                    styles.Vehicles_grid_header
                  )}
                >
                  Date Created
                </div>
                <div
                  className={cn(
                    styles.Vehicles_grid_column,
                    styles.Vehicles_grid_header
                  )}
                >
                  Vehicle Name
                </div>
                <div
                  className={cn(
                    styles.Vehicles_grid_column,
                    styles.Vehicles_grid_header
                  )}
                >
                  Hours
                </div>
                <div
                  className={cn(
                    styles.Vehicles_grid_column,
                    styles.Vehicles_grid_header
                  )}
                >
                  WOF/Rego
                </div>
                <div
                  className={cn(
                    styles.Vehicles_grid_column,
                    styles.Vehicles_grid_header,
                    styles.Vehicles_grid_header_action
                  )}
                >
                  Actions
                </div>
                {/* Header of VehiclesGrid ends here */}

                {/* Body of VehiclesGrid starts here */}
                {vehicles.map(({ id, name, hours, rego, dateCreated }, i) => (
                  <div className={styles.Vehicles_keyWrapper} key={id}>
                    <div
                      className={styles.Vehicles_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 4} / 1 / ${
                                (i + 1) * 4 + 4
                              } / 1`,
                            }
                          : undefined
                      }
                    >
                      {dateFormat(dateCreated, 'dd/mm/yyyy')}
                    </div>
                    <div
                      className={styles.Vehicles_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 4 + 1} / 2 / ${
                                (i + 1) * 4 + 1
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {name}
                    </div>
                    <div
                      className={styles.Vehicles_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 4 + 2} / 2 / ${
                                (i + 1) * 4 + 2
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {hours || '--'}
                    </div>
                    <div
                      className={styles.Vehicles_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 4 + 3} / 2 / ${
                                (i + 1) * 4 + 3
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {rego || '--'}
                    </div>
                    <div
                      className={styles.Vehicles_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 4 + 1} / 3 / ${
                                (i + 1) * 4 + 3 + 1
                              } / ${(i + 1) * 4 + 1}`,
                            }
                          : undefined
                      }
                    >
                      <IconLink
                        to={`/employer/vehicles/view/${id}`}
                        className={styles.Vehicles_grid_viewButton}
                        icon="visibility"
                      />

                      <IconLink
                        to={`/employer/vehicles/update/${id}`}
                        className={styles.Vehicles_grid_editButton}
                        icon="edit"
                      />

                      <IconButton
                        className={styles.Vehicles_grid_deleteButton}
                        icon="highlight_off"
                        onClick={() => setSelectedVehicleId(id)}
                      />
                    </div>
                  </div>
                ))}

                {/* Body of VehiclesGrid ends here */}
              </div>
            </>
          ) : (
            <div className={styles.Vehicles_noVehicles}>
              <img
                src={NoResults}
                alt="TimeSheet No Vehicles"
                className={styles.Vehicles_noVehicles_image}
              />

              <Text
                colorClass={colorClasses.RED['400']}
                type={textTypes.HEADING.XS}
              >
                YOU CURRENTLY DON'T HAVE VEHICLES.
              </Text>

              <Text
                type={textTypes.HEADING.XXS}
                className={styles.Vehicles_noVehicles_withMargin}
              >
                Add one by clicking the Create Vehicle button at the upper side
                of the screen.
              </Text>
            </div>
          )}
        </>
      )}

      <SweetAlert
        show={selectedVehicleId !== null}
        title="Are you sure?"
        onConfirm={async () => {
          const { responseCode: deleteVehicleResponseCode } =
            await deleteVehicle(selectedVehicleId);

          const deleteVehicleCallbacks = {
            deleted: () => {
              alert.success('Vehicle deleted!');
            },
            invalidFields: () => alert.error('Invalid fields.'),
            internalError: () => alert.error('Oops, something went wrong.'),
          };

          switch (deleteVehicleResponseCode) {
            case 200:
              deleteVehicleCallbacks.deleted();
              break;
            case 400:
              deleteVehicleCallbacks.invalidFields();
              break;
            case 500:
              deleteVehicleCallbacks.internalError();
              break;
            default:
              break;
          }

          setSelectedVehicleId(null);
        }}
        onCancel={() => setSelectedVehicleId(null)}
      >
        This will delete all of the reviews under this vehicle.
      </SweetAlert>
    </div>
  );
};

export default Vehicles;
