import React, { useState, useContext, useEffect } from 'react';
import { useLocation, Switch, Redirect, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import styles from './styles.module.scss';

import {
  Dashboard,
  Timesheets,
  CreateTimesheet,
  ViewProject,
  Settings,
  Subscription,
} from '../../screens/contractor';
import { Button, Icon } from '../../components/elements';
import { ContactUsModal } from '../../components/modals';
import {
  Clients,
  CreateClient,
  UpdateClient,
  ViewClient,
  CreateProject,
  UpdateProject,
} from '../../screens/common';
import { useWindowSize } from '../../hooks';
import { UserContext } from '../../contexts';
import { isStaging, isLocal } from '../../utils/destinations';
import { dateTimeIsPast } from '../../utils/datetime';

import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

const stripePromise = loadStripe(
  isStaging || isLocal
    ? 'pk_test_zKGLhj75yMR6ljsqU1gcgB5b00VC1gga5U'
    : 'pk_live_tirYAGRE2KyAjiux3fvhJD9k00sT3alEo1'
);

/* eslint-disable react/jsx-props-no-spreading */
const ContractorContainer = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const isSubscribed =
    !dateTimeIsPast(user.subscriptionTrialEndDate) || user.isSubscribed;
  const { windowSize } = useWindowSize();

  let showSidebarByDefault = !isSubscribed;
  if (windowSize.width <= 991) {
    showSidebarByDefault = true;
  }

  const [isSidebarToggled, toggleSidebar] = useState(showSidebarByDefault);
  const [isContactUsShown, setIsContactUsShown] = useState(false);

  const handleToggleSidebar = (isToggled) => {
    toggleSidebar(isToggled);
  };

  useEffect(() => {
    handleToggleSidebar(true);
  }, [location]);

  return (
    <>
      <Navbar
        isSidebarToggled={isSidebarToggled}
        handleToggleSidebar={handleToggleSidebar}
        isSubscribed={isSubscribed}
      />

      {isSubscribed && <Sidebar isToggled={isSidebarToggled} />}

      <div className={styles.ContractorContainer}>
        <React.Suspense fallback={<div>Loading...</div>}>
          {!isSubscribed ? (
            <>
              <Switch>
                <Route
                  path="/contractor/subscription"
                  name="Subscription"
                  exact
                  render={(props) => (
                    <Elements stripe={stripePromise}>
                      <Subscription {...props} />
                    </Elements>
                  )}
                />
              </Switch>

              <Redirect to="/contractor/subscription" />
            </>
          ) : (
            <>
              <Switch>
                <Route
                  path="/contractor/dashboard"
                  name="Dashboard"
                  exact
                  render={(props) => <Dashboard {...props} />}
                />

                <Route
                  path="/contractor/timesheets"
                  name="Timesheets"
                  exact
                  render={(props) => <Timesheets {...props} />}
                />

                <Route
                  path="/contractor/timesheets/create"
                  name="Create Timesheet"
                  exact
                  render={(props) => (
                    <CreateTimesheet
                      {...props}
                      pageTitle="Create Timesheet"
                      pages={[
                        {
                          name: 'Dashboard',
                          link: '/contractor/dashboard',
                        },
                        {
                          name: 'Timesheets',
                          link: '/contractor/timesheets',
                        },
                      ]}
                    />
                  )}
                />

                <Route
                  path="/contractor/clients"
                  name="Clients"
                  exact
                  render={(props) => <Clients {...props} />}
                />

                <Route
                  path="/contractor/clients/create"
                  name="Create Client"
                  exact
                  render={(props) => <CreateClient {...props} />}
                />

                <Route
                  path="/contractor/clients/update/:clientId"
                  name="Update Client"
                  exact
                  render={(props) => <UpdateClient {...props} />}
                />

                <Route
                  path="/contractor/clients/view/:clientId"
                  name="View Client"
                  exact
                  render={(props) => <ViewClient {...props} />}
                />

                <Route
                  path="/contractor/clients/view/:clientId/projects/create"
                  name="Create Project"
                  exact
                  render={(props) => <CreateProject {...props} />}
                />

                <Route
                  path="/contractor/clients/view/:clientId/projects/update/:projectId"
                  name="Update Project"
                  exact
                  render={(props) => <UpdateProject {...props} />}
                />

                <Route
                  path="/contractor/clients/view/:clientId/projects/view/:projectId"
                  name="View Project"
                  exact
                  render={(props) => <ViewProject {...props} />}
                />

                <Route
                  path="/contractor/clients/view/:clientId/projects/view/:projectId/create"
                  name="Create Project Timesheet"
                  exact
                  render={(props) => (
                    <CreateTimesheet
                      {...props}
                      pageTitle="Create Project Timesheet"
                      pages={[
                        {
                          name: 'Dashboard',
                          link: '/contractor/dashboard',
                        },
                        {
                          name: 'Clients',
                          link: '/contractor/clients',
                        },
                        {
                          name: 'View Client',
                          // eslint-disable-next-line react/prop-types
                          link: `/contractor/clients/view/${props.match.params.clientId}`,
                        },
                        {
                          name: 'View Project',
                          // eslint-disable-next-line react/prop-types
                          link: `/contractor/clients/view/${props.match.params.clientId}/projects/view/${props.match.params.projectId}`,
                        },
                      ]}
                    />
                  )}
                />

                <Route
                  path="/contractor/settings/:activeTab"
                  name="Settings"
                  exact
                  render={(props) => <Settings {...props} />}
                />

                <Route
                  path="/contractor/subscription"
                  name="Subscription"
                  exact
                  render={(props) => (
                    <Elements stripe={stripePromise}>
                      <Subscription {...props} />
                    </Elements>
                  )}
                />

                <Redirect from="*" to="/contractor/dashboard" />
              </Switch>
            </>
          )}
        </React.Suspense>
      </div>

      <Button
        className={styles.helpButton}
        onClick={() => setIsContactUsShown(true)}
      >
        <Icon icon="help" className={styles.helpButton_icon} />
      </Button>

      <ContactUsModal
        isOpen={isContactUsShown}
        handleClose={() => setIsContactUsShown(false)}
        title="Contact Us"
      />
    </>
  );
};

export default ContractorContainer;
