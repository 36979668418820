import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from '../styles.module.scss';

import { ControlledInput, Modal, Text } from '../../../elements';
import {
  buttonTypes,
  colorClasses,
  inputKinds,
  modalPositions,
  modalSizes,
  textTypes,
} from '../../../../globals';
import { UsersService } from '../../../../services';

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'This field is required.';
  }

  return errors;
};

const InputPasswordModal = ({
  title,
  isOpen,
  handleClose,
  emailAddress,
  handleSuccess,
}) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const formRef = useRef();

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      className={styles.TabletViewTimesheetModals}
      actions={[
        {
          text: 'Proceed',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isVerifying,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          password: '',
        }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          setIsVerifying(true);

          const currentFormValues = {
            emailAddress,
            password: values.password,
          };

          const { data: loginResponse } = await UsersService.login(
            currentFormValues
          );

          if (loginResponse.message === 'login_failed') {
            setIsVerifying(false);
            setErrors({
              overall: 'Invalid password.',
            });
          } else {
            handleSuccess();
          }
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <form onSubmit={(e) => e.preventDefault()}>
            <ControlledInput
              kind={inputKinds.PASSWORD}
              placeholder="Password"
              name="password"
              icon="vpn_key"
              value={values.password}
              error={errors.password}
              onChange={(e) => setFieldValue('password', e.target.value)}
            />
            {errors.overall && (
              <Text
                className={styles.TabletViewTimesheetModals_withMargin}
                type={textTypes.BODY.XS}
                colorClass={colorClasses.RED['400']}
              >
                {errors.overall}
              </Text>
            )}
          </form>
        )}
      </Formik>
    </Modal>
  );
};

InputPasswordModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  // doesn't accept any parameter. This will just
  // proceed to the next modal
  handleSuccess: PropTypes.func.isRequired,
};

export default InputPasswordModal;
