import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/clients`;

const ClientsService = {
  create: (body) => axios.post(`${BASE_URL}/create`, body),
  get: (body) => axios.post(`${BASE_URL}/get`, body),
  singleGet: (body) => axios.post(`${BASE_URL}/singleGet`, body),
  update: (body) => axios.post(`${BASE_URL}/update`, body),
  delete: (clientId) => axios.post(`${BASE_URL}/delete`, clientId),
};

export default ClientsService;
