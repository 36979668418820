import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import DailyView from './DailyView';
import WeeklyView from './WeeklyView';
import FortnightlyView from './FortnightlyView';
import MonthlyView from './MonthlyView';

import { timesheetTypes } from '../../globals';
import { TimesheetContext } from '../../contexts';
import { getDayNameFromDayId } from '../../utils/datetime';

const ContractorTimesheetGrid = () => {
  const { viewType, dayIds, isTimesheetsLoading, windowSize } =
    useContext(TimesheetContext);

  if (isTimesheetsLoading) {
    return <Preloader type={viewType} />;
  }

  return (
    <div className={styles.TimesheetGrid}>
      {/* Header of TimesheetGrid starts here */}
      <div
        className={cn(
          styles.TimesheetGrid_grid,
          styles[`TimesheetGrid_grid___${viewType}`]
        )}
      >
        <div
          className={cn(
            styles.TimesheetGrid_column,
            styles.TimesheetGrid_header,
            styles.TimesheetGrid_header_clickable
          )}
        />

        {windowSize.width > 767 &&
          (viewType === timesheetTypes.FORTNIGHTLY ||
            viewType === timesheetTypes.MONTHLY) && (
            <div
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_header
              )}
            >
              Week
            </div>
          )}

        {windowSize.width > 767 &&
          (viewType === timesheetTypes.WEEKLY ||
            viewType === timesheetTypes.FORTNIGHTLY ||
            viewType === timesheetTypes.MONTHLY) && (
            <>
              {dayIds.map((dayId) => (
                <div
                  className={cn(
                    styles.TimesheetGrid_column,
                    styles.TimesheetGrid_header
                  )}
                  key={getDayNameFromDayId(dayId)}
                >
                  {getDayNameFromDayId(dayId)}
                </div>
              ))}
            </>
          )}

        <div
          className={cn(
            styles.TimesheetGrid_column,
            styles.TimesheetGrid_header,
            styles.TimesheetGrid_header_total
          )}
        >
          TOTAL
        </div>
      </div>
      {/* Header of TimesheetGrid ends here */}

      {/* Body of TimesheetGrid starts here */}
      <div
        className={cn(
          styles.TimesheetGrid_grid,
          styles.TimesheetGrid_grid_bodyWrapper,
          styles[`TimesheetGrid_grid___${viewType}`]
        )}
      >
        {viewType === timesheetTypes.DAILY && <DailyView />}

        {viewType === timesheetTypes.WEEKLY && <WeeklyView />}

        {viewType === timesheetTypes.FORTNIGHTLY && <FortnightlyView />}

        {viewType === timesheetTypes.MONTHLY && <MonthlyView />}
      </div>
      {/* Body of TimesheetGrid ends here */}
    </div>
  );
};

export default ContractorTimesheetGrid;
