import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from '../styles.module.scss';
import {
  modalSizes,
  modalPositions,
  buttonTypes,
  inputTypes,
} from '../../../../globals';
import { Modal, ControlledInput } from '../../../elements';
import { UsersService, ConfirmationCodesService } from '../../../../services';

const validate = (values) => {
  const errors = {};

  if (!values.emailAddress) {
    errors.emailAddress = 'This field is required.';
  }

  return errors;
};

const InputUsernameEmailModal = ({ isOpen, handleClose, handleSuccess }) => {
  const formRef = useRef(null);
  const [isVerifying, toggleIsVerifying] = useState(false);

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Just Checking"
      className={styles.ForgotPasswordModals}
      actions={[
        {
          text: isVerifying ? 'Loading...' : 'Proceed',
          type: buttonTypes.PRIMARY.VIOLET,
          disabled: isVerifying === true,
          onClick: () => {
            formRef.current.handleSubmit();
          },
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{ login: '' }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          toggleIsVerifying(true);

          // Check if user exists with the inputted emailAddress
          const { data: userExistsResponse } = await UsersService.userExists(
            values.emailAddress
          );

          if (userExistsResponse.message !== 'user_exists') {
            setErrors({
              emailAddress: 'The user does not exist.',
            });
            toggleIsVerifying(false);
            return;
          }

          // Create a forgot password confirmation code
          const { data: createConfirmationCodeResponse } =
            await ConfirmationCodesService.create({
              forEmailAddress: values.emailAddress,
            });

          if (
            createConfirmationCodeResponse.message ===
            'confirmation_code_created'
          ) {
            handleSuccess({
              userId: createConfirmationCodeResponse.userId,
              confirmationCode: createConfirmationCodeResponse.confirmationCode,
            });
          }

          toggleIsVerifying(false);
        }}
      >
        {({ errors, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ControlledInput
              type={inputTypes.SLIM}
              label="For security purposes, we need your email address"
              placeholder="Email Address"
              name="emailAddress"
              value={values.emailAddress}
              error={errors.emailAddress}
              onChange={(e) => setFieldValue('emailAddress', e.target.value)}
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
};

InputUsernameEmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default InputUsernameEmailModal;
