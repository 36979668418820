import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import dateFormat from 'dateformat';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import { Breadcrumbs, RosterGrid, SweetAlert } from '../../../components';
import { Button, Text } from '../../../components/elements';
import {
  AssignRoleOrShiftModal,
  ViewRosterModal,
} from '../../../components/modals';
import {
  buttonTypes,
  colorClasses,
  textTypes,
  timesheetTypes,
} from '../../../globals';
import { RosterContext, UserContext } from '../../../contexts';
import { useEmployees, useRosters } from '../../../hooks';
import { RostersService } from '../../../services';
import {
  dateIsPreviousWeek,
  getPrevOrAfterDate,
  isMiddleBetweenDates,
} from '../../../utils/datetime';
import { getDateEquivalence, generateDayIds } from '../../../utils/timesheets';
import { defaultRole, modalTypes } from './constants';
import RosterSidebar from './RosterSidebar';
import NoResults from '../../../static/images/Misc/no-results.webp';

const dateToday = dateFormat(new Date(), 'yyyy-mm-dd');

const Rosters = () => {
  const alert = useAlert();
  const { user } = useContext(UserContext);
  const numberOfStillAnEmployee = user?.employees.filter(
    (e) => e.isStillAnEmployee === true
  ).length;

  const startDateBasis = getDateEquivalence(
    timesheetTypes.WEEKLY,
    user.timesheetStartDate,
    'start'
  );
  const endDateBasis = getDateEquivalence(
    timesheetTypes.WEEKLY,
    user.timesheetStartDate,
    'end'
  );

  const [startDate, setStartDate] = useState(startDateBasis);
  const [endDate, setEndDate] = useState(endDateBasis);
  const [showRolloutVerification, setShowRolloutVerification] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalValues, setModalValues] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedRosterId, setSelectedRosterId] = useState(null);
  const [viewRosterValues, setViewRosterValues] = useState(null);

  const dayIds = generateDayIds(
    timesheetTypes.WEEKLY,
    startDate,
    user.timesheetStartWeek
  );

  const {
    isRostersLoading,
    rosters,
    isRolesLoading,
    roles,
    isAssigning: isAssigningRoleOrShift,
    assignRoleOrShift,
    createOrUpdateRoster,
    deleteRoster,
  } = useRosters({
    employerId: user.id,
    startDate,
    endDate,
  });

  const { isLoading: isEmployeesLoading, employees } = useEmployees({
    employerId: user.id,
  });

  const getEmployeeFullName = (employeeId) => {
    const employee = employees.find((emp) => emp.id === employeeId);

    return employee?.fullName;
  };

  return (
    <RosterContext.Provider
      value={{
        dayIds,
        isEmployeesLoading,
        employees,
        isRostersLoading,
        rosters,
        isRolesLoading,
        getEmployeeFullName,
        setSelectedRoleId,
        setSelectedRosterId,
        setViewRosterValues,
      }}
    >
      <div className={styles.Rosters}>
        <div className={styles.Rosters_firstRow}>
          <Breadcrumbs
            pageTitle="Rosters"
            pages={[
              {
                name: 'Dashboard',
                link: '/employer/dashboard',
              },
            ]}
          />

          {numberOfStillAnEmployee > 0 &&
            (dateIsPreviousWeek(startDateBasis, startDate) ||
              startDate === startDateBasis) && (
              <Button
                icon="update"
                type={buttonTypes.PRIMARY.BLUE}
                onClick={() => setShowRolloutVerification(true)}
                disabled={isRostersLoading}
              >
                Rollout Schedules to Next Week
              </Button>
            )}
        </div>

        {numberOfStillAnEmployee > 0 ? (
          <>
            <div className={styles.Rosters_secondRow}>
              <Button
                className={styles.Rosters_secondRow_item}
                icon="arrow_back"
                onClick={() => {
                  setStartDate(getPrevOrAfterDate(startDate, 7, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 7, 'subtract'));
                }}
              >
                <span className={styles.Rosters_secondRow_item_buttonText}>
                  Prev Week
                </span>
              </Button>

              <Text
                className={styles.Rosters_secondRow_item}
                type={textTypes.HEADING.XS}
              >
                {dateFormat(new Date(startDate), 'mmm d')} -{' '}
                {dateFormat(new Date(endDate), 'mmm d, yyyy')}
              </Text>

              {!isMiddleBetweenDates(
                startDate,
                endDate,
                getPrevOrAfterDate(dateToday, 7, 'add')
              ) && (
                <Button
                  className={styles.Rosters_secondRow_item}
                  icon="arrow_forward"
                  iconPosition="right"
                  onClick={() => {
                    setStartDate(getPrevOrAfterDate(startDate, 7, 'add'));
                    setEndDate(getPrevOrAfterDate(endDate, 7, 'add'));
                  }}
                >
                  <span className={styles.Rosters_secondRow_item_buttonText}>
                    Next Week
                  </span>
                </Button>
              )}
            </div>

            <div
              className={cn(styles.Rosters_thirdRow, {
                [styles.Rosters_thirdRow___active]:
                  startDate === startDateBasis ||
                  startDate === getPrevOrAfterDate(startDateBasis, 7, 'add'),
              })}
            >
              {(startDate === startDateBasis ||
                startDate === getPrevOrAfterDate(startDateBasis, 7, 'add')) && (
                <RosterSidebar
                  title="Roles"
                  isRoles
                  createRoleOnClick={() => {
                    setModalType(modalTypes.CREATE_ASSIGN_ROLE);
                    setModalValues({
                      modalType: modalTypes.CREATE_ASSIGN_ROLE,
                      ...defaultRole,
                    });
                  }}
                  data={roles}
                  onClick={async (
                    roleId,
                    name,
                    color,
                    description,
                    schedules
                  ) => {
                    const { data: getRoleEmployeesResponse } =
                      await RostersService.getRoleEmployees({
                        employerId: user.id,
                        roleId,
                        startDate,
                        endDate,
                      });

                    let tempRoleEmployees = [];
                    if (getRoleEmployeesResponse.roleEmployees) {
                      tempRoleEmployees =
                        getRoleEmployeesResponse.roleEmployees;
                    }

                    // Format role employees
                    const roleEmployees = [];

                    tempRoleEmployees.forEach((roleEmployeeId) => {
                      const fullName = getEmployeeFullName(roleEmployeeId);

                      if (fullName) {
                        roleEmployees.push({
                          label: fullName,
                          value: roleEmployeeId,
                        });
                      }
                    });

                    // Set states
                    setModalType(modalTypes.UPDATE_ASSIGN_ROLE);
                    setModalValues({
                      modalType: modalTypes.UPDATE_ASSIGN_ROLE,
                      roleId,
                      name,
                      description,
                      color: {
                        label: color.charAt(0).toUpperCase() + color.slice(1),
                        value: color,
                      },
                      schedules,
                      employees: roleEmployees,
                    });
                  }}
                />
              )}

              <RosterGrid />

              {(startDate === startDateBasis ||
                startDate === getPrevOrAfterDate(startDateBasis, 7, 'add')) && (
                <RosterSidebar
                  title="Employees"
                  data={employees}
                  onClick={(id, fullName) => {
                    const shift = rosters.find(
                      (roster) => roster.employeeId === id
                    );

                    setModalType(modalTypes.ASSIGN_SHIFT);
                    setModalValues(
                      shift
                        ? { modalType: modalTypes.ASSIGN_SHIFT, ...shift }
                        : {
                            modalType: modalTypes.ASSIGN_SHIFT,
                            employeeId: id,
                            fullName,
                            schedules: [],
                          }
                    );
                  }}
                />
              )}
            </div>

            {modalType !== null && modalValues !== null && (
              <AssignRoleOrShiftModal
                isOpen={modalType !== null && modalValues !== null}
                handleClose={() => {
                  setModalType(null);
                  setModalValues(null);
                }}
                title={`${
                  modalType === modalTypes.CREATE_ASSIGN_ROLE
                    ? 'Create/Assign New Role'
                    : `Assign ${
                        modalType === modalTypes.UPDATE_ASSIGN_ROLE
                          ? 'Role'
                          : 'Shift'
                      }`
                }`}
                formInitialValues={modalValues}
                isAssigning={isAssigningRoleOrShift}
                assignRoleOrShift={assignRoleOrShift}
                createOrUpdateRoster={createOrUpdateRoster}
              />
            )}

            {(dateIsPreviousWeek(startDateBasis, startDate) ||
              startDate === startDateBasis) && (
              <SweetAlert
                show={showRolloutVerification}
                title="Are you sure?"
                onConfirm={async () => {
                  const { data: rolloutSchedulesResponse } =
                    await RostersService.rolloutSchedulesToNextWeek({
                      employerId: user.id,
                      startDate,
                      endDate,
                      newStartDate: getPrevOrAfterDate(startDate, 7, 'add'),
                      newEndDate: getPrevOrAfterDate(endDate, 7, 'add'),
                    });

                  if (
                    rolloutSchedulesResponse.message === 'schedules_rolledout'
                  ) {
                    alert.success('Schedules Rolled Out to Next Week!');
                  } else {
                    alert.error('Oops, something went wrong.');
                  }

                  setShowRolloutVerification(false);
                }}
                onCancel={() => setShowRolloutVerification(false)}
              >
                This will override all existing schedules in the next week
              </SweetAlert>
            )}

            {viewRosterValues !== null && (
              <ViewRosterModal
                isOpen={viewRosterValues !== null}
                handleClose={() => setViewRosterValues(null)}
                title="View Roster"
                roleId={viewRosterValues?.roleId}
                roleName={viewRosterValues?.roleName}
                roleDescription={viewRosterValues?.roleDescription}
                roleColor={viewRosterValues?.roleColor}
                roleEmployees={viewRosterValues?.employees}
                employeeId={viewRosterValues?.employeeId}
                schedules={viewRosterValues?.schedules}
              />
            )}

            {(selectedRoleId !== null || selectedRosterId !== null) && (
              <SweetAlert
                show={selectedRoleId !== null || selectedRosterId !== null}
                title="Are you sure?"
                onConfirm={() => {
                  deleteRoster(selectedRoleId, selectedRosterId);

                  setSelectedRoleId(null);
                  setSelectedRosterId(null);
                }}
                onCancel={() => {
                  setSelectedRoleId(null);
                  setSelectedRosterId(null);
                }}
              >
                This will delete all related schedules{' '}
                {selectedRoleId && 'and the role as well.'}
              </SweetAlert>
            )}
          </>
        ) : (
          <div className={styles.Rosters_noEmployees}>
            <img
              src={NoResults}
              alt="TimeSheet No Employees"
              className={styles.Rosters_noEmployees_image}
            />

            <Text
              colorClass={colorClasses.RED['400']}
              type={textTypes.HEADING.XS}
            >
              YOU CURRENTLY DON'T HAVE EMPLOYEES.
            </Text>

            <Text
              type={textTypes.HEADING.XXS}
              className={styles.Rosters_noEmployees_withMargin}
            >
              Add one by clicking the link{' '}
              <Link
                to="/employer/employees/add"
                className={styles.Rosters_noEmployees_link}
              >
                here
              </Link>
              .
            </Text>
          </div>
        )}
      </div>
    </RosterContext.Provider>
  );
};

export default Rosters;
