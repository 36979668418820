import React, { useState, useEffect } from 'react';
import { useLocation, Switch, Redirect, Route } from 'react-router-dom';

import styles from './styles.module.scss';

import { Dashboard, DatabaseExport } from '../../screens/admin';

import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

/* eslint-disable react/jsx-props-no-spreading */
const AdminContainer = () => {
  const location = useLocation();
  const [isSidebarToggled, toggleSidebar] = useState(true);

  const handleToggleSidebar = (isToggled) => {
    toggleSidebar(isToggled);
  };

  useEffect(() => {
    handleToggleSidebar(true);
  }, [location]);

  return (
    <>
      <Navbar
        isSidebarToggled={isSidebarToggled}
        handleToggleSidebar={handleToggleSidebar}
        isSubscribed
      />

      <Sidebar isToggled={isSidebarToggled} />

      <div className={styles.AdminContainer}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              path="/admin/dashboard"
              name="Dashboard"
              exact
              render={(props) => <Dashboard {...props} />}
            />

            <Route
              path="/admin/database-export"
              name="DatabaseExport"
              exact
              render={(props) => <DatabaseExport {...props} />}
            />

            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
        </React.Suspense>
      </div>
    </>
  );
};

export default AdminContainer;
