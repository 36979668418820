import { useState, useEffect } from 'react';

import { UsersService } from '../services';

const useGetEmployee = ({ emailAddress }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState(null);
  useEffect(() => {
    const getEmployee = async () => {
      const { data: getEmployeeResponse } = await UsersService.getEmployerDetails({
        emailAddress,
      });
      if (getEmployeeResponse.employees) {
        setEmployee(getEmployeeResponse.employees);
      }

      setIsLoading(false);
    };

    if (emailAddress) {
        getEmployee();
    } else {
      setIsLoading(false);
    }
  }, [emailAddress]);
  return { isLoading, employee };
};

export default useGetEmployee;