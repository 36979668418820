import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { ProjectsService } from '../services';

const useClients = ({ clientId, search, isFromCreateTimeSheet = false }) => {
  
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  const deleteProject = async (projectId) => {
    const { data: deleteProjectResponse } = await ProjectsService.delete({
      clientId,
      projectId,
    });

    if (deleteProjectResponse.message === 'project_deleted') {
      alert.success('Project deleted.');

      setProjects((prevProjects) =>
        prevProjects.filter((prevProject) => prevProject.id !== projectId)
      );
    } else {
      alert.error('Oops, something went wrong.');
    }
  };

  useEffect(() => {
    const getProjects = async () => {
      const { data: getProjectsResponse } = await ProjectsService.get({
        clientId,
        search
      });

      if(isFromCreateTimeSheet){
        if (getProjectsResponse.projects) {
          const tempProjects = [];
          getProjectsResponse.projects.map((project) => {
            if(project.status === 'Ongoing'){
              tempProjects.push(project);
            }
          });
          setProjects(tempProjects);
        }
      }else{
        if (getProjectsResponse.projects) {
          setProjects(getProjectsResponse.projects);
        }
      }
      

      setIsLoading(false);
    };

    getProjects();
  }, [clientId,search]);

  return { isLoading, projects, deleteProject };
};

export default useClients;
