/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import styles from './styles.module.scss';

import { Card, Tabs, Text } from '../../../components/elements';
import settingsTabs from './constants/settingsTabs';
import { colorClasses, tabKinds, tabTypes, textTypes } from '../../../globals';

import AccountInformation from '../../common/AccountInformation';
import EmployerDetails from './EmployerDetails';

const Settings = ({
  match: {
    params: { activeTab },
  },
}) => (
  <div className={styles.Settings}>
    <div>
      <Text
        className={styles.Settings_title}
        type={textTypes.HEADING.XS}
        colorClass={colorClasses.NEUTRAL['300']}
      >
        Settings
      </Text>
      <Tabs
        type={tabTypes.VERTICAL.LG}
        tabs={[
          {
            name: settingsTabs.ACCOUNT_INFORMATION.name,
            value: settingsTabs.ACCOUNT_INFORMATION.value,
            kind: tabKinds.LINK,
            action: `/employee/settings/${settingsTabs.ACCOUNT_INFORMATION.value}`,
          },
          {
            name: settingsTabs.EMPLOYER_DETAILS.name,
            value: settingsTabs.EMPLOYER_DETAILS.value,
            kind: tabKinds.LINK,
            action: `/employee/settings/${settingsTabs.EMPLOYER_DETAILS.value}`,
          },
        ]}
        activeTab={activeTab}
      />
    </div>

    <Card className={styles.Settings_card}>
      <React.Suspense fallback={<div>loading</div>}>
        <Switch>
          <Route
            path={`/employee/settings/${settingsTabs.ACCOUNT_INFORMATION.value}`}
            name="Settings - Account Information"
            render={(props) => <AccountInformation {...props} />}
          />

          <Route
            path={`/employee/settings/${settingsTabs.EMPLOYER_DETAILS.value}`}
            name="Settings - Employer Details"
            render={(props) => <EmployerDetails {...props} />}
          />

          <Redirect to="/page-not-found" />
        </Switch>
      </React.Suspense>
    </Card>
  </div>
);

Settings.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Settings;
