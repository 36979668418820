import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import FileDownload from 'js-file-download';
import dateFormat from 'dateformat';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import { ControlledInput, DatePicker, Modal } from '../../elements';
import { buttonTypes, inputTypes } from '../../../globals';
import {
  useGenerateVehiclesReport,
  useGenerateVehicleReport,
} from '../../../hooks';
import { isValidEmail } from '../../../utils/string';

const validate = (values) => {
  const errors = {};

  if (values.generateType === 'Send') {
    if (!values.emailAddress) {
      errors.emailAddress = 'This field is required.';
    } else if (!isValidEmail(values.emailAddress)) {
      errors.emailAddress = 'This must be a valid email address.';
    }
  }

  if (!values.from) {
    errors.from = 'This field is required.';
  }

  if (!values.to) {
    errors.to = 'This field is required.';
  }

  if (
    values.from &&
    values.to &&
    new Date(values.to).getTime() < new Date(values.from).getTime()
  ) {
    errors.to = 'To date must be after From date.';
  }

  return errors;
};

const GenerateVehiclesReportModal = ({
  isOpen,
  handleClose,
  employerId,
  employerFullName,
  employerEmailAddress,
  vehicleId,
  generateType,
  reportType,
}) => {
  const alert = useAlert();

  let reportFunc;
  if (reportType === 'Vehicles') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    reportFunc = useGenerateVehiclesReport();
  } else if (reportType === 'Vehicle') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    reportFunc = useGenerateVehicleReport();
  }

  const { isGenerating: isGeneratingReport, generateReport } = reportFunc;

  const formRef = useRef();

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={`${reportType} Report`}
      actions={[
        {
          text: generateType || '',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isGeneratingReport,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          emailAddress: '',
          from: new Date(),
          to: new Date(),
          generateType,
        }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          const { emailAddress, from, to } = values;
          const fileName = `${reportType} Report___${dateFormat(
            new Date(from),
            'mmm d'
          )} - ${dateFormat(new Date(to), 'mmm d, yyyy')}`;

          let generateFunc;
          if (reportType === 'Vehicles') {
            generateFunc = () =>
              generateReport({
                emailAddress,
                employerId,
                employerFullName,
                employerEmailAddress,
                from: dateFormat(new Date(from), 'yyyy-mm-dd'),
                to: dateFormat(new Date(to), 'yyyy-mm-dd'),
                fileName,
                generateType,
              });
          } else if (reportType === 'Vehicle') {
            generateFunc = () =>
              generateReport({
                emailAddress,
                employerFullName,
                employerEmailAddress,
                vehicleId,
                from: dateFormat(new Date(from), 'yyyy-mm-dd'),
                to: dateFormat(new Date(to), 'yyyy-mm-dd'),
                fileName,
                generateType,
              });
          }

          const { responseCode: generateReportResponseCode, fileBlob } =
            await generateFunc();

          const generateReportCallbacks = {
            generated: () => {
              if (generateType === 'Download') {
                FileDownload(fileBlob, `${fileName}.xlsx`);
                alert.success(`${reportType} report downloaded!`);
              } else if (generateType === 'Send') {
                alert.success(`${reportType} report sent!`);
              }

              handleClose();
            },
            invalidFields: () => alert.error('Invalid fields.'),
            internalError: () => alert.error('Oops, something went wrong.'),
          };

          switch (generateReportResponseCode) {
            case 200:
              generateReportCallbacks.generated();
              break;
            case 400:
              generateReportCallbacks.invalidFields();
              break;
            case 500:
              generateReportCallbacks.internalError();
              break;
            default:
              break;
          }
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <>
            {generateType === 'Send' && (
              <ControlledInput
                className={styles.GenerateVehiclesReportModal_withMarginBottom}
                type={inputTypes.SLIM}
                label="Email Address*"
                name="emailAddress"
                icon="email"
                value={values.emailAddress}
                error={errors.emailAddress}
                onChange={(e) => setFieldValue('emailAddress', e.target.value)}
              />
            )}

            <DatePicker
              className={styles.GenerateVehiclesReportModal_withMarginBottom}
              type={inputTypes.SLIM}
              label="From*"
              dateFormat="dd/MM/yyyy"
              selected={values.from}
              name="from"
              icon="today"
              error={errors.from}
              onChange={(date) => {
                setFieldValue('from', date);
              }}
              disableFutureDates
            />

            <DatePicker
              type={inputTypes.SLIM}
              label="To*"
              dateFormat="dd/MM/yyyy"
              selected={values.to}
              name="to"
              icon="today"
              error={errors.to}
              onChange={(date) => {
                setFieldValue('to', date);
              }}
              minDate={values.from}
              disableFutureDates
            />
          </>
        )}
      </Formik>
    </Modal>
  );
};

GenerateVehiclesReportModal.defaultProps = {
  vehicleId: null,
};

GenerateVehiclesReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  employerId: PropTypes.string.isRequired,
  employerFullName: PropTypes.string.isRequired,
  employerEmailAddress: PropTypes.string.isRequired,
  vehicleId: PropTypes.string,
  generateType: PropTypes.oneOf(['Download', 'Send']).isRequired,
  reportType: PropTypes.oneOf(['Vehicles', 'Vehicle']).isRequired,
};

export default GenerateVehiclesReportModal;
