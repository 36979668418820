import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import EasyEdit from 'react-easy-edit';
import { useAlert } from 'react-alert';
import styles from '../styles.module.scss';

import { Icon, Text } from '../../elements';
import { isValidEmail } from '../../../utils/string';

const EditableEmail = ({ initialValue, placeholder, onSave }) => {
  const alert = useAlert();
  const [inputtedEmail, setInputtedEmail] = useState(initialValue);

  return (
    <EasyEdit
      type="text"
      saveButtonLabel={<Icon icon="check" />}
      cancelButtonLabel={<Icon icon="close" />}
      saveButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_save
      )}
      cancelButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_cancel
      )}
      displayComponent={
        <Text className={styles.Editable_displayValue}>{inputtedEmail}</Text>
      }
      placeholder={placeholder}
      value={inputtedEmail}
      onSave={async (val) => {
        if (!val) {
          alert.error('Please input an email address.');
          return;
        }

        if (!isValidEmail(val)) {
          alert.error('You must use a valid email address.');
          return;
        }

        const isUniqueEmailAddress = await onSave(val);
        if (isUniqueEmailAddress) {
          setInputtedEmail(val);
        }
      }}
    />
  );
};

EditableEmail.defaultProps = {
  initialValue: null,
  placeholder: 'Empty',
};

EditableEmail.propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default EditableEmail;
