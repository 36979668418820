import React, { useState, useEffect, useContext } from 'react';
import dateFormat from 'dateformat';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Breadcrumbs } from '../../../components';
import {
  Button,
  ControlledInput,
  Icon,
  IconLink,
  Text,
} from '../../../components/elements';
import { GenerateVehiclesReportModal } from '../../../components/modals';
import { buttonTypes, colorClasses, textTypes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { useVehicle, useReviews, useWindowSize } from '../../../hooks';
import NoResults from '../../../static/images/Misc/no-results.webp';

const ViewVehicle = () => {
  const { vehicleId } = useParams();
  const { user } = useContext(UserContext);
  const [reviews, setReviews] = useState([]);
  const [generateType, setGenerateType] = useState(null);
  const [search, setSearch] = useState('');

  const { isLoading: isVehicleLoading, vehicle } = useVehicle({
    vehicleId,
  });

  const { isLoading: isReviewsLoading, reviews: retrievedReviews } = useReviews(
    {
      vehicleId,
    }
  );

  const { windowSize } = useWindowSize();

  // Setting reviews considering the search functionality
  useEffect(() => {
    if (!isReviewsLoading) {
      if (!search) {
        setReviews(retrievedReviews);
      } else {
        // Search functionality
        setReviews(
          retrievedReviews.filter((review) => {
            const firstCondition = dateFormat(review.dateReviewed, 'dd/mm/yyyy')
              .toLowerCase()
              .match(new RegExp(search.toLowerCase(), 'g'));

            const secondCondition = review.employeeFullName
              .toLowerCase()
              .match(new RegExp(search.toLowerCase(), 'g'));

            const finalRego = review?.rego ? review?.rego : vehicle?.rego;
            let thirdCondition = false;
            if (finalRego !== undefined) {
              thirdCondition = finalRego
                .toLowerCase()
                .match(new RegExp(search.toLowerCase(), 'g'));
            }

            return firstCondition || secondCondition || thirdCondition;
          })
        );
      }
    }
  }, [isReviewsLoading, search]);

  return (
    <div className={styles.ViewVehicle}>
      <div className={styles.ViewVehicle_firstRow}>
        <Breadcrumbs
          pageTitle="View Vehicle"
          pages={[
            {
              name: 'Dashboard',
              link: `/employer/dashboard`,
            },
            {
              name: 'Vehicles',
              link: `/employer/vehicles`,
            },
          ]}
        />

        <div className={styles.ViewVehicle_reportButtons}>
          <Button
            className={styles.ViewVehicle_reportButtons_button}
            icon="send"
            onClick={() => setGenerateType('Send')}
            disabled={isVehicleLoading || isReviewsLoading}
          >
            <span className={styles.ViewVehicle_reportButtons_button_text}>
              Send Report
            </span>
          </Button>

          <Button
            className={styles.ViewVehicle_reportButtons_button}
            icon="download"
            type={buttonTypes.PRIMARY.RED}
            onClick={() => setGenerateType('Download')}
            disabled={isVehicleLoading || isReviewsLoading}
          >
            <span className={styles.ViewVehicle_reportButtons_button_text}>
              Download Report
            </span>
          </Button>
        </div>

        {generateType !== null && (
          <GenerateVehiclesReportModal
            isOpen={generateType !== null}
            handleClose={() => setGenerateType(null)}
            employerFullName={user.fullName}
            employerEmailAddress={user.emailAddress}
            vehicleId={vehicleId}
            generateType={generateType}
            reportType="Vehicle"
          />
        )}
      </div>

      <div className={styles.ViewVehicle_secondRow}>
        <Text type={textTypes.HEADING.MD}>
          {!isVehicleLoading && vehicle.name}
        </Text>

        {retrievedReviews.length ? (
          <ControlledInput
            className={styles.ViewVehicle_secondRow_searchInput}
            placeholder="Search"
            name="search"
            icon="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        ) : (
          <></>
        )}
      </div>

      {isReviewsLoading ? (
        <Preloader />
      ) : (
        <>
          {retrievedReviews.length ? (
            <div className={styles.ViewVehicle_grid}>
              {/* Header of ReviewGrid starts here */}
              <div
                className={cn(
                  styles.ViewVehicle_grid_column,
                  styles.ViewVehicle_grid_header
                )}
              >
                Date
              </div>
              <div
                className={cn(
                  styles.ViewVehicle_grid_column,
                  styles.ViewVehicle_grid_header
                )}
              >
                Employee Name
              </div>
              <div
                className={cn(
                  styles.ViewVehicle_grid_column,
                  styles.ViewVehicle_grid_header
                )}
              >
                WOF/Rego
              </div>
              <div
                className={cn(
                  styles.ViewVehicle_grid_column,
                  styles.ViewVehicle_grid_header
                )}
              >
                Oil Checked
              </div>
              <div
                className={cn(
                  styles.ViewVehicle_grid_column,
                  styles.ViewVehicle_grid_header
                )}
              >
                Vehicle Checked
              </div>
              <div
                className={cn(
                  styles.ViewVehicle_grid_column,
                  styles.ViewVehicle_grid_header
                )}
              >
                Vehicle Broken
              </div>
              <div
                className={cn(
                  styles.ViewVehicle_grid_column,
                  styles.ViewVehicle_grid_header
                )}
              >
                Hours
              </div>
              <div
                className={cn(
                  styles.ViewVehicle_grid_column,
                  styles.ViewVehicle_grid_header,
                  styles.ViewVehicle_grid_header_action
                )}
              >
                Action
              </div>
              {/* Header of ReviewGrid ends here */}

              {/* Body of ReviewGrid starts here */}
              {reviews.map(
                (
                  {
                    id,
                    dateReviewed,
                    employeeFullName,
                    rego,
                    isOilChecked,
                    isVehicleChecked,
                    isVehicleBroken,
                    hours,
                  },
                  i
                ) => (
                  <div className={styles.ViewVehicle_keyWrapper} key={id}>
                    <div
                      className={styles.ViewVehicle_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7} / 1 / ${
                                (i + 1) * 7 + 6
                              } / 1`,
                            }
                          : undefined
                      }
                    >
                      {dateFormat(dateReviewed, 'dd/mm/yyyy')}
                    </div>
                    <div
                      className={styles.ViewVehicle_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 1} / 2 / ${
                                (i + 1) * 7 + 1
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {employeeFullName}
                    </div>
                    <div
                      className={styles.ViewVehicle_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 2} / 2 / ${
                                (i + 1) * 7 + 2
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {rego || (vehicle?.rego ? vehicle?.rego : '--')}
                    </div>
                    <div
                      className={styles.ViewVehicle_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 3} / 2 / ${
                                (i + 1) * 7 + 3
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {isOilChecked ? (
                        <Icon
                          icon="check"
                          className={cn(
                            styles.ViewVehicle_grid_icon,
                            styles.ViewVehicle_grid_icon___green
                          )}
                        />
                      ) : (
                        <Icon
                          icon="close"
                          className={cn(
                            styles.ViewVehicle_grid_icon,
                            styles.ViewVehicle_grid_icon___red
                          )}
                        />
                      )}
                    </div>
                    <div
                      className={styles.ViewVehicle_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 4} / 2 / ${
                                (i + 1) * 7 + 4
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {isVehicleChecked ? (
                        <Icon
                          icon="check"
                          className={cn(
                            styles.ViewVehicle_grid_icon,
                            styles.ViewVehicle_grid_icon___green
                          )}
                        />
                      ) : (
                        <Icon
                          icon="close"
                          className={cn(
                            styles.ViewVehicle_grid_icon,
                            styles.ViewVehicle_grid_icon___red
                          )}
                        />
                      )}
                    </div>
                    <div
                      className={styles.ViewVehicle_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 4} / 2 / ${
                                (i + 1) * 7 + 4
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {isVehicleBroken ? (
                        <Text colorClass={colorClasses.RED['400']}>Yes</Text>
                      ) : (
                        <Text colorClass={colorClasses.GREEN['400']}>No</Text>
                      )}
                    </div>
                    <div
                      className={styles.ViewVehicle_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7 + 5} / 2 / ${
                                (i + 1) * 7 + 5
                              } / 2`,
                            }
                          : undefined
                      }
                    >
                      {hours}
                    </div>
                    <div
                      className={styles.ViewVehicle_grid_column}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${(i + 1) * 7} / 3 / ${
                                (i + 1) * 7 + 6
                              } / 3`,
                            }
                          : undefined
                      }
                    >
                      <IconLink
                        to={`/employer/vehicles/view/${vehicleId}/reviews/view/${id}`}
                        className={styles.ViewVehicle_grid_viewButton}
                        icon="visibility"
                      />
                    </div>
                  </div>
                )
              )}
              {/* Body of ReviewGrid ends here */}
            </div>
          ) : (
            <div className={styles.ViewVehicle_noReviews}>
              <img
                src={NoResults}
                alt="TimeSheet No Reviews"
                className={styles.ViewVehicle_noReviews_image}
              />

              <Text
                colorClass={colorClasses.RED['400']}
                type={textTypes.HEADING.XS}
              >
                THIS VEHICLE DOESN'T HAVE REVIEWS.
              </Text>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ViewVehicle;
