import React, { useContext } from 'react';
import { Formik } from 'formik';
import { useAlert } from 'react-alert';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  Spinner,
} from '../../../components/elements';
import { isValidDecimal } from '../../../utils/string';
import { buttonKinds, colorNames, spinnerSizes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { useCreateVehicle } from '../../../hooks';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'This field is required.';
  }

  if (!values.hours) {
    errors.hours = 'This field is required.';
  } else if (!isValidDecimal(values.hours)) {
    errors.hours = 'Only numbers with a maximum of 2 decimals are allowed.';
  }

  return errors;
};

const CreateVehicle = () => {
  const alert = useAlert();
  const { user } = useContext(UserContext);
  const { isCreating: isVehicleCreating, createVehicle } = useCreateVehicle();

  return (
    <div className={styles.CreateVehicle}>
      <Breadcrumbs
        pageTitle="Create Vehicle"
        pages={[
          {
            name: 'Dashboard',
            link: '/employer/dashboard',
          },
          {
            name: 'Vehicles',
            link: '/employer/vehicles',
          },
        ]}
      />

      <Card className={styles.CreateVehicle_card}>
        <Formik
          initialValues={{
            name: '',
            hours: '',
            rego: '',
          }}
          onSubmit={async (values, { setErrors, setFieldValue }) => {
            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            const newVehicle = {
              employerId: user.id,
              name: values.name,
              hours: values.hours,
              rego: values.rego ? values.rego : undefined,
            };

            const { responseCode: createVehicleResponseCode } =
              await createVehicle(newVehicle);

            const createVehicleCallbacks = {
              created: () => {
                alert.success('Vehicle created!');

                // Reset the form to its initial state
                setFieldValue('name', '');
                setFieldValue('hours', '');
                setFieldValue('rego', '');
              },
              invalidFields: () => alert.error('Invalid fields.'),
              internalError: () => alert.error('Oops, something went wrong.'),
            };

            switch (createVehicleResponseCode) {
              case 200:
                createVehicleCallbacks.created();
                break;
              case 400:
                createVehicleCallbacks.invalidFields();
                break;
              case 500:
                createVehicleCallbacks.internalError();
                break;
              default:
                break;
            }
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <ControlledInput
                name="name"
                placeholder="Name*"
                icon="person"
                value={values.name}
                error={errors.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
              />

              <ControlledInput
                className={styles.CreateVehicle_withMargin}
                name="hours"
                placeholder="Hours*"
                icon="schedule"
                value={values.hours}
                error={errors.hours}
                onChange={(e) => setFieldValue('hours', e.target.value)}
              />

              <ControlledInput
                className={styles.CreateVehicle_withMargin}
                name="rego"
                placeholder="WOF/Rego"
                icon="badge"
                value={values.rego}
                error={errors.rego}
                onChange={(e) => setFieldValue('rego', e.target.value)}
              />

              <div className={styles.CreateVehicle_buttonGroup}>
                <Button
                  className={styles.CreateVehicle_buttonGroup_button}
                  kind={buttonKinds.SUBMIT}
                  icon="add"
                  disabled={isVehicleCreating}
                  onClick={() => {}}
                >
                  <span className={styles.CreateVehicle_buttonGroup_buttonText}>
                    Create Vehicle
                    {isVehicleCreating && (
                      <Spinner
                        size={spinnerSizes.XS}
                        colorName={colorNames.WHITE}
                        className={styles.CreateVehicle_buttonGroup_spinner}
                      />
                    )}
                  </span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default CreateVehicle;
