import React from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';
import { colorClasses, textTypes, radioTypes } from '../../../globals';

import ControlledRadio from './Controlled';

const RadioGroup = ({
  label,
  className,
  innerClassName,
  options,
  checked,
  disabled,
  type,
}) => (
  <div className={className}>
    {label && (
      <Text type={textTypes.BODY.SM} colorClass={colorClasses.NEUTRAL['400']}>
        {label}
      </Text>
    )}
    <div className={innerClassName}>
      {options?.map(
        ({ id, value, label: radioLabel, name, onChange, icon }) => (
          <ControlledRadio
            key={id}
            type={type}
            id={id}
            value={value}
            label={radioLabel}
            name={name}
            checked={checked === value}
            onChange={onChange}
            disabled={disabled}
            icon={icon}
          />
        )
      )}
    </div>
  </div>
);

RadioGroup.defaultProps = {
  className: null,
  innerClassName: null,
  label: null,
  disabled: false,
  type: radioTypes.SMALL,
  checked: null,
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      onChange: PropTypes.func,
      icon: PropTypes.string,
    })
  ).isRequired,
  checked: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([radioTypes.SMALL, radioTypes.LARGE]),
};

export default RadioGroup;
