import React, { useState, useContext } from 'react';
import cn from 'classnames';
import styles from '../styles.module.scss';
import ComponentStyles from './styles.module.scss';

import { Icon, Text } from '../../elements';
import { UserContext, TimesheetContext } from '../../../contexts';
import {
  getPrevOrAfterDate,
  getMonthFromDate,
  getYearFromDate,
  getWeeksInMonth,
  getNumberOfSkipDays,
  getDayNameFromDayId,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import { getHours } from '../../../utils/timesheets';

import TimesheetCarousel from '../TimesheetCarousel';

const MonthlyView = () => {
  const { user } = useContext(UserContext);
  const { startDate, endDate, dayIds, timesheets, windowSize } =
    useContext(TimesheetContext);
  const [isActive, setIsActive] = useState(false);
  const numberOfWeeks = getWeeksInMonth(
    getYearFromDate(endDate),
    getMonthFromDate(startDate),
    user.timesheetStartWeek
  );
  const gridRowStart = 1;
  const gridRowEnd = 1 + numberOfWeeks;
  let overallTotalHours = 0;
  let skipIndex1 = 0;
  let skipIndex2 = 0;

  return (
    <div className={ComponentStyles.MonthlyView}>
      <div className={styles.TimesheetGrid_keyWrapper}>
        {!isActive ? (
          <>
            <button
              type="button"
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_clickable,
                {
                  [ComponentStyles.MonthlyView_fourRows]: numberOfWeeks === 4,
                },
                {
                  [ComponentStyles.MonthlyView_fiveRows]: numberOfWeeks === 5,
                },
                {
                  [ComponentStyles.MonthlyView_sixRows]: numberOfWeeks === 6,
                }
              )}
              onClick={() => setIsActive(true)}
              style={
                windowSize.width <= 767
                  ? {
                      gridArea: `1 / 1 / ${numberOfWeeks * 8 + 3} / 1`,
                    }
                  : undefined
              }
            >
              <Icon
                icon="expand_more"
                className={styles.Navbar_navUser_dropdown_link_icon}
              />
            </button>

            {[...Array(numberOfWeeks)].map((_1, i1) => (
              <>
                {windowSize.width <= 767 && (
                  <>
                    {dayIds.map((dayId, i) => (
                      <div
                        className={cn(styles.TimesheetGrid_column)}
                        key={getDayNameFromDayId(dayId)}
                        style={
                          windowSize.width <= 767
                            ? {
                                alignItems: 'flex-start',
                                gridArea: `${3 + i + i1 * 7 + i1} / 2 / ${
                                  3 + i + i1 * 7 + i1
                                } / 2`,
                              }
                            : undefined
                        }
                      >
                        {getDayNameFromDayId(dayId)}
                      </div>
                    ))}
                  </>
                )}

                <div className={styles.TimesheetGrid_keyWrapper} key={i1}>
                  <div
                    className={cn(
                      styles.TimesheetGrid_column,
                      styles.TimesheetGrid_centerText
                    )}
                    style={
                      windowSize.width <= 767
                        ? {
                            gridArea: `${1 + (i1 * 8 + 1)} / 2 / ${
                              1 + (i1 * 8 + 1)
                            } / 4`,
                          }
                        : undefined
                    }
                  >
                    {windowSize.width <= 767 && 'Week '}
                    {i1 + 1}
                  </div>

                  {[...Array(7)].map((_2, i2) => {
                    const numberOfSkipDays = getNumberOfSkipDays(
                      getYearFromDate(endDate),
                      getMonthFromDate(startDate),
                      user.timesheetStartWeek
                    );

                    if (i1 === 0 && numberOfSkipDays > i2) {
                      return (
                        <div className={styles.TimesheetGrid_column} key={i2}>
                          0
                        </div>
                      );
                    }

                    const timesheetDate = getPrevOrAfterDate(
                      startDate,
                      skipIndex1,
                      'add'
                    );
                    const timesheetsForDay = timesheets.filter(
                      (timesheet) =>
                        timesheet.userId === user.id &&
                        timesheet.date === timesheetDate
                    );

                    let totalHours = 0;
                    timesheetsForDay.forEach(
                      ({
                        date,
                        startTimeDetails = {
                          endTime: undefined,
                          actualTime: undefined,
                        },
                        endTimeDetails = {
                          endTime: undefined,
                          actualTime: undefined,
                        },
                        isLunchBreak,
                        lunchBreakDuration,
                        leaveType,
                        publicHolidayMultiplier,
                      }) => {
                        const startTimeTimestamp = convertDateTimeToTimestamp(
                          date,
                          convertTimeTo24HourFormat(startTimeDetails.startTime)
                        );
                        const endTimeTimestamp = convertDateTimeToTimestamp(
                          date,
                          convertTimeTo24HourFormat(endTimeDetails.endTime)
                        );

                        totalHours += Number(
                          getHours(
                            startTimeTimestamp,
                            endTimeTimestamp,
                            isLunchBreak,
                            lunchBreakDuration,
                            // leaveType,
                            // publicHolidayMultiplier
                          )
                        );
                      }
                    );

                    overallTotalHours += totalHours;
                    skipIndex1++;

                    return (
                      <div
                        className={styles.TimesheetGrid_column}
                        key={i2}
                        style={
                          windowSize.width <= 767
                            ? {
                                gridArea: `${2 + (i2 + 1) + i1 * 8} / 3 / ${
                                  2 + (i2 + 1) + i1 * 8
                                } / 3`,
                              }
                            : undefined
                        }
                      >
                        {totalHours > 0
                          ? user?.hoursAndMinutesFormat
                            ? hoursWithDecimalToHoursAndMinutes(
                                totalHours.toFixed(2)
                              )
                            : totalHours.toFixed(2)
                          : 0}
                      </div>
                    );
                  })}
                </div>
              </>
            ))}

            <div
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_boldText,
                ComponentStyles.MonthlyView_overallTotalHours
              )}
              style={
                windowSize.width <= 767
                  ? {
                      gridArea: `1 / 2 / 1 / 4`,
                    }
                  : {
                      gridRow: `${gridRowStart} / ${gridRowEnd}`,
                    }
              }
            >
              {overallTotalHours > 0
                ? user?.hoursAndMinutesFormat
                  ? hoursWithDecimalToHoursAndMinutes(
                      overallTotalHours.toFixed(2)
                    )
                  : overallTotalHours.toFixed(2)
                : 0}
            </div>
          </>
        ) : (
          <>
            <button
              type="button"
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_clickable,
                styles.TimesheetGrid_clickable___active,
                {
                  [ComponentStyles.MonthlyView_fourRows]: numberOfWeeks === 4,
                },
                {
                  [ComponentStyles.MonthlyView_fiveRows]: numberOfWeeks === 5,
                },
                {
                  [ComponentStyles.MonthlyView_sixRows]: numberOfWeeks === 6,
                }
              )}
              onClick={() => setIsActive(false)}
              style={
                windowSize.width <= 767
                  ? {
                      gridArea: `1 / 1 / ${numberOfWeeks * 8 + 3} / 1`,
                    }
                  : undefined
              }
            >
              <Icon
                icon="expand_less"
                className={styles.Navbar_navUser_dropdown_link_icon}
              />
            </button>

            {[...Array(numberOfWeeks)].map((_1, i1) => {
              const rowPosition = (i1 + 1) % 2; // For color class

              return (
                <>
                  {windowSize.width <= 767 && (
                    <>
                      {dayIds.map((dayId, i) => (
                        <div
                          className={cn(
                            styles.TimesheetGrid_column,
                            styles.TimesheetGrid_column___active,
                            {
                              [styles.TimesheetGrid_column_even]:
                                rowPosition === 0,
                            }
                          )}
                          key={getDayNameFromDayId(dayId)}
                          style={
                            windowSize.width <= 767
                              ? {
                                  alignItems: 'flex-start',
                                  gridArea: `${3 + i + i1 * 7 + i1} / 2 / ${
                                    3 + i + i1 * 7 + i1
                                  } / 2`,
                                }
                              : undefined
                          }
                        >
                          {getDayNameFromDayId(dayId)}
                        </div>
                      ))}
                    </>
                  )}

                  <div className={styles.TimesheetGrid_keyWrapper} key={i1}>
                    <div
                      className={cn(
                        styles.TimesheetGrid_column,
                        styles.TimesheetGrid_column___active,
                        {
                          [styles.TimesheetGrid_column_even]: rowPosition === 0,
                        },
                        styles.TimesheetGrid_centerText
                      )}
                      style={
                        windowSize.width <= 767
                          ? {
                              gridArea: `${1 + (i1 * 8 + 1)} / 2 / ${
                                1 + (i1 * 8 + 1)
                              } / 4`,
                            }
                          : undefined
                      }
                    >
                      {windowSize.width <= 767 && 'Week '}
                      {i1 + 1}
                    </div>

                    {[...Array(7)].map((_2, i2) => {
                      const numberOfSkipDays = getNumberOfSkipDays(
                        getYearFromDate(endDate),
                        getMonthFromDate(startDate),
                        user.timesheetStartWeek
                      );

                      if (i1 === 0 && numberOfSkipDays > i2) {
                        return (
                          <div
                            className={cn(
                              styles.TimesheetGrid_column,
                              styles.TimesheetGrid_column___active,
                              {
                                [styles.TimesheetGrid_column_even]:
                                  rowPosition === 0,
                              }
                            )}
                            key={i2}
                          >
                            --
                          </div>
                        );
                      }

                      const timesheetDate = getPrevOrAfterDate(
                        startDate,
                        skipIndex2,
                        'add'
                      );
                      const timesheetsForDay = timesheets.filter(
                        (timesheet) =>
                          timesheet.userId === user.id &&
                          timesheet.date === timesheetDate
                      );
                      const totalTimesheetsForDay = timesheetsForDay.length;

                      let totalHours = 0;
                      timesheetsForDay.forEach(
                        ({
                          date,
                          startTimeDetails = {
                            endTime: undefined,
                            actualTime: undefined,
                          },
                          endTimeDetails = {
                            endTime: undefined,
                            actualTime: undefined,
                          },
                          isLunchBreak,
                          lunchBreakDuration,
                          leaveType,
                          publicHolidayMultiplier,
                        }) => {
                          const startTimeTimestamp = convertDateTimeToTimestamp(
                            date,
                            convertTimeTo24HourFormat(
                              startTimeDetails.startTime
                            )
                          );
                          const endTimeTimestamp = convertDateTimeToTimestamp(
                            date,
                            convertTimeTo24HourFormat(endTimeDetails.endTime)
                          );

                          totalHours += Number(
                            getHours(
                              startTimeTimestamp,
                              endTimeTimestamp,
                              isLunchBreak,
                              lunchBreakDuration,
                              // leaveType,
                              // publicHolidayMultiplier
                            )
                          );
                        }
                      );

                      overallTotalHours += totalHours;
                      skipIndex2++;

                      return (
                        <div
                          className={cn(
                            styles.TimesheetGrid_column,
                            styles.TimesheetGrid_column___active,
                            {
                              [styles.TimesheetGrid_column_even]:
                                rowPosition === 0,
                            }
                          )}
                          key={i2}
                          style={
                            windowSize.width <= 767
                              ? {
                                  gridArea: `${2 + (i2 + 1) + i1 * 8} / 3 / ${
                                    2 + (i2 + 1) + i1 * 8
                                  } / 3`,
                                }
                              : undefined
                          }
                        >
                          {!totalTimesheetsForDay ? (
                            <Text className={styles.TimesheetGrid_centerText}>
                              --
                            </Text>
                          ) : (
                            <TimesheetCarousel
                              totalHours={totalHours}
                              timesheetsForDay={timesheetsForDay}
                              totalTimesheets={totalTimesheetsForDay}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}

            <div
              className={cn(
                styles.TimesheetGrid_column,
                styles.TimesheetGrid_column___active,
                styles.TimesheetGrid_boldText,
                ComponentStyles.MonthlyView_overallTotalHours
              )}
              style={
                windowSize.width <= 767
                  ? {
                      gridArea: `1 / 2 / 1 / 4`,
                    }
                  : {
                      gridRow: `${gridRowStart} / ${gridRowEnd}`,
                    }
              }
            >
              {overallTotalHours > 0
                ? user?.hoursAndMinutesFormat
                  ? hoursWithDecimalToHoursAndMinutes(
                      overallTotalHours.toFixed(2)
                    )
                  : overallTotalHours.toFixed(2)
                : 0}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MonthlyView;
