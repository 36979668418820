import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';
import dateFormat from 'dateformat';
import {useAlert} from "react-alert";

import { ControlledInput, ControlledSelect, DatePicker, Modal, Text } from '../../elements';
import {
  buttonTypes,
  inputTypes,
  textTypes,
  timesheetTypes,
  selectTypes,
} from '../../../globals';
import { useGenerateContractorTimesheetReport, useTimesheets } from '../../../hooks';
import { isValidEmail } from '../../../utils/string';
import {
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
} from '../../../utils/datetime';
import { getHours } from '../../../utils/timesheets';
import pdfHeader from '../../../static/images/PDF/HEADER.png';
import pdfFooter from '../../../static/images/PDF/FOOTER.png';
import jsPDF from "jspdf";
import moment from 'moment';
import config from '../../../services/config';
import axios from 'axios';
import { UserContext } from '../../../contexts';

const validate = (values) => {
  const errors = {};

  if (values.reportType === 'Send') {
    if (!values.emailAddress) {
      errors.emailAddress = 'This field is required.';
    } else if (!isValidEmail(values.emailAddress)) {
      errors.emailAddress = 'This must be a valid email address.';
    }
  }

  if (
    values.from &&
    values.to &&
    new Date(values.to).getTime() < new Date(values.from).getTime()
  ) {
    errors.to = 'To date must be after From date.';
  }

  return errors;
};

const GenerateContractorTimesheetReportModal = ({
  isOpen,
  handleClose,
  reportType,
  viewType,
  startDate,
  endDate,
  timesheets,
  contractorFullName,
  contractorEmailAddress,
  isProject,
  hoursAndMinutesFormat,
}) => {
  const {
    isGenerating: isGeneratingContractorReport,
    generateContractorReport,
  } = useGenerateContractorTimesheetReport();

  const { user } = useContext(UserContext);

  const formRef = useRef();
  const alert = useAlert()
  const [totalIncomeEarned, setTotalIncomeEarned] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");


  if (fromDate != '' && toDate != '') {
    startDate = fromDate;
    endDate = toDate;
  }

  const { timesheets: allTimesheets , isLoading: isTimesheetsLoading,} = useTimesheets({
    userId:user.id,
    startDate: startDate,
    endDate: endDate,
  });

  if(allTimesheets.length >= 0) {
    timesheets = allTimesheets;
  }

  useEffect(() => {
    // Calculate for the total earnings
    let totalEarnings = 0;
    timesheets.forEach(
      ({
        date,
        startTimeDetails = {
          endTime: undefined,
          actualTime: undefined,
        },
        endTimeDetails = {
          endTime: undefined,
          actualTime: undefined,
        },
        isLunchBreak,
        leaveType,
        publicHolidayMultiplier,
        wage,
      }) => {
        const startTimeTimestamp = convertDateTimeToTimestamp(
          date,
          convertTimeTo24HourFormat(startTimeDetails.startTime)
        );
        const endTimeTimestamp = convertDateTimeToTimestamp(
          date,
          convertTimeTo24HourFormat(endTimeDetails.endTime)
        );

        totalEarnings +=
          wage *
          Number(
            getHours(
              startTimeTimestamp,
              endTimeTimestamp,
              isLunchBreak,
              leaveType,
              publicHolidayMultiplier
            )
          );
      }
    );

    setTotalIncomeEarned(totalEarnings);
  }, [timesheets]);
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={`${reportType} Report`}
      actions={[
        {
          text: isTimesheetsLoading ? 'Loading...' : reportType,
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isGeneratingContractorReport || isTimesheetsLoading,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          reportType,
          emailAddress: '',
          projectType: isProject
            ? {
              label: 'Complete',
              value: 'Complete',
            }
            : null,
          downloadType: {
            label: 'Excel',
            value: 'excel',
          }
        }}
        onSubmit={async (values, { setErrors }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          const { emailAddress, projectType } = values;

          if (values.downloadType.value === "pdf") {
            
            if (reportType === "Download") {
              const columns = [
                { header: 'Date', dataKey: 'date' },
                { header: 'Start', dataKey: 'startTime' },
                { header: 'Actual Start', dataKey: 'actualStartTime' },
                { header: 'End', dataKey: 'endTime' },
                { header: 'Actual End', dataKey: 'actualEndTime' },
                { header: 'Lunch', dataKey: 'isLunchBreak' },
                { header: 'Hours', dataKey: 'hours' },
                { header: 'Wage', dataKey: 'wage' },
                { header: 'Income', dataKey: 'income' },
                { header: 'Notes', dataKey: 'notes' },
                // { header: 'Cost', dataKey: 'cost' },
                { header: 'Total Coast', dataKey: 'totalCost' },
              ];
             
              const doc = new jsPDF("p", "mm", "a3");
              doc.addImage(pdfHeader, 'PNG', 0, 0, 300, 0);

              doc.setFontSize(18)
              doc.setFont('Lato', 'bold')
              doc.text("Timesheet Report ", 13, 50);

              doc.setFontSize(18)
              doc.setFont('Lato', 'bold')
              // doc.setTextColor('#55595C')
              doc.text(`${moment(startDate).format('MMM DD,Y')} - ${moment(endDate).format('MMM DD,Y')}`, 65, 50);

              let timesheetData = [];

              for (let i = 0; i < timesheets.length; i += 1) {
                let timesheet = timesheets[i];
                const startTimeTimestamp = convertDateTimeToTimestamp(timesheet.date, convertTimeTo24HourFormat(timesheet.startTimeDetails.startTime));
                const endTimeTimestamp = convertDateTimeToTimestamp(timesheet.date, convertTimeTo24HourFormat(timesheet.endTimeDetails.endTime));
                
                const hours = getHours(
                  startTimeTimestamp,
                  endTimeTimestamp,
                  timesheet.isLunchBreak,
                  timesheet.lunchBreakDuration,
                  timesheet.isTravelCharge,
                  timesheet.leaveType,
                  timesheet.publicHolidayMultiplier,
                );

                const totalCost = timesheet.costs
                  ? Number(timesheet.costs?.reduce((a, b) => a + Number(b.price), 0))
                  : 0;

                const incomeEarned = Number(timesheet.wage) * hours;
                const travelCharge = timesheet.isTravelCharge ? "Yes" : "No";
                const isLunchBreak = timesheet.isLunchBreak ? 'Yes' : 'No';

                const actualStart = timesheet.startTimeDetails.actualTime;
                const actualEnd = timesheet.endTimeDetails.actualTime;
                  
        
                timesheetData.push({
                  date: timesheet.date,
                  startTime: timesheet.startTimeDetails.startTime,
                  endTime: timesheet.endTimeDetails.endTime,
                  actualStartTime: actualStart,
                  actualEndTime: actualEnd,
                  isLunchBreak: isLunchBreak,
                  hours: hours,
                  wage: timesheet.wage,
                  income: `$${incomeEarned}`,
                  notes: timesheet.notes,
                  // cost: "",
                  totalCost: `$${totalCost}`,
                });
              }

              doc.autoTable({
                headStyles: { fillColor: "#967ADC", minCellHeight: 14, halign: 'left', fontStyle: 'lighter', cellPadding: { top: 4, right: 3, left: 1 } },
                margin: { bottom: 60 },
                startY: 63,
                styles: { cellPadding: { top: 2, right: 1, left: 1, bottom: 1 }, minCellHeight: 14, fontStyle: 'lighter' },
                columnStyles: { text: { cellWidth: 10 } },
                columns: columns.map(e => ({ ...e, dataKey: e.dataKey })),
                body: timesheetData
              })
              doc.addImage(pdfFooter, 'PNG', 0, 350, 300, 0)
              doc.save('report.pdf')
              handleClose();

            } else {
              const BASE_URL = `${config.API_URL}/timesheets/send-contactor-timesheet-in-pdf`;
              const data = {
                viewType,
                startDate,
                endDate,
                emailAddress,
                timesheets,
                contractorFullName,
                contractorEmailAddress,
                reportType,
                isProject,
                projectType: projectType?.value,
                hoursAndMinutesFormat,
              };

              const response = await axios.post(`${BASE_URL}`, data).then((res) => {
                return res.data;
              }).catch((err) => {
                console.log(err);
              });

              if (response.success) {
                console.log(response, 'response');
                console.log(response.success, 'response');

                alert.success('Timesheet report sent successfully!');
                handleClose();
              }

            }

          } else {
            const { message } = await generateContractorReport({
              viewType,
              startDate,
              endDate,
              emailAddress,
              timesheets,
              contractorFullName,
              contractorEmailAddress,
              reportType,
              isProject,
              projectType: projectType?.value,
              hoursAndMinutesFormat,
            });

            if (message === 'generate_report_successful') {
              handleClose();
            }

          }


        }}
      >
        {({ errors, values, setFieldValue }) => (

          <>
            {reportType === 'Send' && (
              <ControlledInput
                className={
                  styles.GenerateContractorTimesheetReportModal_withMarginBottom
                }
                type={inputTypes.SLIM}
                label="Email Address*"
                name="emailAddress"
                icon="email"
                value={values.emailAddress}
                error={errors.emailAddress}
                onChange={(e) => setFieldValue('emailAddress', e.target.value)}
              />
            )}
            {reportType === 'Send PDF' && (
              <ControlledInput
                className={
                  styles.GenerateContractorTimesheetReportModal_withMarginBottom
                }
                type={inputTypes.SLIM}
                label="Email Address*"
                name="emailAddress"
                icon="email"
                value={values.emailAddress}
                error={errors.emailAddress}
                onChange={(e) => setFieldValue('emailAddress', e.target.value)}
              />
            )}
            {isProject && (
              <ControlledSelect
                className={
                  styles.GenerateContractorTimesheetReportModal_withMarginBottom
                }
                type={selectTypes.SLIM}
                label="Type"
                options={[
                  {
                    label: 'Complete',
                    value: 'Complete',
                  },
                  {
                    label: 'Only Billable',
                    value: 'Billable',
                  },
                  {
                    label: 'Only Non-billable',
                    value: 'Non-billable',
                  },
                ]}
                name="type"
                value={values.projectType}
                onChange={(val) => setFieldValue('projectType', val)}
              />
            )}

            <Text className={styles.GenerateTimesheetReportModal_withMarginTop}>*If you leave the dates blank it will download time timesheets according to your current view</Text>

            <div style={{ marginTop: "20px" }}></div>

            <DatePicker
              className={styles.GenerateTimesheetReportModal_withMarginTop}
              type={inputTypes.SLIM}
              label="From*"
              dateFormat="dd/MM/yyyy"
              selected={values.from}
              name="from"
              icon="today"
              error={errors.from}
              onChange={(date) => {
                setFieldValue('from', date);
                setFromDate(dateFormat(new Date(date), 'yyyy-mm-dd'),);
              }}
              disableFutureDates
            />

            <div style={{ marginTop: "20px" }}></div>

            <DatePicker
              className={styles.GenerateTimesheetReportModal_withMarginTop}
              type={inputTypes.SLIM}
              label="To*"
              dateFormat="dd/MM/yyyy"
              selected={values.to}
              name="to"
              icon="today"
              error={errors.to}
              onChange={(date) => {
                setFieldValue('to', date);
                setToDate(dateFormat(new Date(date), 'yyyy-mm-dd'),);
              }}
              minDate={values.from}
              disableFutureDates
            />
            <div style={{ marginTop: "20px" }}></div>

            <ControlledSelect
              type={selectTypes.SLIM}
              label={reportType === 'Send' ? "Select Send Type" : "Select Download Type"}
              options={[
                {
                  value: "excel",
                  label: "Excel",
                },
                {
                  value: "pdf",
                  label: "PDF",
                }
              ]}
              name="selectedDownloadType"
              value={values.downloadType}
              onChange={(val) => setFieldValue('downloadType', val)}
              error={errors.downloadType}
            />
            <div style={{ marginTop: "20px" }}></div>

            <Text
              type={textTypes.HEADING.XXS}
              className={
                styles.GenerateContractorTimesheetReportModal_totalIncomeEarned
              }
            >
              Total Income Earned
              <span
                className={
                  styles.GenerateContractorTimesheetReportModal_totalIncomeEarned_amount
                }
              >
                ${totalIncomeEarned.toFixed(2)}
              </span>
            </Text>
          </>
        )}
      </Formik>
    </Modal>
  );
};

GenerateContractorTimesheetReportModal.defaultProps = {
  isProject: false,
};

GenerateContractorTimesheetReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reportType: PropTypes.oneOf(['Download', 'Send', 'Send PDF']).isRequired,
  viewType: PropTypes.oneOf(Object.values(timesheetTypes)).isRequired,
  startDate: PropTypes.string.isRequired, // YYYY-MM-DD
  endDate: PropTypes.string.isRequired, // YYYY-MM-DD
  timesheets: PropTypes.array.isRequired,
  contractorFullName: PropTypes.string.isRequired,
  contractorEmailAddress: PropTypes.string.isRequired,
  isProject: PropTypes.bool,
  hoursAndMinutesFormat: PropTypes.bool,
};

export default GenerateContractorTimesheetReportModal;
