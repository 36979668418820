import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import { ControlledInput, ControlledTextArea, Modal } from '../../elements';
import { buttonTypes } from '../../../globals';
import { UserContext } from '../../../contexts';
import { MiscService } from '../../../services';

const validate = (values) => {
  const errors = {};

  if (!values.subject) {
    errors.subject = 'This field is required.';
  } else if (values.subject.length > 60) {
    errors.subject = 'The maximum length of this field is 60 characters.';
  }

  if (!values.message) {
    errors.message = 'This field is required.';
  } else if (values.message.length > 500) {
    errors.message = 'The maximum length of this field is 500 characters.';
  }

  return errors;
};

const ContactUsModal = ({ isOpen, handleClose, title }) => {
  const { user } = useContext(UserContext);
  const alert = useAlert();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef();

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      actions={[
        {
          text: 'Submit',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isSubmitting,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          subject: '',
          message: '',
        }}
        onSubmit={async (values, { setErrors, resetForm }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          const currentFormValues = {
            name: user.fullName,
            emailAddress: user.emailAddress,
            userType: user.userType,
            phoneNumber: user.phoneNumber,
            subject: values.subject,
            message: values.message,
          };

          setIsSubmitting(true);

          // Check if user exists with the inputted emailAddress
          const { data: contactUsResponse } = await MiscService.contactUs(
            currentFormValues
          );

          // Close the modal
          handleClose();

          if (contactUsResponse.message === 'message_sent') {
            // Show an alert
            alert.success('Message sent successfully!');
          } else {
            // Show an alert
            alert.error('Oops, something went wrong.');
          }

          setIsSubmitting(false);
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <>
            <ControlledInput
              className={styles.ContactUsModal_withMarginBottom}
              name="subject"
              placeholder="Subject*"
              value={values.subject}
              error={errors.subject}
              onChange={(e) => setFieldValue('subject', e.target.value)}
            />

            <ControlledTextArea
              name="message"
              placeholder="Message*"
              value={values.message}
              error={errors.message}
              onChange={(e) => setFieldValue('message', e.target.value)}
            />
          </>
        )}
      </Formik>

      <a
        href="https://youtube.com/channel/UC9r9rDSONSEAHPMA9Mokj6w"
        className={styles.ContactUsModal_tutorialLink}
        target="_blank"
        rel="noreferrer"
      >
        View our tutorials here
      </a>
    </Modal>
  );
};

ContactUsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContactUsModal;
