import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import { useAlert } from 'react-alert';
import styles from './styles.module.scss';

import { SubscriptionCard, SweetAlert } from '../../../../components';
import { ControlledInput, Button, Text } from '../../../../components/elements';
import {
  buttonTypes,
  colorClasses,
  inputTypes,
  subscriptionPlans,
  textTypes,
} from '../../../../globals';
import { newSubscriptionPlan,oldSubscriptionPlan } from '../../Subscription/SubscriptionPlans';
import { UserContext } from '../../../../contexts';
import { SubscriptionsService } from '../../../../services';
import { dateTimeIsPast } from '../../../../utils/datetime';
import moment from 'moment';

const Subscription = () => {
  const alert = useAlert();
  const { user, loginUpdate } = useContext(UserContext);
  const [showCancelAlert, setShowCancelAlert] = useState(false);

  let updatedSubscriptionPlan = oldSubscriptionPlan;
  const userRegisterDate = moment(user?.dateRegistered?._seconds * 1000).format('Y-MM-DD');
  if(user.dateRegistered !== undefined && userRegisterDate > moment('2022-11-01').format('Y-MM-DD')){
    updatedSubscriptionPlan = newSubscriptionPlan;
  }

  // Define constants
  const currentSubscriptionPlan = updatedSubscriptionPlan.find(
    (plan) => user?.subscriptionPlanId === plan.id
  );

  const subscriptionEndDate = user?.subscriptionEndDate
    ? dateFormat(new Date(user?.subscriptionEndDate), 'mmmm d, yyyy')
    : null;

  const subscriptionPlan = updatedSubscriptionPlan.find(
    (plan) => user.subscriptionPlanId === plan.id
  );

  return (
    <div className={styles.Subscription}>
      <Text type={textTypes.HEADING.SM} className={styles.Subscription_title}>
        Subscription
      </Text>

      {!dateTimeIsPast(user.subscriptionTrialEndDate) && (
        <div className={styles.Subscription_alert}>
          <Text
            type={textTypes.HEADING.XXXS}
            colorClass={colorClasses.NEUTRAL[0]}
          >
            Your free trial will end on{' '}
            {dateFormat(
              new Date(user?.subscriptionTrialEndDate),
              'mmmm d, yyyy'
            )}
            . Click{' '}
            <Link
              to="/employer/subscription"
              className={styles.Subscription_alert_link}
            >
              here
            </Link>{' '}
            to select a subscription plan now.
          </Text>
        </div>
      )}

      <Text
        type={textTypes.HEADING.XXXS}
        className={styles.Subscription_withMarginBottom}
      >
        {subscriptionEndDate && (
          <>
            {user?.subscriptionStatus === 'active'
              ? `Your next billing date is on ${subscriptionEndDate}.`
              : `You can still use TimeSheet until ${subscriptionEndDate}.`}
          </>
        )}
      </Text>

      <ControlledInput
        className={styles.Subscription_withMarginBottom}
        type={inputTypes.SLIM}
        label="Number of Employees Allowed To Have"
        value={user.subscriptionPlanQuantity}
        disabled
      />

      {dateTimeIsPast(user.subscriptionTrialEndDate) && user.isSubscribed && (
        <>
          <Text className={styles.Subscription_label}>
            {user?.subscriptionStatus !== 'canceled' ? 'Active' : 'Previous'}{' '}
            Subscription Plan -{' '}
            <b>
              {subscriptionPlan &&
                `${subscriptionPlan?.timeInterval.toUpperCase()}`}
            </b>
            <Link
              to="/employer/subscription"
              className={styles.Subscription_changePlanLink}
            >
              {user?.subscriptionStatus !== 'canceled'
                ? 'Change'
                : 'Activate a'}{' '}
              Plan
            </Link>
          </Text>
          <SubscriptionCard
            planName={subscriptionPlan?.name}
            planDescription={subscriptionPlan?.description}
            planPrice={subscriptionPlan?.price}
            isCurrentPlan
          />

          {user?.subscriptionStatus !== 'canceled' && (
            <>
              <Button
                type={buttonTypes.PRIMARY.RED}
                className={styles.Subscription_cancelSubscriptionButton}
                onClick={() => setShowCancelAlert(true)}
              >
                Cancel Subscription
              </Button>

              <SweetAlert
                show={showCancelAlert}
                title="Are you sure?"
                onConfirm={async () => {
                  const { data: cancelSubscriptionResponse } =
                    await SubscriptionsService.cancelSubscription({
                      previousSubscriptionPlanName:
                        currentSubscriptionPlan?.name,
                      previousSubscriptionPlanInterval:
                        currentSubscriptionPlan?.timeInterval,
                      previousSubscriptionPlanQuantity:
                        user.subscriptionPlanQuantity,
                      subscriptionId: user?.subscriptionId,
                      customerFullName: user.fullName,
                      customerEmailAddress: user?.emailAddress,
                      customerCompanyName: user.companyName,
                    });

                  if (
                    cancelSubscriptionResponse.message ===
                    'subscription_canceled'
                  ) {
                    loginUpdate({ ...user, subscriptionStatus: 'canceled' });

                    alert.success('Subscription Cancelled!');
                  } else {
                    alert.error('Oops, something went wrong.');
                  }

                  setShowCancelAlert(false);
                }}
                onCancel={() => setShowCancelAlert(false)}
              >
                Cancellation will be effective at the end of your current
                billing period on {subscriptionEndDate}.
              </SweetAlert>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Subscription;
