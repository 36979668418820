import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Formik } from 'formik';
import ReactTooltip from 'react-tooltip';
import dateFormat from 'dateformat';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';
import swal from 'sweetalert';
import Preloader from './Preloader';
import { Breadcrumbs } from '../../../components';
import {
  Button,
  Card,
  ControlledInput,
  ControlledSelect,
  ControlledTextArea, 
  DatePicker,
  Icon,
  InputMask,
  TimePicker,
  KeyboardTimePicker,
  SweetAlert,
  Spinner,
  Text,
  IconButton,
  Label,
  HoursPicker
} from '../../../components/elements';
import {
  buttonKinds,
  colorClasses,
  colorNames,
  leaveTypes,
  spinnerSizes,
  textTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import {
  useEmployees,
  useCreateTimesheet,
  useClient,
  useProject,
  useClients,
  useProjects,
} from '../../../hooks';
import { TimesheetsService } from '../../../services';
import {
  checkIfTimeIsValid,
  convertTimeTo24HourFormat,
  convertDateTimeToTimestamp,
  hoursWithDecimalToHoursAndMinutes,
  timeMask,
  momentTo24HourTime,
  convertTimeTo12HourFormat,
  createMomentWithDummyDate,
  hourToDecimal,
} from '../../../utils/datetime';
import { getHours, isTimesheetTimesValid } from '../../../utils/timesheets';
import NoResults from '../../../static/images/Misc/no-results.webp';
import useGetEmployee from '../../../hooks/useGetEmployee';
import moment from 'moment/moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { now } from 'lodash';
import dayjs from 'dayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker'
// import { TimePicker } from '../../../components/elements/HoursPicker';

const validate = (values) => {
  const errors = {};

  if (!values.date) {
    errors.date = 'This field is required.';
  }

  if (values.leaveType.value === leaveTypes.NONE) {
    if (!values.startTime) {
      errors.startTime = 'This field is required.';
    } else if (!checkIfTimeIsValid(convertTimeTo12HourFormat(momentTo24HourTime(values.startTime)))) {
      errors.startTime = 'Invalid time format.';
    }

    if (!values.endTime) {
      errors.endTime = 'This field is required.';
    } else if (!checkIfTimeIsValid(convertTimeTo12HourFormat(momentTo24HourTime(values.endTime)))) {
      errors.endTime = 'Invalid time format.';
    }
    if (values.isLunchBreak.value && !values.lunchBreakDuration) {
      errors.lunchBreakDuration = 'This field is required.';
    }
    if(values.isLunchBreak && values.lunchBreakDuration){
      if(!checkIfTimeIsValid(convertTimeTo12HourFormat(values.lunchBreakDuration))){
          errors.lunchBreakDuration = 'Invalid time format.';
        }
    }
  }
  return errors;
};


const CreateTimesheet = ({ pageTitle, pages }) => {
  let { clientId, projectId } = useParams();
  const alert = useAlert();
  const { user } = useContext(UserContext);
  const getEmployeeDetail = useGetEmployee({
    emailAddress: user.emailAddress,
  });

  const [isSettingInitialValues, setIsSettingInitialValues] = useState(true);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [projectsOptions, setProjectsOptions] = useState([]);
  const [publicHolidayMultiplier, setPublicHolidayMultiplier] = useState(1);
  const [hourlyWage, setHourlyWage] = useState(0);
  const [hours, setHours] = useState(0);

  const [totalIncome, setTotalIncome] = useState(0);
  const [addCost, setAddCost] = useState([]);
  const [costItemName, setCostItemName] = useState('');
  const [costItemPrice, setCostItemPrice] = useState(0);

  const { isLoading: isEmployeesLoading, employees } = useEmployees({
    employerId: user.id,
  });
  const [clientValue, setClientValue] = useState(undefined);
  const [clientName, setClientName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectValue, setProjectValue] = useState(undefined);
  const { isLoading: isClientsLoading, clients } = useClients({
    userId: user.id,
  });
  const selectedClient = clients.findIndex(val => val.id === clientId);
  const { isLoading: isProjectsLoading, projects } = useProjects({
    clientId: clientValue,
    isFromCreateTimeSheet: true,
  })
  const selectedProject = projects.findIndex(val => val.id === projectId);

  if (clientValue !== null && clientValue !== undefined) {
    clientId = clientValue;
  }
  if (projectValue !== null && projectValue !== undefined) {
    projectId = projectValue;
  }
  const { isLoading: isClientLoading, client } = useClient({
    clientId,
  });
  const { isLoading: isProjectLoading, project } = useProject({
    clientId,
    projectId,
  });
  const { isCreating: isTimesheetCreating, createTimesheet } =
    useCreateTimesheet();

  const getPublicHolidayMultiplier = (employeeId) => {
    const employee = employees.find((emp) => emp.id === employeeId);

    return employee.employerDetails.publicHolidayMultiplier;
  };
  const onClickAddCost = (event) => {
    if (costItemName !== '') {
      setAddCost(addCost.concat({
        itemName: costItemName,
        price: costItemPrice || "0",
      }));
    }
  };
  const removeCost = (index) => {
    const newCost = addCost.filter((_, i) => i !== index);
    setAddCost(newCost);
  };


  const updateHours = (
    date,
    startTime,
    endTime,
    isLunchBreak = false,
    lunchBreakDuration = "00:00",
    isTravelCharge = false,
    leaveType = 'None',
    multiplier,
    setFieldValue,
  ) => {
    // Set form field values depending on leaveType
  
    startTime = convertTimeTo12HourFormat(momentTo24HourTime(startTime));
    endTime = convertTimeTo12HourFormat(momentTo24HourTime(endTime));

    if (
      leaveType === leaveTypes.ANNUAL ||
      leaveType === leaveTypes.WITHOUT_PAY
    ) {
      setFieldValue('startTime', undefined);
      setFieldValue('endTime', undefined);
      setFieldValue(
        'isLunchBreak',
        user.isLunchBreakByDefault
          ? { label: 'Yes', value: true }
          : {
            label: 'No',
            value: false,
          }
      );
      setFieldValue(
        'isTravelCharge',
        user.isTravelChargeByDefault
          ? { label: 'Yes', value: true }
          : {
            label: 'No',
            value: false,
          }
      );
      const tempHours = getHours(null, null, null, null, leaveType);
      setHours(tempHours);

      setTotalIncome(hourlyWage * tempHours);

      return;
    }

    // Set the hours depending on certain conditions
    if (date && checkIfTimeIsValid(startTime) && checkIfTimeIsValid(endTime)) {
      const tempHours = getHours(
        convertDateTimeToTimestamp(date, convertTimeTo24HourFormat(startTime)),
        convertDateTimeToTimestamp(date, convertTimeTo24HourFormat(endTime)),
        isLunchBreak,
        lunchBreakDuration,
        isTravelCharge,
        leaveType,
        multiplier,
       
      );
      setHours(tempHours);

      setTotalIncome(hourlyWage * tempHours);
    } else if (
      leaveType === leaveTypes.PUBLIC_HOLIDAY ||
      leaveType === leaveTypes.SICK
    ) {
      const tempHours = getHours(null, null, null, null, leaveType);

      setHours(tempHours);
      setTotalIncome(hourlyWage * tempHours);
    } else {
      setHours(0);
      setTotalIncome(0);
    }
  };
  // Generate employee options
  useEffect(() => {
    if (!isEmployeesLoading) {
      const options = [];
      employees.forEach(({ id, fullName, employerDetails: { wage } }) => {
        const option = {
          label: fullName,
          value: id,
          hourlyWage: wage,
        };
        options.push(option);
      });

      setEmployeeOptions(options);
    }
  }, [isEmployeesLoading]);
  // Generate clients options
  useEffect(() => {
    if (!isClientsLoading) {
      const options = [];
      clients.forEach(({ id, name }) => {
        const option = {
          label: name,
          value: id,
        };
        options.push(option);
      });

      setClientsOptions(options);
      if (clientId !== undefined) {
        setClientValue(clients[selectedClient].id);
      }
    }
  }, [isClientsLoading]);
  // Generate projects options
  useEffect(() => {
    if (!isProjectsLoading) {
      const options = [];
      if (projects.length > 0) {
        projects.forEach(({ id, name, isFinshed }) => {
          const option = {
            label: name,
            value: id,
          };
          if (isFinshed === undefined) {
            options.push(option);
          } else if (isFinshed !== undefined && !isFinshed) {
            options.push(option);
          }
        });
      }
      setProjectsOptions(options);

    }
  }, [isProjectsLoading]);
  // Set default public holiday multiplier
  // and hourly wage (first employee)
  useEffect(() => {
    if (employeeOptions.length) {
      setPublicHolidayMultiplier(
        getPublicHolidayMultiplier(employeeOptions[0].value)
      );
      setHourlyWage(employeeOptions[0].hourlyWage);
      setIsSettingInitialValues(false);
    }

  }, [employeeOptions]);
  return (
    <div className={styles.CreateTimesheet}>
      <Breadcrumbs pageTitle={pageTitle} pages={pages} />

      {isEmployeesLoading ||
        isClientLoading ||
        isProjectLoading ||
        isSettingInitialValues ? (
        <Preloader />
      ) : (
        <>
          {employees.length > 0 ? (
            <Card className={styles.CreateTimesheet_card}>
              <Formik
                initialValues={{
                  employee: employeeOptions[0],
                  client: clientId !== undefined ? clientsOptions[selectedClient] : undefined,
                  project: projectId !== undefined ? projectsOptions[selectedProject] : undefined,
                  date: new Date(),
                  startTime: undefined,
                  endTime: undefined,
                  isLunchBreak: user.isLunchBreakByDefault
                    ? { label: 'Yes', value: true }
                    : {
                      label: 'No',
                      value: false,
                    },
                  lunchBreakDuration: undefined,
                  isTravelCharge: {
                    label: 'No',
                    value: false,
                  },
                  leaveType: {
                    label: leaveTypes.NONE,
                    value: leaveTypes.NONE,
                  },
                  notes: undefined,
                  performedNotes: undefined,
                  isBillable: {
                    label: 'Yes',
                    value: true,
                  },
                  costs: [],

                }}
                onSubmit={async (values, { setErrors, setFieldValue }) => {
               
                  const errors = validate(values);
                  if (!isEmpty(errors)) {
                    setErrors(errors);
                    return;
                  }

                  const newTimesheet = {
                    userId: values.employee.value,
                    employerId: user.id,
                    date: dateFormat(values.date, 'yyyy-mm-dd'),
                    leaveType: values.leaveType.value,
                    publicHolidayMultiplier,
                    notes: values.notes,
                    performedNotes: values.performedNotes,
                    clientId: clientId || undefined,
                    clientName: clientName || client?.name || undefined,
                    projectId: projectId || undefined,
                    projectName: projectName || project?.name || undefined,
                    isBillable: projectId ? values.isBillable.value : undefined,
                    wage: hourlyWage || 0,
                    costs: addCost || [],
                  };

                  if (
                    values.leaveType.value === leaveTypes.NONE ||
                    values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                    values.leaveType.value === leaveTypes.SICK
                  ) {
                    // Add necessary properties depending on leaveType
                    newTimesheet.startTimeDetails = {
                      startTime: convertTimeTo12HourFormat(momentTo24HourTime(values.startTime)),
                    };
                    newTimesheet.endTimeDetails = {
                      endTime: convertTimeTo12HourFormat(momentTo24HourTime(values.endTime)),
                    };
                    newTimesheet.isLunchBreak = values.isLunchBreak.value;
                    newTimesheet.lunchBreakDuration = values.lunchBreakDuration;
                    newTimesheet.isTravelCharge = values.isTravelCharge.value;
                  }
                  // Check if times of this timesheet is not overlapping
                  // any times in timesheets array
                  const { data: getTimesheetsResponse } =
                    await TimesheetsService.get({
                      userId: newTimesheet.userId,
                      startDate: newTimesheet.date,
                      endDate: newTimesheet.date,
                    });
                  const { timesheets } = getTimesheetsResponse;
                  const startTime = convertTimeTo12HourFormat(momentTo24HourTime(values.startTime));

                  const endTime = convertTimeTo12HourFormat(momentTo24HourTime(values.endTime));

                  const diffMins = moment.utc(endTime, 'HH:mm a').diff(moment.utc(startTime, 'HH:mm a'), 'minutes');
                
                  const lunchTime = values.lunchBreakDuration;
                  const lunchHour = moment.utc(lunchTime, 'hh:mm a').get('hour');
                  const lunchMinutes = moment.utc(lunchTime, 'hh:mm a').get('minute');
                  const totalMinutes = (lunchHour * 60) + lunchMinutes

                  
                  if ((startTime === endTime) &&
                    (values.leaveType.value === leaveTypes.NONE ||
                      values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                      values.leaveType.value === leaveTypes.SICK)) {
                    alert.error(
                      'Oops, start and end time cannot be the same'
                    );
                    return;
                  }
                  if ((Math.abs(diffMins) < 15) &&
                    (values.leaveType.value === leaveTypes.NONE ||
                      values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                      values.leaveType.value === leaveTypes.SICK)) {
                    alert.error(
                      'Oops, please record a timesheet for at least 15 minutes'
                    );
                    return;
                  }
                  if ((( diffMins > 0 && Math.abs(diffMins) <= totalMinutes) || totalMinutes > 600 || totalMinutes === 0) &&
                    (values.leaveType.value === leaveTypes.NONE ||
                      values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                      values.leaveType.value === leaveTypes.SICK)) {
                    alert.error(
                      'Oops, please select a valid lunch time'
                    );
                    return;
                  }
                  // if (
                  //   values.startTime &&
                  //   values.endTime &&
                  //   !isTimesheetTimesValid(timesheets, {
                  //     startTime: convertTimeTo12HourFormat(values.startTime),
                  //     endTime: convertTimeTo12HourFormat(values.endTime),
                  //   }) &&
                  //   (values.leaveType.value === leaveTypes.NONE ||
                  //     values.leaveType.value === leaveTypes.PUBLIC_HOLIDAY ||
                  //     values.leaveType.value === leaveTypes.SICK)
                  // ) {
                  //   alert.error(
                  //     'Oops, the time/s you have inputted overlaps an existing timesheet.'
                  //   );
                  //   return;
                  // }
                  if (getEmployeeDetail.employee?.isNoteNeeded) {
                    if (getEmployeeDetail.employee?.isWorkPerformed && (newTimesheet.notes === undefined || newTimesheet.performedNotes === undefined)) {
                      swal({
                        title: "Notes needed",
                        text: "You must have to add a notes",
                        icon: "warning"
                      });
                      return;
                    }
                    if (!getEmployeeDetail.employee?.isWorkPerformed && newTimesheet.notes === undefined) {
                      swal({
                        title: "Notes needed",
                        text: "You must have to add a notes",
                        icon: "warning"
                      });
                      return;
                    }
                  }
                  if (getEmployeeDetail.employee?.isProjectClient && (newTimesheet.clientId === undefined || newTimesheet.projectId === undefined)) {
                    swal({
                      title: "Are you sure?",
                      text: "You haven’t added a timesheet to a project/client are you sure you want to continue",
                      icon: "warning",
                      buttons: ["No", "Yes"],
                    }).then(async (ok) => {
                      if (ok) {
                        const { responseCode: createTimesheetResponseCode } =
                          await createTimesheet(newTimesheet);

                        const createTimesheetCallbacks = {
                          created: () => {
                            alert.success('Timesheet created!');

                            // Reset the form to its initial state
                            setFieldValue('employee', employeeOptions[0]);
                            setFieldValue('client', clientId !== undefined ? clientsOptions[selectedClient] : undefined);
                            setFieldValue('project', projectId !== undefined ? projectsOptions[selectedProject] : undefined);
                            setFieldValue('startTime', undefined);
                            setFieldValue('endTime', undefined);
                            setFieldValue(
                              'isLunchBreak',
                              user.isLunchBreakByDefault
                                ? { label: 'Yes', value: true }
                                : {
                                  label: 'No',
                                  value: false,
                                }
                            );
                            setFieldValue('lunchBreakDuration', undefined);
                            setFieldValue(
                              'isTravelCharge', {
                              label: 'No',
                              value: false,
                            }
                            );
                            setFieldValue('leaveType', {
                              label: leaveTypes.NONE,
                              value: leaveTypes.NONE,
                            });
                            setFieldValue('notes', undefined);
                            setFieldValue('performedNotes', undefined);
                            setFieldValue('itemName', '');
                            setFieldValue('price', '');
                            setHourlyWage(employeeOptions[0]?.hourlyWage);
                            setHours(0);
                            setTotalIncome(0);
                            setAddCost([]);
                          },
                          invalidFields: () => {
                            if (typeof newTimesheet.wage !== "number") {
                              alert.error("can't include text in wage")
                            } else {
                              alert.error('Invalid fields.')
                            }
                          },
                          internalError: () =>
                            alert.error('Oops, something went wrong.'),
                        };

                        switch (createTimesheetResponseCode) {
                          case 200:
                            createTimesheetCallbacks.created();
                            break;
                          case 400:
                            createTimesheetCallbacks.invalidFields();
                            break;
                          case 500:
                            createTimesheetCallbacks.internalError();
                            break;
                          default:
                            break;
                        }
                      }
                    });
                  } else {
                    const { responseCode: createTimesheetResponseCode } =
                      await createTimesheet(newTimesheet);

                    const createTimesheetCallbacks = {
                      created: () => {
                        alert.success('Timesheet created!');

                        // Reset the form to its initial state
                        setFieldValue('employee', employeeOptions[0]);
                        setFieldValue('client', clientId !== undefined ? clientsOptions[selectedClient] : undefined);
                        setFieldValue('project', projectId !== undefined ? projectsOptions[selectedProject] : undefined);
                        setFieldValue('startTime', undefined);
                        setFieldValue('endTime', undefined);
                        setFieldValue(
                          'isLunchBreak',
                          user.isLunchBreakByDefault
                            ? { label: 'Yes', value: true }
                            : {
                              label: 'No',
                              value: false,
                            }
                        );
                        setFieldValue('lunchBreakDuration', undefined);
                        setFieldValue(
                          'isTravelCharge', {
                          label: 'No',
                          value: false,
                        }
                        );
                        setFieldValue('leaveType', {
                          label: leaveTypes.NONE,
                          value: leaveTypes.NONE,
                        });
                        setFieldValue('notes', undefined);
                        setFieldValue('performedNotes', undefined);
                        setFieldValue('itemName', '');
                        setFieldValue('price', '');
                        setHourlyWage(employeeOptions[0]?.hourlyWage);
                        setHours(0);
                        setTotalIncome(0);
                        setAddCost([]);
                      },
                      invalidFields: () => alert.error('Invalid fields.'),
                      internalError: () =>
                        alert.error('Oops, something went wrong.'),
                    };

                    switch (createTimesheetResponseCode) {
                      case 200:
                        createTimesheetCallbacks.created();
                        break;
                      case 400:
                        createTimesheetCallbacks.invalidFields();
                        break;
                      case 500:
                        createTimesheetCallbacks.internalError();
                        break;
                      default:
                        break;
                    }
                  }
                }}

              >
                {({ errors, values, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <ControlledSelect
                      className={styles.CreateTimesheet_withMarginDrop}
                      options={employeeOptions}
                      name="employee"
                      placeholder="Employee*"
                      value={values.employee}
                      error={errors.employee}
                      onChange={(val) => {
                        setFieldValue('employee', {
                          label: val.label,
                          value: val.value,
                          hourlyWage: val.hourlyWage,
                        });

                        setPublicHolidayMultiplier(
                          getPublicHolidayMultiplier(val.value)
                        );

                        setHourlyWage(val.hourlyWage);

                        updateHours(
                          values.date,
                          values.startTime,
                          values.endTime,
                          values.isLunchBreak.value,
                          values.lunchBreakDuration,
                          values.isTravelCharge.value,
                          values.leaveType.value,
                          getPublicHolidayMultiplier(val.value),
                          setFieldValue
                        );
                      }}
                    />
                    <ControlledSelect
                      className={styles.CreateTimesheet_withMarginDrop}
                      options={clientsOptions}
                      name="client"
                      placeholder="Client"
                      value={values.client}
                      error={errors.client}
                      onChange={(val) => {
                        setFieldValue('client', val);
                        setClientValue(val.value.toString());
                        setClientName(val.label);
                        setFieldValue('project', "");
                        setProjectValue("");
                        setProjectName("");
                      }}
                    />

                    <ControlledSelect
                      className={styles.CreateTimesheet_withMarginDrop}
                      options={projects.map((project) => ({
                        value: project.id,
                        label: project.name,
                      }))}
                      name="project"
                      placeholder="Project"
                      value={values.project}
                      error={errors.project}
                      onChange={(val) => {
                        setFieldValue('project', val);
                        setProjectValue(val.value);
                        setProjectName(val.label);
                      }}
                    />
                    <DatePicker
                      className={styles.CreateTimesheet_withMargin}
                      dateFormat="dd/MM/yyyy"
                      selected={values.date}
                      placeholder="Date*"
                      name="date"
                      icon="today"
                      onChange={(date) => {
                        setFieldValue('date', date);
                        updateHours(
                          date,
                          values.startTime,
                          values.endTime,
                          values.isLunchBreak.value,
                          values.lunchBreakDuration,
                          values.isTravelCharge.value,
                          values.leaveType.value,
                          publicHolidayMultiplier,
                          setFieldValue
                        );
                      }}
                    />
                    {/* <div className={styles.CreateTimesheet_withMargin}>
                      <Text
                        type={textTypes.BODY.MD}
                      >
                        Start Time *
                      </Text>
                      <TimePicker
                        time={values?.startTime ? createMomentWithDummyDate(values.startTime) : null}
                        onChange={(newTime) => {
                          setFieldValue('startTime', momentTo24HourTime(newTime),
                            updateHours(
                              values.date,
                              momentTo24HourTime(newTime),
                              values.endTime,
                              values.isLunchBreak.value,
                              values.isTravelCharge.value,
                              values.leaveType.value,
                              publicHolidayMultiplier,
                              setFieldValue
                            ))
                        }
                        }
                        error={errors.startTime}
                        clearable
                        icon="schedule"
                        placeholder="Start Time*"
                        disabled={
                          values.leaveType.value === leaveTypes.ANNUAL ||
                          values.leaveType.value === leaveTypes.WITHOUT_PAY
                        }
                      />
                    </div>
                    <div className={styles.CreateTimesheet_withMargin}>
                      <Text
                        type={textTypes.BODY.MD}
                      >
                        End Time *
                      </Text>
                      <TimePicker
                        time={values?.endTime ? createMomentWithDummyDate(values.endTime) : null}
                        onChange={(newTime) => {
                          setFieldValue('endTime', momentTo24HourTime(newTime),
                            updateHours(
                              values.date,
                              values.startTime,
                              momentTo24HourTime(newTime),
                              values.isLunchBreak.value,
                              values.isTravelCharge.value,
                              values.leaveType.value,
                              publicHolidayMultiplier,
                              setFieldValue
                            ));

                        }
                        }
                        error={errors.endTime}
                        clearable
                        placeholder="End Time*"
                        icon="schedule"
                        disabled={
                          values.leaveType.value === leaveTypes.ANNUAL ||
                          values.leaveType.value === leaveTypes.WITHOUT_PAY
                        }
                      />
                    </div> */}
                    <div className={styles.CreateTimesheet_withMargin}>
                      <Text
                        type={textTypes.BODY.MD}
                      >
                        Start Time *
                      </Text>
                      <KeyboardTimePicker
                        time={values?.startTime}
                        onChange={(newTime) => {
                          setFieldValue('startTime', newTime,
                            updateHours(
                              values.date,
                              newTime,
                              values.endTime,
                              values.isLunchBreak.value,
                              values.lunchBreakDuration,
                              values.isTravelCharge.value,
                              values.leaveType.value,
                              publicHolidayMultiplier,
                              setFieldValue
                            )
                          )
                        }
                        }
                        ampm={getEmployeeDetail.employee?.is24Hour ? false : true}
                        mask={getEmployeeDetail.employee?.is24Hour ? "__:__" : "__:__ _M"}
                        error={errors.startTime}
                        clearable
                        disabled={
                          values.leaveType.value === leaveTypes.ANNUAL ||
                          values.leaveType.value === leaveTypes.WITHOUT_PAY
                        }
                      />
                    </div>
                    <div className={styles.CreateTimesheet_withMargin}>
                      <Text
                        type={textTypes.BODY.MD}
                      >
                        End Time *
                      </Text>
                      <KeyboardTimePicker
                        time={values?.endTime}
                        onChange={(newTime) => {
                          setFieldValue('endTime', newTime,
                            updateHours(
                              values.date,
                              values.startTime,
                              newTime,
                              values.isLunchBreak.value,
                              values.lunchBreakDuration,
                              values.isTravelCharge.value,
                              values.leaveType.value,
                              publicHolidayMultiplier,
                              setFieldValue
                            ));
                        }
                        }
                        ampm={getEmployeeDetail.employee?.is24Hour ? false : true}
                        mask={getEmployeeDetail.employee?.is24Hour ? "__:__" : "__:__ _M"}
                        error={errors.endTime}
                        clearable
                        disabled={
                          values.leaveType.value === leaveTypes.ANNUAL ||
                          values.leaveType.value === leaveTypes.WITHOUT_PAY
                        }
                      />
                    </div>
                    {/* <InputMask
                      className={styles.CreateTimesheet_withMargin}
                      mask={timeMask(values.startTime)}
                      maskPlaceholder="hh:mm xm"
                      placeholder="Start Time*"
                      name="startTime"
                      icon="schedule"
                      value={values.startTime}
                      error={errors.startTime}
                      onChange={(e) =>
                        setFieldValue('startTime', e.target.value)
                      }
                      onBlur={() => {
                        updateHours(
                          values.date,
                          values.startTime,
                          values.endTime,
                          values.isLunchBreak.value,
                          values.isTravelCharge.value,
                          values.leaveType.value,
                          publicHolidayMultiplier,
                          setFieldValue
                        );
                      }}
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    /> */}

                    {/* <InputMask
                      className={styles.CreateTimesheet_withMargin}
                      mask={timeMask(values.endTime)}
                      maskPlaceholder="hh:mm xm"
                      placeholder="End Time*"
                      name="endTime"
                      icon="schedule"
                      value={values.endTime}
                      error={errors.endTime}
                      onChange={(e) => setFieldValue('endTime', e.target.value)}
                      onBlur={() => {
                        updateHours(
                          values.date,
                          values.startTime,
                          values.endTime,
                          values.isLunchBreak.value,
                          values.isTravelCharge.value,
                          values.leaveType.value,
                          publicHolidayMultiplier,
                          setFieldValue
                        );
                      }}
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    /> */}

                    <ControlledSelect
                      className={styles.CreateTimesheet_withMarginDrop}
                      options={[
                        {
                          label: 'Yes',
                          value: true,
                        },
                        {
                          label: 'No',
                          value: false,
                        },
                      ]}
                      name="isLunchBreak"
                      placeholder="Lunch Break*"
                      value={values.isLunchBreak}
                      error={errors.isLunchBreak}
                      onChange={(val) => {
                        setFieldValue('isLunchBreak', {
                          label: val.label,
                          value: val.value,
                        });
                        setFieldValue('lunchBreakDuration', val.value ? values.lunchBreakDuration : undefined);
                        updateHours(
                          values.date,
                          values.startTime,
                          values.endTime,
                          val.value,
                          values.lunchBreakDuration,
                          values.isTravelCharge.value,
                          values.leaveType.value,
                          publicHolidayMultiplier,
                          setFieldValue
                        );
                      }}
                      disabled={
                        values.leaveType.value === leaveTypes.ANNUAL ||
                        values.leaveType.value === leaveTypes.WITHOUT_PAY
                      }
                    />

                    {
                      values.isLunchBreak.value &&
                      <div className={styles.CreateTimesheet_withMargin}>
                        <Text
                          type={textTypes.BODY.MD}
                        >
                          Lunch Break Duration
                        </Text>
                        {
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <HoursPicker
                              ampm={false}
                              format={'HH:mm'}
                              minTime={dayjs().set('hour', 0).set('minutes',0)}
                              maxTime={dayjs().set('hour', 10).set('minutes',0)}
                              onChange={(time) => {
                                setFieldValue('lunchBreakDuration', moment(new Date(time).getTime()).format('HH:mm'));
                                updateHours(
                                  values.date,
                                  values.startTime,
                                  values.endTime,
                                  values.isLunchBreak.value,
                                  moment(new Date(time).getTime()).format('HH:mm'),
                                  // values.value,
                                  values.isTravelCharge.value,
                                  values.leaveType.value,
                                  publicHolidayMultiplier,
                                  setFieldValue
                                );
                              }}
                              error={errors.lunchBreakDuration}
                              clearable
                              disabled={
                                values.leaveType.value === leaveTypes.ANNUAL ||
                                values.leaveType.value === leaveTypes.WITHOUT_PAY
                              }

                            />
                          </LocalizationProvider>
                        }
                      </div> 
                    }

                    {getEmployeeDetail.employee?.isTravelChargeByDefault &&
                      <ControlledSelect
                        className={styles.CreateTimesheet_withMarginDrop}
                        options={[
                          {
                            label: 'Yes',
                            value: true,
                          },
                          {
                            label: 'No',
                            value: false,
                          },
                        ]}
                        name="isTravelCharge"
                        placeholder="Travel Charge*"
                        value={values.isTravelCharge}
                        error={errors.isTravelCharge}
                        onChange={(val) => {
                          setFieldValue('isTravelCharge', {
                            label: val.label,
                            value: val.value,
                          });

                          updateHours(
                            values.date,
                            values.startTime,
                            values.endTime,
                            values.isLunchBreak.value,
                            values.lunchBreakDuration,
                            val.value,
                            values.leaveType.value,
                            publicHolidayMultiplier,
                            setFieldValue
                          );
                        }}
                        disabled={
                          values.leaveType.value === leaveTypes.ANNUAL ||
                          values.leaveType.value === leaveTypes.WITHOUT_PAY
                        }
                      />
                    }
                    <ControlledSelect
                      className={styles.CreateTimesheet_withMarginDrop}
                      options={[
                        {
                          label: leaveTypes.NONE,
                          value: leaveTypes.NONE,
                        },
                        {
                          label: leaveTypes.ANNUAL,
                          value: leaveTypes.ANNUAL,
                        },
                        {
                          label: leaveTypes.PUBLIC_HOLIDAY,
                          value: leaveTypes.PUBLIC_HOLIDAY,
                        },
                        {
                          label: leaveTypes.SICK,
                          value: leaveTypes.SICK,
                        },
                        {
                          label: leaveTypes.WITHOUT_PAY,
                          value: leaveTypes.WITHOUT_PAY,
                        },
                      ]}
                      name="leaveType"
                      placeholder="Leave Type*"
                      value={values.leaveType}
                      error={errors.leaveType}
                      onChange={(val) => {
                        setFieldValue('leaveType', {
                          label: val.label,
                          value: val.value,
                        });
                        updateHours(
                          values.date,
                          values.startTime,
                          values.endTime,
                          values.isLunchBreak.value,
                          values.lunchBreakDuration,
                          values.isTravelCharge.value,
                          val.value,
                          publicHolidayMultiplier,
                          setFieldValue
                        );
                      }}
                    />

                    <ControlledInput
                      className={styles.CreateTimesheet_withMargin}
                      name="hours"
                      placeholder="Hours"
                      icon="schedule"
                      value={
                        user?.hoursAndMinutesFormat
                          ? hoursWithDecimalToHoursAndMinutes(hours)
                          : `${hours}`
                      }
                      disabled
                    />

                    {projectId && (
                      <ControlledSelect
                        className={styles.CreateTimesheet_withMargin}
                        options={[
                          {
                            label: 'Yes',
                            value: true,
                          },
                          {
                            label: 'No',
                            value: false,
                          },
                        ]}
                        name="isBillable"
                        placeholder="Billable*"
                        value={values.isBillable}
                        error={errors.isBillable}
                        onChange={(val) => {
                          setFieldValue('isBillable', {
                            label: val.label,
                            value: val.value,
                          });
                        }}
                      />
                    )}
                    {getEmployeeDetail.employee?.isWorkPerformed && <ControlledTextArea
                      className={styles.CreateTimesheet_withMargin}
                      name="performedNotes"
                      placeholder="Performed For:"
                      icon="description"
                      value={values.performedNotes}
                      error={errors.performedNotes}
                      onChange={(e) => setFieldValue('performedNotes', e.target.value)}
                    />}
                    <ControlledTextArea
                      className={styles.CreateTimesheet_withMargin}
                      name="notes"
                      placeholder="Work Performed: (issue/resolution)"
                      icon="description"
                      value={values.notes}
                      error={errors.notes}
                      onChange={(e) => setFieldValue('notes', e.target.value)}
                    />

                    {project &&
                      <div className={styles.CreateTimesheet_withCosts}>
                        <Text type={textTypes.HEADING.XXXS}>Costs</Text>
                        <div className={styles.CreateTimesheet_withCostItem}>
                          <ControlledInput
                            className={styles.CreateTimesheet_withMargin}
                            name="itemName"
                            placeholder="Item name"
                            value={values.itemName}
                            error={errors.itemName}
                            onChange={(e) => {
                              setFieldValue('itemName', e.target.value);
                              setCostItemName(e.target.value);
                            }}
                          />
                          <ControlledInput
                            className={styles.CreateTimesheet_withMargin}
                            name="price"
                            placeholder="Price ($)"
                            value={values.price}
                            error={errors.price}
                            onChange={(e) => {
                              setFieldValue('price', e.target.value);
                              setCostItemPrice(e.target.value);
                            }}
                          />
                          <IconButton
                            icon="add"
                            className={cn(
                              styles.CreateTimesheet_withCostItem_action,
                              styles.CreateTimesheet_addNew
                            )}

                            onClick={() => {
                              onClickAddCost();
                              setFieldValue('itemName', '');
                              setFieldValue('price', '');
                              setCostItemName('');
                              setCostItemPrice('');
                            }
                            }
                          />

                        </div>
                        {addCost.length > 0 &&
                          addCost.map((value, index) => (
                            <div key={index} className={styles.CreateTimesheet_withCostDetail}>
                              <Text type={textTypes.HEADING.XXXS}>{value.itemName}</Text>
                              <Text type={textTypes.HEADING.XXXS}>${value.price}</Text>
                              <IconButton
                                icon="delete"
                                className={cn(
                                  styles.CreateTimesheet_withCostDetail_action,
                                  styles.CreateTimesheet_delete
                                )}
                                onClick={() => removeCost(index)}
                              />
                            </div>
                          ))
                        }
                      </div>
                    }

                    {!getEmployeeDetail?.hideWage && (
                      <>
                        <div className={styles.CreateTimesheet_withTooltip}>
                          <Text type={textTypes.HEADING.XXXS}>Hourly Wage</Text>

                          <a
                            className={
                              styles.CreateTimesheet_withTooltip_tooltipLink
                            }
                            data-tip
                            data-for="hourlyWageTooltip"
                          >
                            <Icon
                              icon="help"
                              className={
                                styles.CreateTimesheet_withTooltip_tooltipIcon
                              }
                            />
                          </a>

                          <ReactTooltip
                            className={styles.CreateTimesheet_withTooltip_tooltip}
                            id="hourlyWageTooltip"
                            type="info"
                            effect="solid"
                          >
                            <span>
                              This is your hourly wage and is used to compute for
                              your total income for a timesheet entry.
                              <br />
                              <br />
                              The default value can be set in Settings &gt;&gt;
                              Account Information.
                            </span>
                          </ReactTooltip>
                        </div>

                        <ControlledInput
                          className={styles.CreateTimesheet_withMargin}
                          name="hourlyWage"
                          placeholder="Hourly Wage"
                          value={`${hourlyWage}`}
                          onChange={(e) => {
                            setHourlyWage(e.target.value);
                            setTotalIncome(e.target.value * hours);
                          }}
                        />
                      </>
                    )}

                    <Text
                      type={textTypes.HEADING.XXXS}
                      className={styles.CreateTimesheet_withMargin}
                    >
                      Summary
                    </Text>

                    <div className={styles.CreateTimesheet_summary}>
                      <div className={styles.CreateTimesheet_summary_row}>
                        <div>
                          <Text
                            type={textTypes.BODY.SM}
                            className={styles.CreateTimesheet_summary_label}
                          >
                            Hours
                          </Text>

                          <Text
                            type={textTypes.HEADING.XXS}
                            className={styles.CreateTimesheet_summary_value}
                          >
                            {user?.hoursAndMinutesFormat
                              ? hoursWithDecimalToHoursAndMinutes(hours)
                              : Number(hours).toFixed(2)}
                          </Text>
                        </div>
                      </div>

                      <div className={styles.CreateTimesheet_summary_row}>
                        <div
                          className={styles.CreateTimesheet_summary_row_group}
                        >
                          {!user?.hideWage && (
                            <>
                              <div>
                                <Text
                                  type={textTypes.BODY.SM}
                                  className={styles.CreateTimesheet_summary_label}
                                >
                                  Total Income
                                </Text>

                                <Text
                                  type={textTypes.HEADING.XXS}
                                  colorClass={colorClasses.GREEN['400']}
                                  className={styles.CreateTimesheet_summary_value}
                                >
                                  $
                                  {!Number.isNaN(totalIncome)
                                    ? totalIncome.toFixed(2)
                                    : '0.00'}
                                </Text>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                    </div>

                    <div className={styles.CreateTimesheet_buttonGroup}>
                      <Button
                        className={styles.CreateTimesheet_buttonGroup_button}
                        kind={buttonKinds.SUBMIT}
                        icon="add"
                        disabled={isTimesheetCreating}
                        onClick={() => { }}
                      >
                        <span
                          className={
                            styles.CreateTimesheet_buttonGroup_buttonText
                          }
                        >
                          Create Timesheet
                          {isTimesheetCreating && (
                            <Spinner
                              size={spinnerSizes.XS}
                              colorName={colorNames.WHITE}
                              className={
                                styles.CreateTimesheet_buttonGroup_spinner
                              }
                            />
                          )}
                        </span>
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </Card>
          ) : (
            <div className={styles.CreateTimesheet_noEmployees}>
              <img
                src={NoResults}
                alt="TimeSheet No Employees"
                className={styles.CreateTimesheet_noEmployees_image}
              />

              <Text
                colorClass={colorClasses.RED['400']}
                type={textTypes.HEADING.XS}
              >
                YOU CURRENTLY DON'T HAVE EMPLOYEES.
              </Text>

              <Text
                type={textTypes.HEADING.XXS}
                className={styles.CreateTimesheet_noEmployees_withMargin}
              >
                Add one by clicking the link{' '}
                <Link
                  to="/employer/employees/add"
                  className={styles.CreateTimesheet_noEmployees_link}
                >
                  here
                </Link>
                .
              </Text>
            </div>
          )}
        </>
      )}
    </div>
  );
};

CreateTimesheet.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
};

export default CreateTimesheet;
