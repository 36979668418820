import React from 'react';
import styles from './styles.module.scss';

import { Card, Shine } from '../../../../../components/elements';

const PreloaderSmall = () => (
  <Card className={styles.PreloaderSmall}>
    <Shine className={styles.PreloaderSmall_shine} />
  </Card>
);

export default PreloaderSmall;
