import React, { useState, useContext, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import dateFormat from 'dateformat';
import styles from './styles.module.scss';

import { Breadcrumbs, TimesheetGrid } from '../../../components';
import {
  Button,
  ButtonLink,
  ControlledSelect,
  Text,
} from '../../../components/elements';
import {
  GenerateTimesheetReportModal,
  TimesheetsToXeroModals,
} from '../../../components/modals';
import { TimesheetContext, UserContext } from '../../../contexts';
import {
  useEmployees,
  useRosters,
  useTimesheets,
  useWindowSize,
} from '../../../hooks';
import {
  buttonTypes,
  colorClasses,
  timesheetTypes,
  textTypes,
} from '../../../globals';
import { XeroService } from '../../../services';
import {
  getPrevOrAfterDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../../utils/datetime';
import { getDateEquivalence, generateDayIds } from '../../../utils/timesheets';
import NoResults from '../../../static/images/Misc/no-results.webp';

const useQuery = () => new URLSearchParams(useLocation().search);

const Timesheets = () => {
  const cookies = new Cookies();
  const query = useQuery();
  const { user } = useContext(UserContext);
  let employerId = "";
  if(user.userType === "Employer"){
    employerId = user.id;
  }
  const numberOfStillAnEmployee = user?.employees.filter(
    (e) => e.isStillAnEmployee === true
  ).length;
  const [viewType, setViewType] = useState({
    value: timesheetTypes.WEEKLY,
    label: `View by ${timesheetTypes.WEEKLY}`,
  });
  const [startDate, setStartDate] = useState(
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'start')
  );
  const [endDate, setEndDate] = useState(
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'end')
  );
  const [reportType, setReportType] = useState(null);
  const [isTimesheetsToXeroToggled, toggleIsTimesheetsToXero] = useState(false);

  const dayIds = generateDayIds(
    viewType.value,
    startDate,
    user.timesheetStartWeek
  );

  const { isLoading: isEmployeesLoading, employees } = useEmployees({
    employerId: user.id,
    includeNoLongerEmployees: true,
    startDate
  });


  const { isLoading: isRostersLoading, rosters } = useRosters({
    employerId: user.id,
    startDate,
    endDate,
  });

  const {
    isLoading: isTimesheetsLoading,
    timesheets,
    updateTimesheet,
    deleteTimesheet,
    overrideTimesheets,
  } = useTimesheets({
    employerId: user.id,
    startDate,
    endDate,
  });


  const { windowSize } = useWindowSize();

  useEffect(async () => {
    // We check if the URL has a code parameter
    // If it has a code, we generate a token set
    if (query.get('code')) {
      try {
        const { data: generateTokenSetResponse } =
          await XeroService.generateTokenSet({
            callbackUrl: window.location.href,
          });

        if (generateTokenSetResponse.tokenSet) {
          // Set tokenSet in the cookies
          cookies.set('xeroTokenSet', generateTokenSetResponse.tokenSet, {
            path: '/',
          });

          // Refresh the page to remove the query params
          window.location.href = '/employer/timesheets';
        }
      } catch (e) {
        // Refresh the page to remove the query params
        // since callbackUrl is not valid
        window.location.href = '/employer/timesheets';
      }
    }
  }, []);

  return (
    <div className={styles.Timesheets}>
      <div className={styles.Timesheets_firstRow}>
        <Breadcrumbs
          pageTitle="Timesheets"
          pages={[
            {
              name: 'Dashboard',
              link: '/employer/dashboard',
            },
          ]}
        />

        {numberOfStillAnEmployee > 0 && (
          <div className={styles.Timesheets_reportButtons}>
            {user.showXero &&
              (viewType.value === timesheetTypes.WEEKLY ||
                viewType.value === timesheetTypes.FORTNIGHTLY) && (
                <Button
                  className={styles.Timesheets_reportButtons_button}
                  icon="logout"
                  type={buttonTypes.PRIMARY.BLUE}
                  onClick={async () => {
                    if (cookies.get('xeroTokenSet')) {
                      toggleIsTimesheetsToXero(true);
                    } else {
                      const { data: authorizeResponse } =
                        await XeroService.authorize();

                      if (authorizeResponse.consentUrl) {
                        window.location.href = authorizeResponse.consentUrl;
                      }
                    }
                  }}
                  disabled={
                    isEmployeesLoading ||
                    isRostersLoading ||
                    isTimesheetsLoading
                  }
                >
                  <span className={styles.Timesheets_reportButtons_button_text}>
                    Transfer to XERO
                  </span>
                </Button>
              )}

            <Button
              className={styles.Timesheets_reportButtons_button}
              icon="send"
              onClick={() => setReportType('Send')}
              disabled={
                isEmployeesLoading || isRostersLoading || isTimesheetsLoading
              }
            >
              <span className={styles.Timesheets_reportButtons_button_text}>
                Send Report
              </span>
            </Button>

            <Button
              className={styles.Timesheets_reportButtons_button}
              icon="download"
              type={buttonTypes.PRIMARY.RED}
              onClick={() => setReportType('Download')}
              disabled={
                isEmployeesLoading || isRostersLoading || isTimesheetsLoading
              }
            >
              <span className={styles.Timesheets_reportButtons_button_text}>
                Download Report
              </span>
            </Button>

            {reportType !== null && (
              <GenerateTimesheetReportModal
                isOpen={reportType !== null}
                handleClose={() => setReportType(null)}
                reportType={reportType}
                viewType={viewType.value}
                startDate={startDate}
                endDate={endDate}
                employees={employees}
                timesheets={timesheets}
                employerFullName={user.fullName}
                employerEmailAddress={user.emailAddress}
                hoursAndMinutesFormat={user?.hoursAndMinutesFormat}
                employerId={employerId}
              />
            )}
          </div>
        )}
      </div>

      {user.employees.length > 0 ? (
        <>
          <div className={styles.Timesheets_secondRow}>
            <div className={styles.Timesheets_navigationContainer}>
              <Text type={textTypes.HEADING.XS}>
                {viewType.value !== timesheetTypes.DAILY && (
                  <>{dateFormat(new Date(startDate), 'mmm d')} - </>
                )}

                {dateFormat(new Date(endDate), 'mmm d, yyyy')}
              </Text>

              <div className={styles.Timesheets_navigationContainer_navigation}>
                <Button
                  className={
                    styles.Timesheets_navigationContainer_navigation_item
                  }
                  icon="arrow_back"
                  onClick={() => {
                    if (viewType.value === timesheetTypes.DAILY) {
                      setStartDate(
                        getPrevOrAfterDate(startDate, 1, 'subtract')
                      );
                      setEndDate(getPrevOrAfterDate(endDate, 1, 'subtract'));
                    } else if (viewType.value === timesheetTypes.WEEKLY) {
                      setStartDate(
                        getPrevOrAfterDate(startDate, 7, 'subtract')
                      );
                      setEndDate(getPrevOrAfterDate(endDate, 7, 'subtract'));
                    } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                      setStartDate(
                        getPrevOrAfterDate(startDate, 14, 'subtract')
                      );
                      setEndDate(getPrevOrAfterDate(endDate, 14, 'subtract'));
                    } else if (viewType.value === timesheetTypes.MONTHLY) {
                      setStartDate(
                        dateFormat(
                          getFirstDayOfMonth(
                            getPrevOrAfterDate(startDate, 28, 'subtract')
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                      setEndDate(
                        dateFormat(
                          getLastDayOfMonth(
                            getPrevOrAfterDate(startDate, 28, 'subtract')
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                    }
                  }}
                >
                  <span
                    className={
                      styles.Timesheets_navigationContainer_navigation_item_buttonText
                    }
                  >
                    Prev{' '}
                    {viewType.value === timesheetTypes.DAILY
                      ? 'Day'
                      : viewType.value.slice(0, -2)}
                  </span>
                </Button>

                <ControlledSelect
                  className={
                    styles.Timesheets_navigationContainer_navigation_item
                  }
                  options={[
                    {
                      value: timesheetTypes.DAILY,
                      label: `View by ${timesheetTypes.DAILY}`,
                    },
                    {
                      value: timesheetTypes.WEEKLY,
                      label: `View by ${timesheetTypes.WEEKLY}`,
                    },
                    {
                      value: timesheetTypes.FORTNIGHTLY,
                      label: `View by ${timesheetTypes.FORTNIGHTLY}`,
                    },
                    {
                      value: timesheetTypes.MONTHLY,
                      label: `View by ${timesheetTypes.MONTHLY}`,
                    },
                  ]}
                  name="viewType"
                  placeholder="View Type"
                  value={viewType}
                  onChange={(val) => {
                    if (val.value === timesheetTypes.MONTHLY) {
                      setStartDate(
                        dateFormat(
                          getFirstDayOfMonth(
                            getDateEquivalence(
                              val.value,
                              user.timesheetStartDate,
                              'start'
                            )
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                      setEndDate(
                        dateFormat(
                          getLastDayOfMonth(
                            getDateEquivalence(
                              val.value,
                              user.timesheetStartDate,
                              'start'
                            )
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                    } else {
                      setStartDate(
                        getDateEquivalence(
                          val.value,
                          user.timesheetStartDate,
                          'start'
                        )
                      );
                      setEndDate(
                        getDateEquivalence(
                          val.value,
                          user.timesheetStartDate,
                          'end'
                        )
                      );
                    }
                    setViewType(val);
                  }}
                />

                <Button
                  className={
                    styles.Timesheets_navigationContainer_navigation_item
                  }
                  icon="arrow_forward"
                  iconPosition="right"
                  onClick={() => {
                    if (viewType.value === timesheetTypes.DAILY) {
                      setStartDate(getPrevOrAfterDate(startDate, 1, 'add'));
                      setEndDate(getPrevOrAfterDate(endDate, 1, 'add'));
                    } else if (viewType.value === timesheetTypes.WEEKLY) {
                      setStartDate(getPrevOrAfterDate(startDate, 7, 'add'));
                      setEndDate(getPrevOrAfterDate(endDate, 7, 'add'));
                    } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                      setStartDate(getPrevOrAfterDate(startDate, 14, 'add'));
                      setEndDate(getPrevOrAfterDate(endDate, 14, 'add'));
                    } else if (viewType.value === timesheetTypes.MONTHLY) {
                      setStartDate(
                        dateFormat(
                          getFirstDayOfMonth(
                            getPrevOrAfterDate(endDate, 28, 'add')
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                      setEndDate(
                        dateFormat(
                          getLastDayOfMonth(
                            getPrevOrAfterDate(endDate, 28, 'add')
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                    }
                  }}
                >
                  <span
                    className={
                      styles.Timesheets_navigationContainer_navigation_item_buttonText
                    }
                  >
                    Next{' '}
                    {viewType.value === timesheetTypes.DAILY
                      ? 'Day'
                      : viewType.value.slice(0, -2)}
                  </span>
                </Button>
              </div>
            </div>

            <ButtonLink
              to="/employer/timesheets/create"
              type={buttonTypes.PRIMARY.GREEN}
              icon="add"
            >
              Create Timesheet
            </ButtonLink>
          </div>

          <TimesheetContext.Provider
            value={{
              viewType: viewType.value,
              startDate,
              endDate,
              dayIds,
              isEmployeesLoading,
              employees,
              isRostersLoading,
              rosters,
              isTimesheetsLoading,
              timesheets,
              updateTimesheet,
              deleteTimesheet,
              overrideTimesheets,
              windowSize,
            }}
          >
            <TimesheetGrid />

            {isTimesheetsToXeroToggled && (
              <TimesheetsToXeroModals
                isOpen={isTimesheetsToXeroToggled}
                handleClose={() => toggleIsTimesheetsToXero(false)}
              />
            )}
          </TimesheetContext.Provider>
        </>
      ) : (
        <div className={styles.Timesheets_noEmployees}>
          <img
            src={NoResults}
            alt="TimeSheet No Employees"
            className={styles.Timesheets_noEmployees_image}
          />

          <Text
            colorClass={colorClasses.RED['400']}
            type={textTypes.HEADING.XS}
          >
            YOU CURRENTLY DON'T HAVE EMPLOYEES.
          </Text>

          <Text
            type={textTypes.HEADING.XXS}
            className={styles.Timesheets_noEmployees_withMargin}
          >
            Add one by clicking the link{' '}
            <Link
              to="/employer/employees/add"
              className={styles.Timesheets_noEmployees_link}
            >
              here
            </Link>
            .
          </Text>
        </div>
      )}
    </div>
  );
};

export default Timesheets;
