import { useState } from 'react';

import { TimesheetsService } from '../services';

const useUpdateTimesheet = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const updateTimesheet = async (timesheet) => {
    setIsUpdating(true);

    const { data: updateTimesheetResponse } = await TimesheetsService.update(
      timesheet
    );

    setIsUpdating(false);

    let message = null;

    if (updateTimesheetResponse.message === 'timesheet_updated') {
      message = 'timesheet_updated';
    } else {
      message = 'timesheet_not_updated';
    }

    return { message };
  };

  return { isUpdating, updateTimesheet };
};

export default useUpdateTimesheet;
