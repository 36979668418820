import React, { useState, useContext,useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { ForgotPasswordModals } from '../../../components/modals';
import {
  Card,
  ControlledInput,
  Button,
  ButtonLink,
  Spinner,
  Text,
} from '../../../components/elements';
import styles from './styles.module.scss';
import {
  buttonKinds,
  buttonTypes,
  colorClasses,
  colorNames,
  inputKinds,
  spinnerSizes,
  textTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { UsersService, TokensService } from '../../../services';
import Logo from '../../../static/images/Logo/logo.png';
import config from "../../../services/config";
import axios from "axios";

const validate = (values) => {
  const errors = {};

  if (!values.emailAddress) {
    errors.emailAddress = 'This field is required.';
  }

  if (!values.password) {
    errors.password = 'This field is required.';
  }

  return errors;
};

const Login = () => {
  const userContext = useContext(UserContext);
  const cookies = new Cookies();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isForgotPasswordToggled, toggleIsForgotPassword] = useState(false);

  const checkEmployerSubscription = async (emailAddress) => {
    const BASE_URL = `${config.API_URL}/users/getEmployerDetails`;
    const data = {
      emailAddress: emailAddress,
    };
    try {
      const response = await axios.post(BASE_URL, data);
      if (response.data && !response.data.employees.isSubscribed) {
        return false;
      }else{
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <>
      <Card className={styles.Login}>
        <div className={styles.Login_header}>
          <img
            src={Logo}
            className={styles.Login_header_logo}
            alt="TimeSheet Logo"
          />

          <div className={styles.Login_header_headingTextWrapper}>
            <Text
              type={textTypes.SPAN.MD}
              className={styles.Login_header_headingTextWrapper_headingText}
            >
              Sign In with TimeSheet
            </Text>
          </div>
        </div>

        <div className={styles.Login_content}>
          <Formik
            initialValues={{
              emailAddress: '',
              password: '',
            }}
            onSubmit={async (values, { setErrors }) => {
              const currentFormValues = {
                emailAddress: values.emailAddress,
                password: values.password,
              };

              const errors = validate(values);
              if (!isEmpty(errors)) {
                setErrors(errors);
                return;
              }

              setIsLoggingIn(true);

              const { data: loginResponse } = await UsersService.login(
                currentFormValues
              );

              if (loginResponse.message === 'login_failed') {
                setIsLoggingIn(false);
                setErrors({
                  overall: 'Invalid email address and/or password.',
                });
              } else {
                // Call the Acquire Tokens endpoint to set the tokens
                // in to the cookies
                const { data: acquireResponse } = await TokensService.acquire({
                  userId: loginResponse.id,
                  emailAddress: currentFormValues.emailAddress,
                  password: currentFormValues.password,
                });
                if (acquireResponse.message === 'user_is_valid') {
                  if (loginResponse.userType === "Employee") {
                    // const isSubscribed = await checkEmployerSubscription(loginResponse.employerDetails.employerEmailAddress);
                    // if (!isSubscribed) {
                    //   setIsLoggingIn(false);
                    //   setErrors({
                    //     overall: 'Your Employer plan is expired, contact your employer for more details.',
                    //   });
                    //   return;
                    // } else {
                      
                    // }
                    userContext.loginUpdate(loginResponse);
  
                      cookies.set('accessToken', acquireResponse.accessToken, {
                        path: '/',
                      });
                      cookies.set('refreshToken', acquireResponse.refreshToken, {
                        path: '/',
                      });
                  } else {
                    userContext.loginUpdate(loginResponse);
  
                    cookies.set('accessToken', acquireResponse.accessToken, {
                      path: '/',
                    });
                    cookies.set('refreshToken', acquireResponse.refreshToken, {
                      path: '/',
                    });
                  }
                
                  // Update login
                }
                setIsLoggingIn(false);
              }
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <ControlledInput
                  className={styles.Login_content_input}
                  placeholder="Email Address"
                  name="emailAddress"
                  icon="email"
                  value={values.emailAddress}
                  error={errors.emailAddress}
                  onChange={(e) =>
                    setFieldValue('emailAddress', e.target.value)
                  }
                />
                <ControlledInput
                  kind={inputKinds.PASSWORD}
                  className={styles.Login_content_input}
                  placeholder="Password"
                  name="password"
                  icon="vpn_key"
                  value={values.password}
                  error={errors.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                />
                {errors.overall && (
                  <Text
                    className={styles.Login_content_input_errorMessage}
                    type={textTypes.BODY.XS}
                    colorClass={colorClasses.RED['400']}
                  >
                    {errors.overall}
                  </Text>
                )}

                <Button
                  type={buttonTypes.TEXT.VIOLET}
                  className={styles.Login_content_forgotPassword}
                  onClick={() => toggleIsForgotPassword(true)}
                >
                  Forgot Password?
                </Button>

                <div className={styles.Login_content_buttonGroup}>
                  <Button
                    kind={buttonKinds.SUBMIT}
                    icon="lock_open"
                    disabled={isLoggingIn}
                    onClick={() => {}}
                  >
                    <span
                      className={styles.Login_content_buttonGroup_buttonText}
                    >
                      Sign In
                      {isLoggingIn && (
                        <Spinner
                          size={spinnerSizes.XS}
                          colorName={colorNames.WHITE}
                          className={styles.Login_content_buttonGroup_spinner}
                        />
                      )}
                    </span>
                  </Button>

                  <ButtonLink
                    type={buttonTypes.SECONDARY.VIOLET}
                    to="/signup"
                    icon="person"
                    disabled={isLoggingIn}
                  >
                    Sign Up
                  </ButtonLink>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Card>

      {isForgotPasswordToggled && (
        <ForgotPasswordModals
          isOpen={isForgotPasswordToggled}
          handleClose={() => toggleIsForgotPassword(false)}
        />
      )}
    </>
  );
};

export default Login;
