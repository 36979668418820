import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { TimesheetsService } from '../services';
import { isTimesheetTimesValid } from '../utils/timesheets';
import { convertTimeTo12HourFormat, hoursWithDecimalToHoursAndMinutes, momentTo24HourTime,convertTimeTo24HourFormat } from '../utils/datetime';
import moment from 'moment';

const useTimesheets = ({
  adminId,
  employerId,
  employeeId,
  userId,
  clientId,
  projectId,
  startDate,
  endDate,
}) => {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [timesheets, setTimesheets] = useState([]);

  const updateTimesheet = async (timesheet) => {
    const flag = false;
  
    if (timesheet.lunchBreakDuration) {

      const startTime = timesheet.startTimeDetails.startTime;
      const endTime = timesheet.endTimeDetails.endTime;
      let diffMins = moment.utc(endTime, 'HH:mm a').diff(moment.utc(startTime, 'HH:mm a'), 'minutes');
      const lunchHour = moment.utc(timesheet.lunchBreakDuration, 'hh:mm a').get('hour');
      const lunchMinutes = moment.utc(timesheet.lunchBreakDuration, 'hh:mm a').get('minute');
      const totalMinutes = (lunchHour * 60) + lunchMinutes
      if (( diffMins > 0 && Math.abs(diffMins) <= totalMinutes) || totalMinutes > 600 || totalMinutes === 0 ) {
        alert.error('Oops, please Enter a valid lunch time');
        return flag 
      }
     
    }
   
    // Only updates of start time and end time have timesheet.date
    if (timesheet.date) {
      // Check if times of this timesheet is not overlapping
      // any times in timesheets array

      const filteredTimesheets = timesheets.filter(
        (t) =>
          t.date === timesheet.date &&
          t.timesheetId !== timesheet.timesheetId &&
          t.userId === timesheet.userId
      );

      // BUG: DOESN'T ALLOW UPDATING OF END TIME
      // if (
      //   !isTimesheetTimesValid(filteredTimesheets, {
      //     startTime: timesheet?.startTimeDetails?.startTime,
      //     endTime: timesheet?.endTimeDetails?.endTime,
      //   })
      // ) {
      //   flag = true;
      // }
    }
    if (!flag) {

      const { data: updateTimesheetResponse } = await TimesheetsService.update(
        timesheet
      );

      if (updateTimesheetResponse.timesheet) {
        setTimesheets((prevTimesheets) =>
          prevTimesheets.map((prevTimesheet) =>
            prevTimesheet.timesheetId ===
              updateTimesheetResponse.timesheet.timesheetId
              ? updateTimesheetResponse.timesheet
              : prevTimesheet
          )
        );

        alert.success('Timesheet updated!');

      } else {
        alert.error('Oops, something went wrong.');
      }
    }
    else {
      alert.error(
        'Oops, the time you have inputted overlaps a certain timesheet.'
      );
    }

    return !flag; // Reverse so that it will be true if successful while false if not
  };

  const deleteTimesheet = async (timesheetId) => {
    const { data: deleteTimesheetResponse } = await TimesheetsService.delete({
      timesheetId,
    });

    if (deleteTimesheetResponse.message === 'timesheet_deleted') {
      setTimesheets((prevTimesheets) =>
        prevTimesheets.filter(
          (prevTimesheet) => prevTimesheet.timesheetId !== timesheetId
        )
      );

      alert.success('Timesheet deleted!');
    } else {
      alert.error('Oops, something went wrong.');
    }
  };

  const overrideTimesheets = async (timesheetsToDelete, timesheetsToCreate) => {
    let isOkay = true;

    // Delete timesheets
    timesheetsToDelete.forEach(async (timesheetId) => {
      const { data: deleteTimesheetResponse } = await TimesheetsService.delete({
        timesheetId,
      });

      if (deleteTimesheetResponse.message !== 'timesheet_deleted') {
        isOkay = false;
      }
    });

    // Copy timesheetsToCreate since we will be updating
    // some of the properties
    const copyTimesheetsToCreate = JSON.parse(
      JSON.stringify(timesheetsToCreate)
    );

    // Create timesheets
    for (let i = 0; i < copyTimesheetsToCreate.length; i++) {
      const timesheet = copyTimesheetsToCreate[i];

      timesheet.employerId = employerId;
      const { data: createTimesheetResponse } = await TimesheetsService.create(
        timesheet
      );

      if (createTimesheetResponse.message === 'timesheet_created') {
        // Add newly created timesheet id to timesheetsToCreate with the same index
        timesheetsToCreate[i].timesheetId = createTimesheetResponse.timesheetId;
      } else {
        isOkay = false;
      }
    }

    if (isOkay) {
      // Remove timesheetsToDelete in state
      setTimesheets((prevTimesheets) =>
        prevTimesheets.filter(
          (prevTimesheet) =>
            !timesheetsToDelete.includes(prevTimesheet.timesheetId)
        )
      );

      // Add timesheetsToCreate in state
      setTimesheets((prevTimesheets) => [
        ...prevTimesheets,
        ...timesheetsToCreate,
      ]);

      // Show an alert
      alert.success('Timesheets overridden!');
    } else {
      alert.error('Oops, something went wrong.');
    }
  };

  useEffect(() => {
    const getTimesheets = async () => {
      setIsLoading(true);
      const params = {startDate, endDate, userId,clientId,projectId};
    
      if (adminId) {
        params.adminId = adminId;
      } else if (userId && clientId) {
        params.userId = userId;
        params.clientId = clientId;
      } else if (userId && projectId) {
        params.userId = userId;
        params.projectId = projectId;
      }
      else if (employerId) {
        params.employerId = employerId;
      } 
      else if (employerId && userId === 'All'){
        params.employerId = employerId;
      }
      else if (employerId && userId){
        params.employerId = employerId;
        params.userId = userId;
      }
      else if (employerId && clientId == ""){
        params.employerId = employerId;
      }
      else if (employerId && clientId){
        params.employerId = employerId;
        params.clientId = clientId;
      }
      else if (userId) {
        params.userId = userId;
      } else if (clientId) {
        params.clientId = clientId;
      } else if (projectId && clientId) {
        params.projectId = projectId;
        params.clientId = clientId;
      }  else if (projectId) {
        params.projectId = projectId;
      }
      const getTimesheetsResponse = await TimesheetsService.get(
        params
      ).then((res) => {
        setIsLoading(false);
        setTimesheets(res.data.timesheets);
        return res;
      }).catch((error) => {
        setIsLoading(false);
        console.log(error,'error')
      });

      setIsLoading(false);
    };
    getTimesheets();
  }, [startDate, endDate ,userId ,clientId]);

  return {
    isLoading,
    timesheets,
    updateTimesheet,
    deleteTimesheet,
    overrideTimesheets,
  };
};

export default useTimesheets;
