import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import dateFormat from 'dateformat';
import { Formik } from 'formik';
import styles from './styles.module.scss';

import { Breadcrumbs } from '../../../components';
import {
  Card,
  ControlledInput,
  Button,
  Spinner,
  Text,
} from '../../../components/elements';
import {
  ExitTabletViewModal,
  TabletViewTimesheetModals,
} from '../../../components/modals';
import {
  colorClasses,
  colorNames,
  buttonKinds,
  inputKinds,
  leaveTypes,
  spinnerSizes,
  tabletViewRecordingTypes,
  textTypes,
  buttonTypes,
} from '../../../globals';
import { UserContext } from '../../../contexts';
import { useEmployees, useTimesheets } from '../../../hooks';
import { TimesheetsService, UsersService } from '../../../services';
import Preloader from './Preloader';
import NoResults from '../../../static/images/Misc/no-results.webp';
import useGetEmployee from "../../../hooks/useGetEmployee";

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'This field is required.';
  }

  return errors;
};

const TabletView = () => {
  const { user, loginUpdate } = useContext(UserContext);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isExitTabletViewToggled, toggleExitTabletView] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [existLoading,setExistLoading] = useState(false);

  const {isLoading: isUserLoading, employee: getEmployeeDetail} = useGetEmployee({
    emailAddress: user.emailAddress,
  });

  // useEffect(() => {
  //   if(getEmployeeDetail){
  //     if(!getEmployeeDetail.isPasswordRequired){
  //       if(!user.hasOpenedTabletView){
  //         loginUpdate({ ...user, hasOpenedTabletView: true });
  //         setIsLoggingIn(false);
  //       }
  //     }
  //   }
  // },[getEmployeeDetail]);

  const { isLoading: employeesLoading, employees: retrievedEmployees } =
    useEmployees({
      employerId: user.id,
    });

  const { isLoading: timesheetsLoading, timesheets } = useTimesheets({
    employerId: user.id,
    startDate: dateFormat(new Date(), 'yyyy-mm-dd'),
    endDate: dateFormat(new Date(), 'yyyy-mm-dd'),
  });

  const updateActiveTimesheet = async (employeeId) => {
    const { data: getTimesheetsResponse } = await TimesheetsService.get({
      userId: employeeId,
      startDate: dateFormat(new Date(), 'yyyy-mm-dd'),
      endDate: dateFormat(new Date(), 'yyyy-mm-dd'),
    });

    if (getTimesheetsResponse.timesheets) {
      const foundEmployee = employees.find(
        (employee) => employee.id === employeeId
      );

      const filteredTimesheets = getTimesheetsResponse.timesheets.filter(
        (timesheet) =>
          !timesheet?.endTimeDetails &&
          !timesheet?.endTimeDetails?.endTime &&
          timesheet?.leaveType === leaveTypes.NONE
      );

      // We only use the first timesheet because there might be
      // some case where an employee has 2 active timesheets (without end time)
      foundEmployee.activeTimesheet = filteredTimesheets[0]; // eslint-disable-line prefer-destructuring
    }
  };

  // const openExistModal = () => {
  //   if(getEmployeeDetail) {
  //     if (!getEmployeeDetail.isPasswordRequired) {
  //       setExistLoading(true);
  //       loginUpdate({ ...user, hasOpenedTabletView: undefined });
  //       window.location.href = "/employer/dashboard";
  //     }else{
  //       toggleExitTabletView(true)
  //     }
  //   }else{
  //     toggleExitTabletView(true)
  //   }
  // }

  // Setting employees
  useEffect(() => {
    if (!employeesLoading && !timesheetsLoading) {
      if (!search) {
        // We loop through all of the retrievedEmployees
        // to set their activeTimesheets
        retrievedEmployees.forEach((employee) => {
          const filteredTimesheets = timesheets.filter(
            (timesheet) =>
              timesheet?.userId === employee.id &&
              !timesheet?.endTimeDetails &&
              !timesheet?.endTimeDetails?.endTime &&
              timesheet?.leaveType === leaveTypes.NONE
          );

          // We only use the first timesheet because there might be
          // some case where an employee has 2 active timesheets (without end time)
          employee.activeTimesheet = filteredTimesheets[0]; // eslint-disable-line prefer-destructuring
        });

        // Then we set our employees state
        setEmployees(retrievedEmployees);
      } else {
        // Search functionality
        setEmployees(
          employees.filter((employee) =>
            employee.fullName
              .toLowerCase()
              .match(new RegExp(search.toLowerCase(), 'g'))
          )
        );
      }
    }
  }, [employeesLoading, timesheetsLoading, search]);

  return (
    <div
      className={cn(styles.TabletView, {
        [styles.TabletView___passwordProtected]: !user.hasOpenedTabletView,
      })}
    >

      {
        (!isUserLoading && !existLoading) && (
            <>
              {!user.hasOpenedTabletView ? (
                  <>
                    <div className={styles.TabletView_breadcrumbs}>
                      <Breadcrumbs
                          pageTitle="Tablet View"
                          pages={[
                            {
                              name: 'Dashboard',
                              link: '/employer/dashboard',
                            },
                          ]}
                      />
                    </div>

                    <Card
                        className={cn(
                            styles.TabletView_card,
                            styles.TabletView_passwordCard
                        )}
                    >
                      <Text
                          type={textTypes.HEADING.XXS}
                          className={styles.TabletView_headingText}
                      >
                        Enter your password to proceed
                      </Text>

                      <Formik
                          initialValues={{
                            password: '',
                          }}
                          onSubmit={async (values, { setErrors }) => {
                            const errors = validate(values);
                            if (!isEmpty(errors)) {
                              setErrors(errors);
                              return;
                            }

                            setIsLoggingIn(true);

                            const currentFormValues = {
                              emailAddress: user.emailAddress,
                              password: values.password,
                            };

                            const { data: loginResponse } = await UsersService.login(
                                currentFormValues
                            );

                            if (loginResponse.message === 'login_failed') {
                              setIsLoggingIn(false);
                              setErrors({
                                overall: 'Invalid password.',
                              });
                            } else {
                              // Update user object in cookies
                              loginUpdate({ ...user, hasOpenedTabletView: true });
                              setIsLoggingIn(false);
                            }
                          }}
                      >
                        {({ errors, values, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                              <ControlledInput
                                  kind={inputKinds.PASSWORD}
                                  className={styles.TabletView_withMarginBottom}
                                  placeholder="Password"
                                  name="password"
                                  icon="vpn_key"
                                  value={values.password}
                                  error={errors.password}
                                  onChange={(e) => setFieldValue('password', e.target.value)}
                              />
                              {errors.overall && (
                                  <Text
                                      className={styles.TabletView_withMarginBottom}
                                      type={textTypes.BODY.XS}
                                      colorClass={colorClasses.RED['400']}
                                  >
                                    {errors.overall}
                                  </Text>
                              )}

                              <div className={styles.TabletView_buttonGroup}>
                                <Button
                                    kind={buttonKinds.SUBMIT}
                                    icon="lock_open"
                                    disabled={isLoggingIn}
                                    onClick={() => {}}
                                >
                      <span
                          className={styles.TabletView_buttonGroup_buttonText}
                      >
                        Proceed
                        {isLoggingIn && (
                            <Spinner
                                size={spinnerSizes.XS}
                                colorName={colorNames.WHITE}
                                className={styles.TabletView_buttonGroup_spinner}
                            />
                        )}
                      </span>
                                </Button>
                              </div>
                            </form>
                        )}
                      </Formik>
                    </Card>
                  </>
              ) : (
                  <>
                    <div className={styles.TabletView_firstRow}>
                      <Button
                          type={buttonTypes.TEXT.RED}
                          icon="arrow_back"
                          onClick={() => toggleExitTabletView(true)}
                      >
                        Exit Tablet View
                      </Button>

                      <ExitTabletViewModal
                          isOpen={isExitTabletViewToggled}
                          handleClose={() => toggleExitTabletView(false)}
                          title="Enter your password to exit tablet view"
                      />

                      <ControlledInput
                          className={styles.TabletView_searchInput}
                          placeholder="Search Employee"
                          name="search"
                          icon="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>

                    {employeesLoading || timesheetsLoading ? (
                        <Preloader />
                    ) : (
                        <>
                          {employees.length ? (
                              <>
                                <div className={styles.TabletView_grid}>
                                  {/* Header of TabletViewGrid starts here */}
                                  <div
                                      className={cn(
                                          styles.TabletView_grid_column,
                                          styles.TabletView_grid_header
                                      )}
                                  >
                                    Employee Name
                                  </div>

                                  <div
                                      className={cn(
                                          styles.TabletView_grid_column,
                                          styles.TabletView_grid_header
                                      )}
                                  >
                                    Action
                                  </div>
                                  {/* Header of TabletViewGrid ends here */}

                                  {/* Body of TabletViewGrid starts here */}
                                  {employees.map(
                                      ({ id, fullName, emailAddress, activeTimesheet }) => (
                                          <div className={styles.TabletView_keyWrapper} key={id}>
                                            <div className={styles.TabletView_grid_column}>
                                              {fullName}
                                            </div>
                                            <div className={styles.TabletView_grid_column}>
                                              <Button
                                                  type={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    user.tabletViewRecordingType ===
                                                    tabletViewRecordingTypes.AUTOMATICALLY_RECORD
                                                        ? activeTimesheet
                                                            ? buttonTypes.TEXT.RED
                                                            : buttonTypes.TEXT.GREEN
                                                        : buttonTypes.TEXT.GREEN
                                                  }
                                                  icon={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    user.tabletViewRecordingType ===
                                                    tabletViewRecordingTypes.AUTOMATICALLY_RECORD
                                                        ? activeTimesheet
                                                            ? 'logout'
                                                            : 'login'
                                                        : 'edit'
                                                  }
                                                  onClick={() =>
                                                      setSelectedEmployee({
                                                        employeeId: id,
                                                        fullName,
                                                        emailAddress,
                                                        activeTimesheet,
                                                      })
                                                  }
                                              >
                                                {/* eslint-disable-next-line no-nested-ternary */}
                                                {user.tabletViewRecordingType ===
                                                tabletViewRecordingTypes.AUTOMATICALLY_RECORD
                                                    ? activeTimesheet
                                                        ? 'Sign Out'
                                                        : 'Sign In'
                                                    : 'Log Time'}
                                              </Button>
                                            </div>
                                          </div>
                                      )
                                  )}
                                </div>

                                {selectedEmployee && (
                                    <TabletViewTimesheetModals
                                        isOpen={selectedEmployee !== null}
                                        handleClose={() => setSelectedEmployee(null)}
                                        employeeId={selectedEmployee?.employeeId}
                                        fullName={selectedEmployee?.fullName}
                                        emailAddress={selectedEmployee?.emailAddress}
                                        activeTimesheet={
                                          selectedEmployee?.activeTimesheet
                                              ? selectedEmployee.activeTimesheet
                                              : null
                                        }
                                        updateActiveTimesheet={() =>
                                            updateActiveTimesheet(selectedEmployee?.employeeId)
                                        }
                                        recordingType={user.tabletViewRecordingType}
                                    />
                                )}
                              </>
                          ) : (
                              <div className={styles.TabletView_noEmployees}>
                                <img
                                    src={NoResults}
                                    alt="TimeSheet No Employees"
                                    className={styles.TabletView_noEmployees_image}
                                />

                                <Text
                                    colorClass={colorClasses.RED['400']}
                                    type={textTypes.HEADING.XS}
                                >
                                  YOU CURRENTLY DON'T HAVE EMPLOYEES.
                                </Text>

                                <Text
                                    type={textTypes.HEADING.XXS}
                                    className={styles.TabletView_noEmployees_withMargin}
                                >
                                  Add one by clicking the link the tablet view, and clicking
                                  the 'Employees' tab in the sidebar.
                                </Text>
                              </div>
                          )}
                        </>
                    )}
                  </>
              )}
            </>
          )
      }
    </div>
  );
};

export default TabletView;
