import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import dateFormat from 'dateformat';
import GoogleMapReact from 'google-map-react';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Breadcrumbs, GoogleMapMarker } from '../../../components';
import {
  Button,
  Checkbox,
  ControlledSelect,
  Text,
} from '../../../components/elements';
import { UserContext } from '../../../contexts';
import { useEmployees, useTimesheets } from '../../../hooks';
import {
  colorClasses,
  colorNames,
  textTypes,
  timesheetTypes,
} from '../../../globals';
import {
  getPrevOrAfterDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import { getDateEquivalence, getHours } from '../../../utils/timesheets';

const Map = () => {
  const { user } = useContext(UserContext);
  const [viewType, setViewType] = useState({
    value: timesheetTypes.WEEKLY,
    label: `View by ${timesheetTypes.WEEKLY}`,
  });
  const [startDate, setStartDate] = useState(
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'start')
  );
  const [endDate, setEndDate] = useState(
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'end')
  );
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({
    label: 'All',
    value: 'All',
  });
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [selectedTimesheetType, setSelectedTimesheetType] = useState(null);
  const [showStartLocation, setShowStartLocation] = useState(true);
  const [showEndLocation, setShowEndLocation] = useState(true);

  const { isLoading: isEmployeesLoading, employees } = useEmployees({
    employerId: user.id,
  });
  
  const { isLoading: isTimesheetsLoading, timesheets } = useTimesheets({
    employerId: user.id,
    startDate,
    endDate,
  });

  const getEmployeeDetails = (employeeId) =>
    employees.find((employee) => employee.id === employeeId);

  const filterTimesheets = (timesheetsToFilter) => {
    const fTimesheets = timesheetsToFilter.filter((timesheet) => {
      const {
        startTimeDetails: {
          location: { latlong: startLatLong } = {
            latlong: undefined,
          },
        } = {},
      } = timesheet;

      if (startLatLong) {
        return true;
      }

      return false;
    });

    setFilteredTimesheets(fTimesheets);
  };



  // Generate employee options
  useEffect(() => {
    if (!isEmployeesLoading) {
      const options = [
        {
          label: 'All',
          value: 'All',
        },
      ];
      employees.forEach(({ id, fullName }) => {
        const option = {
          label: fullName,
          value: id,
        };

        options.push(option);
      });

      setEmployeeOptions(options);
    }
  }, [isEmployeesLoading]);

  // Generate timesheets
  useEffect(() => {
    if (!isTimesheetsLoading) {
      filterTimesheets(timesheets);
    }
  }, [isTimesheetsLoading]);

  return (
    <div className={styles.Map}>
      <div className={styles.Map_firstRow}>
        <Breadcrumbs
          pageTitle="Map"
          pages={[
            {
              name: 'Dashboard',
              link: '/employer/dashboard',
            },
          ]}
        />
      </div>

      <div className={styles.Map_secondRow}>
        <div className={styles.Map_navigationContainer}>
          <Text type={textTypes.HEADING.XS}>
            {viewType.value !== timesheetTypes.DAILY && (
              <>{dateFormat(new Date(startDate), 'mmm d')} - </>
            )}

            {dateFormat(new Date(endDate), 'mmm d, yyyy')}
          </Text>

          <div className={styles.Map_navigationContainer_navigation}>
            <Button
              className={styles.Map_navigationContainer_navigation_item}
              icon="arrow_back"
              onClick={() => {
                if (viewType.value === timesheetTypes.DAILY) {
                  setStartDate(getPrevOrAfterDate(startDate, 1, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 1, 'subtract'));
                } else if (viewType.value === timesheetTypes.WEEKLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 7, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 7, 'subtract'));
                } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 14, 'subtract'));
                  setEndDate(getPrevOrAfterDate(endDate, 14, 'subtract'));
                } else if (viewType.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getPrevOrAfterDate(startDate, 28, 'subtract')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(
                        getPrevOrAfterDate(startDate, 28, 'subtract')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                }
              }}
            >
              <span
                className={
                  styles.Map_navigationContainer_navigation_item_buttonText
                }
              >
                Prev{' '}
                {viewType.value === timesheetTypes.DAILY
                  ? 'Day'
                  : viewType.value.slice(0, -2)}
              </span>
            </Button>

            <ControlledSelect
              className={styles.Map_navigationContainer_navigation_item}
              options={[
                {
                  value: timesheetTypes.DAILY,
                  label: `View by ${timesheetTypes.DAILY}`,
                },
                {
                  value: timesheetTypes.WEEKLY,
                  label: `View by ${timesheetTypes.WEEKLY}`,
                },
                {
                  value: timesheetTypes.FORTNIGHTLY,
                  label: `View by ${timesheetTypes.FORTNIGHTLY}`,
                },
                {
                  value: timesheetTypes.MONTHLY,
                  label: `View by ${timesheetTypes.MONTHLY}`,
                },
              ]}
              name="viewType"
              placeholder="View Type"
              value={viewType}
              onChange={(val) => {
                if (val.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getDateEquivalence(
                          val.value,
                          user.timesheetStartDate,
                          'start'
                        )
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(
                        getDateEquivalence(
                          val.value,
                          user.timesheetStartDate,
                          'start'
                        )
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                } else {
                  setStartDate(
                    getDateEquivalence(
                      val.value,
                      user.timesheetStartDate,
                      'start'
                    )
                  );
                  setEndDate(
                    getDateEquivalence(
                      val.value,
                      user.timesheetStartDate,
                      'end'
                    )
                  );
                }

                setViewType(val);
              }}
            />

            <Button
              className={styles.Map_navigationContainer_navigation_item}
              icon="arrow_forward"
              iconPosition="right"
              onClick={() => {
                if (viewType.value === timesheetTypes.DAILY) {
                  setStartDate(getPrevOrAfterDate(startDate, 1, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 1, 'add'));
                } else if (viewType.value === timesheetTypes.WEEKLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 7, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 7, 'add'));
                } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                  setStartDate(getPrevOrAfterDate(startDate, 14, 'add'));
                  setEndDate(getPrevOrAfterDate(endDate, 14, 'add'));
                } else if (viewType.value === timesheetTypes.MONTHLY) {
                  setStartDate(
                    dateFormat(
                      getFirstDayOfMonth(
                        getPrevOrAfterDate(endDate, 28, 'add')
                      ),
                      'yyyy-mm-dd'
                    )
                  );
                  setEndDate(
                    dateFormat(
                      getLastDayOfMonth(getPrevOrAfterDate(endDate, 28, 'add')),
                      'yyyy-mm-dd'
                    )
                  );
                }
              }}
            >
              <span
                className={
                  styles.Map_navigationContainer_navigation_item_buttonText
                }
              >
                Next{' '}
                {viewType.value === timesheetTypes.DAILY
                  ? 'Day'
                  : viewType.value.slice(0, -2)}
              </span>
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.Map_thirdRow}>
        <>
          <div>
            <Text type={textTypes.HEADING.XXS}>Marker Filters:</Text>

            <div className={styles.Map_thirdRow_checkboxes}>
              <Checkbox
                className={styles.Map_withMarginTop}
                name="startLocation"
                label="Show Start Location"
                onChange={() => setShowStartLocation(!showStartLocation)}
                checked={showStartLocation}
              />
              <Checkbox
                className={styles.Map_withMarginTop}
                name="endLocation"
                label="Show End Location"
                onChange={() => setShowEndLocation(!showEndLocation)}
                checked={showEndLocation}
              />
            </div>
          </div>

          <ControlledSelect
            className={styles.Map_thirdRow_viewSpecificEmployee}
            options={employeeOptions}
            name="employee"
            placeholder="Select an Employee"
            value={selectedEmployee}
            onChange={(val) => {
              setSelectedEmployee({
                label: val.label,
                value: val.value,
              });

              if (val.value === 'All') {
                // Reset to the original state
                filterTimesheets(timesheets);
              } else {
                // Filter by specific employee
                const filterByEmployeeTimesheets = timesheets.filter(
                  (timesheet) => timesheet.userId === val.value
                );
                filterTimesheets(filterByEmployeeTimesheets);
              }
            }}
          />
        </>
      </div>

      {isEmployeesLoading || isTimesheetsLoading ? (
        <Preloader />
      ) : (
        <div className={styles.Map_mapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyCQKXb_XO-uZbYHUDZLNYTjnF4hXyrgE44',
            }}
            defaultCenter={{
              lat: -36.848461,
              lng: 174.763336,
            }}
            defaultZoom={5}
          >
            {filteredTimesheets.map(
              ({
                userId,
                timesheetId,
                date,
                startTimeDetails: {
                  startTime,
                  actualTime: startActualTime,
                  location: { latlong: startLatLong, address: startAddress } = {
                    latlong: undefined,
                    address: undefined,
                  },
                } = {},
                endTimeDetails: {
                  endTime,
                  actualTime: endActualTime,
                  location: { latlong: endLatLong, address: endAddress } = {
                    latlong: undefined,
                    address: undefined,
                  },
                } = {},
                isLunchBreak,
                isTravelCharge,
                leaveType,
                publicHolidayMultiplier,
                notes,
              }) => {
                const employee = getEmployeeDetails(userId);
                const startLatLongArray = startLatLong
                  .replace(/ /g, '')
                  .split(',');

                let endLatLongArray = null;
                let isDone = false;
                if (endLatLong) {
                  endLatLongArray = endLatLong.replace(/ /g, '').split(',');
                  isDone = true;
                }

                const startTimeTimestamp = convertDateTimeToTimestamp(
                  date,
                  convertTimeTo24HourFormat(startTime)
                );
                const endTimeTimestamp = convertDateTimeToTimestamp(
                  date,
                  convertTimeTo24HourFormat(endTime)
                );

                const markers = [];

                if (showStartLocation) {
                  markers.push(
                    <GoogleMapMarker
                      lat={startLatLongArray[0]}
                      lng={startLatLongArray[1]}
                      isOpen={
                        timesheetId === selectedTimesheet &&
                        selectedTimesheetType === 'signIn'
                      }
                      close={() => {
                        setSelectedTimesheet(null);
                        setSelectedTimesheetType(null);
                      }}
                      onClick={() => {
                        setSelectedTimesheet(timesheetId);
                        setSelectedTimesheetType('signIn');
                      }}
                      markerColor={colorNames.GREEN}
                    >
                      <div className={styles.Map_timesheet}>
                        <Text colorClass={colorClasses.GREEN['400']}>
                          {employee?.fullName}
                        </Text>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Date:</b> {dateFormat(date, 'dd/mm/yyyy')}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Start:</b> {startTime || '--'}
                          </Text>

                          <Text>
                            <b>Actual:</b> {startActualTime || '--'}
                          </Text>

                          <Text>
                            <b>Address:</b> {startAddress || '--'}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Lunch:</b> {isLunchBreak ? 'Yes' : 'No'}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Leave:</b> {leaveType}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Notes:</b> {notes || '--'}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Hours:</b>{' '}
                            {user?.hoursAndMinutesFormat
                              ? hoursWithDecimalToHoursAndMinutes(
                                  getHours(
                                    startTimeTimestamp,
                                    endTimeTimestamp,
                                    isLunchBreak,
                                    isTravelCharge,
                                    leaveType,
                                    publicHolidayMultiplier
                                  )
                                )
                              : getHours(
                                  startTimeTimestamp,
                                  endTimeTimestamp,
                                  isLunchBreak,
                                  isTravelCharge,
                                  leaveType,
                                  publicHolidayMultiplier
                                )}
                          </Text>
                        </div>
                      </div>
                    </GoogleMapMarker>
                  );
                }

                // We create another marker if the timesheet has
                // a sign out location
                if (isDone && showEndLocation) {
                  markers.push(
                    <GoogleMapMarker
                      lat={endLatLongArray[0]}
                      lng={endLatLongArray[1]}
                      isOpen={
                        timesheetId === selectedTimesheet &&
                        selectedTimesheetType === 'signOut'
                      }
                      close={() => {
                        setSelectedTimesheet(null);
                        setSelectedTimesheetType(null);
                      }}
                      onClick={() => {
                        setSelectedTimesheet(timesheetId);
                        setSelectedTimesheetType('signOut');
                      }}
                      markerColor={colorNames.RED}
                    >
                      <div
                        className={cn(
                          styles.Map_timesheet,
                          styles.Map_timesheet___done
                        )}
                      >
                        <Text colorClass={colorClasses.RED['400']}>
                          {employee?.fullName}
                        </Text>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Date:</b> {dateFormat(date, 'dd/mm/yyyy')}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Start:</b> {startTime || '--'}
                          </Text>

                          <Text>
                            <b>Actual:</b> {startActualTime || '--'}
                          </Text>

                          <Text>
                            <b>Address:</b> {startAddress || '--'}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>End:</b> {endTime || '--'}
                          </Text>

                          <Text>
                            <b>Actual:</b> {endActualTime || '--'}
                          </Text>

                          <Text>
                            <b>Address:</b> {endAddress || '--'}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Lunch:</b> {isLunchBreak ? 'Yes' : 'No'}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Leave:</b> {leaveType}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Notes:</b> {notes || '--'}
                          </Text>
                        </div>

                        <div className={styles.Map_timesheet_group}>
                          <Text>
                            <b>Hours:</b>{' '}
                            {user?.hoursAndMinutesFormat
                              ? hoursWithDecimalToHoursAndMinutes(
                                  getHours(
                                    startTimeTimestamp,
                                    endTimeTimestamp,
                                    isLunchBreak,
                                    isTravelCharge,
                                    leaveType,
                                    publicHolidayMultiplier
                                  )
                                )
                              : getHours(
                                  startTimeTimestamp,
                                  endTimeTimestamp,
                                  isLunchBreak,
                                  isTravelCharge,
                                  leaveType,
                                  publicHolidayMultiplier
                                )}
                          </Text>
                        </div>
                      </div>
                    </GoogleMapMarker>
                  );
                }

                return markers;
              }
            )}
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

export default Map;
