import { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';

import { ClientsService } from '../services';

const useClients = ({ userId, search }) => {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);

  const deleteClient = async (clientId) => {
    const { data: deleteClientResponse } = await ClientsService.delete({
      clientId,
    });

    if (deleteClientResponse.message === 'client_deleted') {
      alert.success('Client deleted.');

      setClients((prevClients) =>
        prevClients.filter((prevClient) => prevClient.id !== clientId)
      );
    } else {
      alert.error('Oops, something went wrong.');
    }
  };

  useEffect(() => {
    const getClients = async () => {
      const { data: getClientsResponse } = await ClientsService.get({
        userId,
        search
      });

      if (getClientsResponse.clients) {
        setClients(getClientsResponse.clients);
      }

      setIsLoading(false);
    };
    getClients();
  }, [search]);
  return { isLoading, clients, deleteClient };
};

export default useClients;
