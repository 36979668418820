import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.module.scss';

import { Schedule } from '../..';
import {
  ControlledInput,
  ControlledTextArea,
  ControlledSelect,
  Modal,
  Text,
} from '../../elements';
import {
  buttonTypes,
  colorClasses,
  modalSizes,
  textTypes,
  selectTypes,
} from '../../../globals';
import { RosterContext } from '../../../contexts';
import {
  defaultRole,
  modalTypes,
} from '../../../screens/employer/Rosters/constants';
import {
  validateDetails,
  validateSchedules,
} from '../../../screens/employer/Rosters/validations';

const validate = (values) => {
  let detailsErrors = {};
  if (values.modalType !== modalTypes.ASSIGN_SHIFT) {
    detailsErrors = validateDetails(values);
  }

  const schedulesErrors = validateSchedules(values);

  return {
    ...detailsErrors,
    ...schedulesErrors,
  };
};

const AssignRoleOrShiftModal = ({
  isOpen,
  handleClose,
  title,
  formInitialValues,
  isAssigning,
  assignRoleOrShift,
  createOrUpdateRoster,
}) => {
  const { dayIds, employees, getEmployeeFullName } = useContext(RosterContext);
  const formRef = useRef();

  return (
    <Modal
      size={modalSizes.MD}
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      actions={[
        {
          text: 'Confirm',
          type: buttonTypes.PRIMARY.GREEN,
          disabled: isAssigning,
          onClick: () => formRef.current.handleSubmit(),
        },
      ]}
    >
      <Formik
        innerRef={formRef}
        initialValues={formInitialValues}
        onSubmit={async (values, { setErrors, resetForm }) => {
          const errors = validate(values);
          if (!isEmpty(errors)) {
            setErrors(errors);
            return;
          }

          let data = {};

          if (values.modalType === modalTypes.ASSIGN_SHIFT) {
            // Data for assigning shift
            data = {
              employeeId: values.employeeId,
              schedules: values.schedules,
            };
          } else if (values.modalType === modalTypes.CREATE_ASSIGN_ROLE) {
            // Data for creating and assigning role
            data = {
              name: values.name,
              description: values.description,
              color: values.color.value,
              schedules: values.schedules,
            };
          } else if (values.modalType === modalTypes.UPDATE_ASSIGN_ROLE) {
            // Data for updating and assigning role
            data = {
              roleId: values.roleId,
              name: values.name,
              description: values.description,
              color: values.color.value,
              schedules: values.schedules,
            };
          }

          let shouldResetForm = false;

          const assignRoleOrShiftResponse =
            (await assignRoleOrShift(values.modalType, data)) || {};

          if (assignRoleOrShiftResponse.shouldResetForm) {
            shouldResetForm = assignRoleOrShiftResponse.shouldResetForm;
          }

          if (
            values.modalType === modalTypes.CREATE_ASSIGN_ROLE ||
            values.modalType === modalTypes.UPDATE_ASSIGN_ROLE
          ) {
            // Since values.employees is an object { label: '', value: '' },
            // we need to loop through everything to only get the value (employeeId)
            const roleEmployees = [];
            values.employees.forEach((employee) => {
              roleEmployees.push(employee.value);
            });

            // Create roster object
            const roster = {
              roleId:
                values.modalType === modalTypes.CREATE_ASSIGN_ROLE
                  ? assignRoleOrShiftResponse.newlyCreatedRoleId
                  : data.roleId,
              roleName: data.name,
              roleDescription: data.description,
              roleColor: data.color,
              schedules: data.schedules,
              employees: roleEmployees,
            };

            const createOrUpdateRosterResponse = await createOrUpdateRoster(
              roster
            );

            if (createOrUpdateRosterResponse.shouldResetForm) {
              shouldResetForm = createOrUpdateRosterResponse.shouldResetForm;
            }
          }

          if (shouldResetForm) {
            // Reset the form
            resetForm({
              values: defaultRole,
            });
          }

          // Close the modal
          handleClose();
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <>
            {(values.modalType === modalTypes.CREATE_ASSIGN_ROLE ||
              values.modalType === modalTypes.UPDATE_ASSIGN_ROLE) && (
              <>
                <ControlledInput
                  className={styles.AssignRoleOrShiftModal_withMarginBottom}
                  name="roleName"
                  placeholder="Role Name*"
                  value={values.name}
                  error={errors.name}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />

                <ControlledTextArea
                  className={styles.AssignRoleOrShiftModal_withMarginBottom}
                  name="roleDescription"
                  placeholder="Role Description*"
                  value={values.description}
                  error={errors.description}
                  onChange={(e) => setFieldValue('description', e.target.value)}
                />

                <ControlledSelect
                  className={styles.AssignRoleOrShiftModal_withMarginBottom}
                  options={[
                    {
                      label: 'Blue',
                      value: 'blue',
                    },
                    {
                      label: 'Pink',
                      value: 'pink',
                    },
                    {
                      label: 'Violet',
                      value: 'violet',
                    },
                  ]}
                  name="type"
                  placeholder="Color*"
                  value={values.color}
                  onChange={(val) => setFieldValue('color', val)}
                />

                <ControlledSelect
                  className={styles.AssignRoleOrShiftModal_withMarginBottom}
                  type={selectTypes.SLIM}
                  label="Employees"
                  options={employees.map((employee) => ({
                    value: employee.id,
                    label: employee.fullName,
                  }))}
                  name="includedEmployees"
                  isMulti
                  value={values.employees}
                  onChange={(val) => setFieldValue('employees', val)}
                  error={errors.employees}
                />
              </>
            )}

            {values.modalType === modalTypes.ASSIGN_SHIFT && (
              <div className={styles.AssignRoleOrShiftModal_withMarginBottom}>
                <Text
                  type={textTypes.BODY.MD}
                  className={styles.AssignRoleOrShiftModal_label}
                >
                  Employee Name
                </Text>

                <Text
                  type={textTypes.HEADING.XXS}
                  colorClass={colorClasses.VIOLET['400']}
                >
                  {values.fullName || getEmployeeFullName(values.employeeId)}
                </Text>
              </div>
            )}

            <Text
              type={textTypes.BODY.MD}
              className={styles.AssignRoleOrShiftModal_label}
            >
              Schedules
            </Text>

            <Schedule
              dayIds={dayIds}
              values={{
                schedules: values.schedules,
              }}
              errors={{
                schedulesOverall: errors.schedulesOverall,
                schedules: errors.schedules,
              }}
              setFieldValue={setFieldValue}
            />
          </>
        )}
      </Formik>
    </Modal>
  );
};

AssignRoleOrShiftModal.defaultProps = {
  isAssigning: false,
};

AssignRoleOrShiftModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  formInitialValues: PropTypes.object.isRequired,
  isAssigning: PropTypes.bool,
  assignRoleOrShift: PropTypes.func.isRequired,
  createOrUpdateRoster: PropTypes.func.isRequired,
};

export default AssignRoleOrShiftModal;
