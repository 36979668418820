import React from 'react';
import styles from './styles.module.scss';

import { Card, Shine, Separator } from '../../../../components/elements';

const PreloaderProject = () => (
  <Card className={styles.PreloaderProject}>
    <div className={styles.PreloaderProject_header}>
      <Shine className={styles.PreloaderProject_header_shine} />
      <Shine className={styles.PreloaderProject_header_shine} />
      <Shine className={styles.PreloaderProject_header_shine} />
      <Shine className={styles.PreloaderProject_header_shine} />
      <Shine className={styles.PreloaderProject_header_shine} />
      <Shine className={styles.PreloaderProject_header_shine} />
      <Shine className={styles.PreloaderProject_header_shine} />
      <Shine className={styles.PreloaderProject_header_shine} />
    </div>

    <Separator className={styles.PreloaderProject_separator} />

    <div className={styles.PreloaderProject_body}>
      <Shine className={styles.PreloaderProject_body_shine} />
      <Shine className={styles.PreloaderProject_body_shine} />
      <Shine className={styles.PreloaderProject_body_shine} />
      <Shine className={styles.PreloaderProject_body_shine} />
      <Shine className={styles.PreloaderProject_body_shine} />
      <Shine className={styles.PreloaderProject_body_shine} />
      <Shine className={styles.PreloaderProject_body_shine} />
      <Shine className={styles.PreloaderProject_body_shine} />
    </div>
  </Card>
);

export default PreloaderProject;
