const settingsTabs = {
  ACCOUNT_INFORMATION: {
    name: 'Account Information',
    value: 'account-information',
  },
  EMPLOYER_DETAILS: {
    name: 'Employer Details',
    value: 'employer-details',
  },
};

export default settingsTabs;
