import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

import { Card, Shine, Separator } from '../../../../../components/elements';
import { UserContext } from '../../../../../contexts';

const PreloaderClient = () => {
  const { user } = useContext(UserContext);

  return (
    <Card
      className={cn(styles.PreloaderClient, {
        [styles.PreloaderClient___Employer]: user.userType === 'Employer',
      })}
    >
      <div className={styles.PreloaderClient_header}>
        <Shine className={styles.PreloaderClient_header_shine} />
        <Shine className={styles.PreloaderClient_header_shine} />
        <Shine className={styles.PreloaderClient_header_shine} />
        <Shine className={styles.PreloaderClient_header_shine} />
        <Shine className={styles.PreloaderClient_header_shine} />
        <Shine className={styles.PreloaderClient_header_shine} />
      </div>

      <Separator className={styles.PreloaderClient_separator} />

      <div className={styles.PreloaderClient_body}>
        <Shine className={styles.PreloaderClient_body_shine} />
        <Shine className={styles.PreloaderClient_body_shine} />
        <Shine className={styles.PreloaderClient_body_shine} />
        <Shine className={styles.PreloaderClient_body_shine} />
        <Shine className={styles.PreloaderClient_body_shine} />
        <Shine className={styles.PreloaderClient_body_shine} />
      </div>
    </Card>
  );
};

export default PreloaderClient;
