import axios from 'axios';
import config from './config';

const BASE_URL = `${config.API_URL}/rosters`;

const RostersService = {
  getRosters: (body) => axios.post(`${BASE_URL}/getRosters`, body),
  getRoles: (body) => axios.post(`${BASE_URL}/getRoles`, body),
  getRoleEmployees: (body) => axios.post(`${BASE_URL}/getRoleEmployees`, body),
  createRole: (body) => axios.post(`${BASE_URL}/createRole`, body),
  updateRole: (body) => axios.post(`${BASE_URL}/updateRole`, body),
  createOrUpdateRoster: (body) =>
    axios.post(`${BASE_URL}/createOrUpdateRoster`, body),
  deleteRole: (body) => axios.post(`${BASE_URL}/deleteRole`, body),
  deleteRoster: (body) => axios.post(`${BASE_URL}/deleteRoster`, body),
  rolloutSchedulesToNextWeek: (body) =>
    axios.post(`${BASE_URL}/rolloutSchedulesToNextWeek`, body),
};

export default RostersService;
