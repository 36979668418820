import React from 'react';
import styles from './styles.module.scss';

import { Card, Shine, Separator } from '../../../../../components/elements';

const PreloaderLarge = () => (
  <Card className={styles.PreloaderLarge}>
    <div className={styles.PreloaderLarge_header}>
      <Shine className={styles.PreloaderLarge_header_shine} />
      <Shine className={styles.PreloaderLarge_header_shine} />
      <Shine className={styles.PreloaderLarge_header_shine} />
    </div>

    <Separator className={styles.PreloaderLarge_separator} />

    <div className={styles.PreloaderLarge_body}>
      <Shine className={styles.PreloaderLarge_body_shine} />
      <Shine className={styles.PreloaderLarge_body_shine} />
      <Shine className={styles.PreloaderLarge_body_shine} />
    </div>
  </Card>
);

export default PreloaderLarge;
