import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import Cookies from 'universal-cookie';
import styles from '../styles.module.scss';

import { ControlledSelect, Spinner, Modal } from '../../../elements';
import {
  buttonTypes,
  colorNames,
  modalPositions,
  modalSizes,
  spinnerSizes,
} from '../../../../globals';
import { XeroService } from '../../../../services';

const SelectEarningRatesModal = ({
  isOpen,
  handleClose,
  tokenSet,
  tenantId,
  necessaryEarningRates,
  handleSuccess,
}) => {
  const cookies = new Cookies();
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [earningRates, setEarningRates] = useState(null);

  const validate = (values) => {
    const errors = {};

    if (!values.publicHoliday && necessaryEarningRates.publicHoliday) {
      errors.publicHoliday = 'This field is required.';
    }

    if (!values.normal && necessaryEarningRates.normal) {
      errors.normal = 'This field is required.';
    }

    if (!values.overtime && necessaryEarningRates.overtime) {
      errors.overtime = 'This field is required.';
    }

    return errors;
  };

  useEffect(async () => {
    try {
      const { data: getEarningRatesResponse } =
        await XeroService.getEarningRates({
          tokenSet,
          tenantId,
        });

      if (getEarningRatesResponse.earningRates) {
        setEarningRates(
          getEarningRatesResponse.earningRates?.map(
            ({ name, earningsRateID }) => ({
              label: name,
              value: earningsRateID,
            })
          )
        );
      }

      if (getEarningRatesResponse.tokenSet) {
        // The tokens have been refreshed, so we update the
        // xeroTokenSet in the cookies
        cookies.set('xeroTokenSet', getEarningRatesResponse.tokenSet, {
          path: '/',
        });
      }
    } catch {
      // Both access token and refresh token is invalid already.
      // We remove the xeroTokenSet in the cookies
      cookies.remove('xeroTokenSet', {
        path: '/',
      });
    }
  }, []);

  return (
    <Modal
      size={modalSizes.SM}
      position={modalPositions.CENTER}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Select Earning Rates"
      className={styles.ForgotPasswordModals}
      hasCloseButton={false}
      actions={[
        {
          text: 'Proceed',
          type: buttonTypes.PRIMARY.VIOLET,
          disabled: isSubmitting === true || !earningRates,
          onClick: () => {
            formRef.current.handleSubmit();
          },
        },
      ]}
    >
      {!earningRates ? (
        <Spinner colorName={colorNames.VIOLET} size={spinnerSizes.LG} />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{
            publicHoliday: earningRates.length > 0 ? earningRates[0] : null,
            normal: earningRates.length > 0 ? earningRates[0] : null,
            overtime: earningRates.length > 0 ? earningRates[0] : null,
          }}
          onSubmit={async (values, { setErrors }) => {
            const errors = validate(values);
            if (!isEmpty(errors)) {
              setErrors(errors);
              return;
            }

            setIsSubmitting(true);

            handleSuccess({
              earningRates: {
                publicHoliday: values.publicHoliday?.value,
                normal: values.normal?.value,
                overtime: values.overtime?.value,
              },
            });

            setIsSubmitting(false);
          }}
        >
          {({ errors, values, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              {necessaryEarningRates.publicHoliday && (
                <ControlledSelect
                  className={styles.SelectEarningRatesModal_withMarginBottom}
                  options={earningRates}
                  name="publicHoliday"
                  placeholder="Public Holiday*"
                  value={values.publicHoliday}
                  error={errors.publicHoliday}
                  onChange={(val) => {
                    setFieldValue('publicHoliday', {
                      label: val.label,
                      value: val.value,
                    });
                  }}
                />
              )}

              {necessaryEarningRates.normal && (
                <ControlledSelect
                  className={styles.SelectEarningRatesModal_withMarginBottom}
                  options={earningRates}
                  name="normal"
                  placeholder="Normal*"
                  value={values.normal}
                  error={errors.normal}
                  onChange={(val) => {
                    setFieldValue('normal', {
                      label: val.label,
                      value: val.value,
                    });
                  }}
                />
              )}

              {necessaryEarningRates.overtime && (
                <ControlledSelect
                  className={styles.SelectEarningRatesModal_withMarginBottom}
                  options={earningRates}
                  name="overtime"
                  placeholder="Overtime*"
                  value={values.overtime}
                  error={errors.overtime}
                  onChange={(val) => {
                    setFieldValue('overtime', {
                      label: val.label,
                      value: val.value,
                    });
                  }}
                />
              )}
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

SelectEarningRatesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tokenSet: PropTypes.object.isRequired,
  tenantId: PropTypes.string.isRequired,
  necessaryEarningRates: PropTypes.object.isRequired,
  // doesn't accept any parameter. This will just
  // proceed to the next modal
  handleSuccess: PropTypes.func.isRequired,
};

export default SelectEarningRatesModal;
