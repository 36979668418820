import React, { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import Preloader from './Preloader';
import { Button, Text } from '../../../../components/elements';
import { buttonTypes, textTypes } from '../../../../globals';
import { RosterContext } from '../../../../contexts';

const RosterSidebar = ({
  title,
  isRoles,
  createRoleOnClick,
  data,
  onClick,
}) => {
  const { isRolesLoading, isEmployeesLoading } = useContext(RosterContext);

  return (
    <div className={styles.RosterSidebar}>
      <div className={styles.RosterSidebar_header}>
        <Text type={textTypes.BODY.LG}>{title}</Text>

        {isRoles && (
          <Button
            className={styles.RosterSidebar_header_button}
            type={buttonTypes.PRIMARY.GREEN}
            icon="add"
            iconPosition="right"
            onClick={createRoleOnClick}
          >
            Create
          </Button>
        )}
      </div>

      <div className={styles.RosterSidebar_list}>
        {isRoles ? (
          <>
            {isRolesLoading ? (
              <>
                <Preloader />
                <Preloader />
                <Preloader />
              </>
            ) : (
              <>
                {data
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map(({ roleId, name, color, description, schedules }) => (
                    <Button
                      className={cn(
                        styles.RosterSidebar_list_button,
                        styles[`RosterSidebar_list_button___${color}`]
                      )}
                      onClick={() =>
                        onClick(roleId, name, color, description, schedules)
                      }
                      key={roleId}
                    >
                      {name}
                    </Button>
                  ))}
              </>
            )}
          </>
        ) : (
          <>
            {isEmployeesLoading ? (
              <>
                <Preloader />
                <Preloader />
                <Preloader />
              </>
            ) : (
              <>
                {data.map(({ id, fullName }) => (
                  <Button
                    className={styles.RosterSidebar_list_button}
                    onClick={() => onClick(id, fullName)}
                    key={id}
                  >
                    {fullName}
                  </Button>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

RosterSidebar.defaultProps = {
  isRoles: false,
  createRoleOnClick: null,
};

RosterSidebar.propTypes = {
  title: PropTypes.string.isRequired,
  isRoles: PropTypes.bool,
  createRoleOnClick: PropTypes.func,
  // Could be: roles array OR employees array
  data: PropTypes.array.isRequired,
  // - If isRoles is true, this function will return
  // the following values: roleId, name, color, description, schedules
  // - If isRoles is false, this function will return
  // the following value: id, fullName
  onClick: PropTypes.func.isRequired,
};

export default RosterSidebar;
