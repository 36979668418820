import { useState } from 'react';

import { ReviewsService } from '../services';

const useCreateReview = () => {
  const [isCreating, setIsCreating] = useState(false);

  const createReview = async (review) => {
    setIsCreating(true);

    let responseCode;
    let reviewId;

    try {
      const response = await ReviewsService.create({
        body: { ...review },
      });
      responseCode = response.status;
      reviewId = response.data.reviewId;
    } catch (error) {
      responseCode = error.response.status;
    }

    setIsCreating(false);

    return { responseCode, reviewId };
  };

  return { isCreating, createReview };
};

export default useCreateReview;
