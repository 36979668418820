import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import EasyEdit from 'react-easy-edit';
import styles from '../styles.module.scss';

import { Icon, Text, ControlledTextArea } from '../../elements';

const EditableTextarea = ({ initialValue, placeholder, onSave, value }) => {
  const [inputtedText, setInputtedText] = useState(value);
  const [currentText, setCurrentText] = useState(initialValue);
  const [withValue, setWithValue] = useState(true);

  return (
    <EasyEdit
      type="textarea"
      saveButtonLabel={<Icon icon="check" />}
      cancelButtonLabel={<Icon icon="close" />}
      saveButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_save
      )}
      cancelButtonStyle={cn(
        styles.Editable_editButton,
        styles.Editable_editButton_cancel
      )}
      editComponent={
        <ControlledTextArea
          inputClassName={styles.Editable_textarea}
          name="editableTextArea"
          value={inputtedText}
          onChange={(e) => { setInputtedText(e.target.value); setWithValue(false) }}
          withValue={withValue}
          withStyles={false}
          multiline
        />
      }
      displayComponent={
        <Text className={styles.Editable_displayValue}>
          {currentText
            ? currentText
                .split('\n')
                .reduce((el, a) => el.concat(a, <br />), [])
            : ''}
        </Text>
      }
      placeholder={placeholder}
      value={inputtedText}
      onSave={() => {
        if (inputtedText.length <= 1000) {
          setCurrentText(inputtedText);
          setWithValue(true);
          onSave(inputtedText);
        } else {
          setWithValue(true);
          setInputtedText(currentText);
        }
      }}
      cssClassPrefix={!currentText ? 'placeholder' : ''}
      onCancel={() => setInputtedText(currentText)}
    />
  );
};

EditableTextarea.defaultProps = {
  initialValue: null,
  placeholder: 'Empty',
};

EditableTextarea.propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default EditableTextarea;
