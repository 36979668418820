import { useState } from 'react';

import { TimesheetsService } from '../services';

const useCreateTimesheet = () => {
  const [isCreating, setIsCreating] = useState(false);

  const createTimesheet = async (timesheet) => {
    setIsCreating(true);

    let responseCode;

    try {
      const response = await TimesheetsService.create(timesheet);
      responseCode = response.status;
    } catch (error) {
      responseCode = error.response.status;
    }

    setIsCreating(false);

    return { responseCode };
  };

  return { isCreating, createTimesheet };
};

export default useCreateTimesheet;
