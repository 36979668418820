import React, { useState, useContext } from 'react';
import cn from 'classnames';
import dateFormat from 'dateformat';
import { useParams, Link } from 'react-router-dom';

import Preloader from './Preloader';
import { Breadcrumbs, TimesheetGrid } from '../../../components';
import {
  Badge,
  Button,
  ButtonLink,
  ControlledSelect,
  Grid,
  Card,
  Icon,
  Text,
} from '../../../components/elements';
import { GenerateTimesheetReportModal } from '../../../components/modals';
import {
  useProject,
  useEmployees,
  useTimesheets,
  useWindowSize,
} from '../../../hooks';
import { TimesheetContext, UserContext } from '../../../contexts';
import {
  buttonTypes,
  colorClasses,
  colorNames,
  gridTypes,
  timesheetTypes,
  textTypes,
} from '../../../globals';
import {
  convertDateTimeToTimestamp,
  convertTimeTo24HourFormat,
  getPrevOrAfterDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  hoursWithDecimalToHoursAndMinutes,
} from '../../../utils/datetime';
import {
  getDateEquivalence,
  generateDayIds,
  getHours,
} from '../../../utils/timesheets';
import styles from './styles.module.scss';
import NoResults from '../../../static/images/Misc/no-results.webp';
import task from '../../../static/images/Icons/task.png';
const ViewProject = () => {
  const { clientId, projectId } = useParams();
  const { user } = useContext(UserContext);
  const numberOfStillAnEmployee = user?.employees.filter(
    (e) => e.isStillAnEmployee === true
  ).length;
  const [viewType, setViewType] = useState({
    value: timesheetTypes.WEEKLY,
    label: `View by ${timesheetTypes.WEEKLY}`,
  });
  const [startDate, setStartDate] = useState(
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'start')
  );
  const [endDate, setEndDate] = useState(
    getDateEquivalence(timesheetTypes.WEEKLY, user.timesheetStartDate, 'end')
  );
  const [reportType, setReportType] = useState(null);

  const dayIds = generateDayIds(
    viewType.value,
    startDate,
    user.timesheetStartWeek
  );

  const { isLoading: isProjectLoading, project } = useProject({
    clientId,
    projectId,
  });

  const { isLoading: isEmployeesLoading, employees } = useEmployees({
    employerId: user.id,
    includeNoLongerEmployees: true,
  });

  // This is for the accumulated timesheets created all of their employees
  const { timesheets: allTimesheets } = useTimesheets({
    projectId,
    startDate: dateFormat(new Date('January 1, 2018'), 'yyyy-mm-dd'),
    endDate: dateFormat(new Date('January 1, 2100'), 'yyyy-mm-dd'),
  });

  // Calculate for the overall total hours using the accumulated timesheets
  let overallTotalHours = 0;
  allTimesheets.forEach(
    ({
      date,
      startTimeDetails = {
        endTime: undefined,
        actualTime: undefined,
      },
      endTimeDetails = {
        endTime: undefined,
        actualTime: undefined,
      },
      isLunchBreak,
      isTravelCharge,
      leaveType,
      publicHolidayMultiplier,
    }) => {
      const startTimeTimestamp = convertDateTimeToTimestamp(
        date,
        convertTimeTo24HourFormat(startTimeDetails.startTime)
      );
      const endTimeTimestamp = convertDateTimeToTimestamp(
        date,
        convertTimeTo24HourFormat(endTimeDetails.endTime)
      );

      overallTotalHours += Number(
        getHours(
          startTimeTimestamp,
          endTimeTimestamp,
          isLunchBreak,
          isTravelCharge,
          leaveType,
          publicHolidayMultiplier
        )
      );
    }
  );

  // This is for the timesheets from startDate to endDate
  const {
    isLoading: isTimesheetsLoading,
    timesheets,
    updateTimesheet,
    deleteTimesheet,
  } = useTimesheets({
    clientId,
    projectId,
    startDate,
    endDate,
  });

  const { windowSize } = useWindowSize();

  return (
    <div className={styles.ViewProject}>
      <div className={styles.ViewProject_firstRow}>
        <Breadcrumbs
          pageTitle="View Project"
          pages={[
            {
              name: 'Dashboard',
              link: '/employer/dashboard',
            },
            {
              name: 'Clients',
              link: '/employer/clients',
            },
            {
              name: 'View Client',
              link: `/employer/clients/view/${clientId}`,
            },
          ]}
        />

        {numberOfStillAnEmployee > 0 && (
          <div className={styles.ViewProject_reportButtons}>
            <Button
              className={styles.ViewProject_reportButtons_button}
              icon="send"
              onClick={() => setReportType('Send')}
              disabled={
                isProjectLoading || isEmployeesLoading || isTimesheetsLoading
              }
            >
              <span className={styles.ViewProject_reportButtons_button_text}>
                Send Report
              </span>
            </Button>
            <Button
              className={styles.ViewProject_reportButtons_button}
              icon="download"
              type={buttonTypes.PRIMARY.RED}
              onClick={() => setReportType('Download')}
              disabled={
                isProjectLoading || isEmployeesLoading || isTimesheetsLoading
              }
            >
              <span className={styles.ViewProject_reportButtons_button_text}>
                Download Report
              </span>
            </Button>

            {reportType !== null && (
              <GenerateTimesheetReportModal
                isOpen={reportType !== null}
                handleClose={() => setReportType(null)}
                reportType={reportType}
                viewType={viewType.value}
                startDate={startDate}
                endDate={endDate}
                employees={employees}
                timesheets={timesheets}
                employerFullName={user.fullName}
                employerEmailAddress={user.emailAddress}
                isProject
                projectId={projectId}
                clientId={clientId}
                hoursAndMinutesFormat={user?.hoursAndMinutesFormat}
              />
            )}
          </div>
        )}
      </div>

      <Text
        type={textTypes.HEADING.XS}
        className={styles.ViewProject_withMargin}
      >
        Project Details
      </Text>

      {/* {isProjectLoading ? (
        <Preloader />
      ) : (
        <div className={styles.ViewProject_secondRow}>
          <div className={styles.ViewProject_grid}>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header,
                styles.ViewProject_grid_header_action
              )}
            >
              Status
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Name
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Start Date
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Projected Finish Date
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Address
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Expected Hours
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Overall Total Hours
            </div>
            <div
              className={cn(
                styles.ViewProject_grid_column,
                styles.ViewProject_grid_header
              )}
            >
              Notes
            </div>

            <div className={styles.ViewProject_grid_column}>
              <Badge
                text={project?.status.value}
                colorName={
                  project?.status.value === 'Ongoing'
                    ? colorNames.BLUE
                    : colorNames.GREEN
                }
              />
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.name}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.startDate
                ? dateFormat(project?.startDate, 'dd/mm/yyyy')
                : '--'}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.projectedFinishDate
                ? dateFormat(project?.projectedFinishDate, 'dd/mm/yyyy')
                : '--'}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.address || '--'}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.expectedHours || '--'}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {user?.hoursAndMinutesFormat
                ? hoursWithDecimalToHoursAndMinutes(
                  overallTotalHours.toFixed(2)
                )
                : overallTotalHours.toFixed(2)}
            </div>
            <div className={styles.ViewProject_grid_column}>
              {project?.notes || '--'}
            </div>
          </div>
        </div>
      )} */}
      <Text
        type={textTypes.HEADING.XS}
        className={styles.ViewProject_withSummaryMargin}
      >
        Summary
      </Text>
      <Grid type={gridTypes.ONEBYTHREE} className={styles.ViewProject_cards}>

        <Card className={styles.ViewProject_cards_card}>
          <Grid className={styles.ViewProject_cards_card_grid}>

            <img
              src={task}
              alt="Task"
              className={styles.ViewProject_noProjects_image}
            />
            <div className={styles.ViewProject_cards_card_leftColumn1}>
              <Text
                type={textTypes.HEADING.SM}
                colorClass={colorClasses.NEUTRAL}
                className={styles.ViewProject_cards_card_nameText}
              >
                {project?.name}
              </Text>
              <div className={styles.ViewProject_cards_card_leftColumn}>
                <div className={styles.ViewProject_cards_card_leftColumnStart}>
                  <Icon
                    icon="event"
                    className={cn(
                      styles.ViewProject_cards_card_icon,
                      styles.ViewProject_cards_card_icon___nuetral
                    )}
                  />
                  <Text
                    type={textTypes.BODY.MD}
                    colorClass={colorClasses.NEUTRAL['500']}
                  >
                    Start: {project?.startDate
                      ? dateFormat(project?.startDate, 'dd/mm/yyyy')
                      : '--'}
                  </Text>
                </div>
                <div className={styles.ViewProject_cards_card_leftColumnEnd}>
                  <Icon
                    icon="event"
                    className={cn(
                      styles.ViewProject_cards_card_icon,
                      styles.ViewProject_cards_card_icon___nuetral
                    )}
                  />
                  <Text
                    type={textTypes.BODY.MD}
                    colorClass={colorClasses.NEUTRAL['500']}
                  >
                    Projected Finish: {project?.projectedFinishDate
                      ? dateFormat(project?.projectedFinishDate, 'dd/mm/yyyy')
                      : '--'}
                  </Text>
                </div>
              </div>
            </div>
          </Grid>
        </Card>
        <Card className={styles.ViewProject_cards_card1}>
          <Grid className={styles.ViewProject_cards_card1_Right}>
            <div className={styles.ViewProject_cards_card1_leftColumn}>
              <Text type={textTypes.BODY.LG}>Expected Hours</Text>
              <Text
                type={textTypes.HEADING.MD}
                colorClass={colorClasses.NEUTRAL['500']}
              >
                {project?.expectedHours || '--'}
              </Text>
            </div>

            <div className={styles.ViewProject_cards_card1_rightColumn}>
              <Icon
                icon="schedule"
                className={cn(
                  styles.ViewProject_cards_card1_icon,
                  styles.ViewProject_cards_card1_icon___lightGray
                )}
              />
            </div>
          </Grid>
        </Card>
        <Card className={styles.ViewProject_cards_card1}>
          <Grid className={styles.ViewProject_cards_card1_Right}>
            <div className={styles.ViewProject_cards_card1_leftColumn}>
              <Text type={textTypes.BODY.LG}>Actual Hours</Text>
              <Text
                type={textTypes.HEADING.MD}
                colorClass={project?.expectedHours != '' && Number(project?.expectedHours) < Number(overallTotalHours.toFixed(2)) ? colorClasses.RED['400'] : colorClasses.GREEN['400']}
              >
                {user?.hoursAndMinutesFormat
                  ? hoursWithDecimalToHoursAndMinutes(overallTotalHours.toFixed(2))
                  : overallTotalHours.toFixed(2)}
              </Text>
            </div>

            <div className={styles.ViewProject_cards_card1_rightColumn}>
              <Icon
                icon="schedule"
                className={cn(
                  styles.ViewProject_cards_card1_icon,
                  project?.expectedHours != '' && Number(project?.expectedHours) < Number(overallTotalHours.toFixed(2)) ? styles.ViewProject_cards_card1_icon___red : styles.ViewProject_cards_card1_icon___green,
                )}
              />
            </div>
          </Grid>
        </Card>
      </Grid>
      {user.employees.length > 0 ? (
        <>
          <div className={styles.ViewProject_thirdRow}>
            <div className={styles.ViewProject_navigationContainer}>
              <Text type={textTypes.HEADING.XS}>
                {viewType.value !== timesheetTypes.DAILY && (
                  <>{dateFormat(new Date(startDate), 'mmm d')} - </>
                )}

                {dateFormat(new Date(endDate), 'mmm d, yyyy')}
              </Text>

              <div
                className={styles.ViewProject_navigationContainer_navigation}
              >
                <Button
                  className={
                    styles.ViewProject_navigationContainer_navigation_item
                  }
                  icon="arrow_back"
                  onClick={() => {
                    if (viewType.value === timesheetTypes.DAILY) {
                      setStartDate(
                        getPrevOrAfterDate(startDate, 1, 'subtract')
                      );
                      setEndDate(getPrevOrAfterDate(endDate, 1, 'subtract'));
                    } else if (viewType.value === timesheetTypes.WEEKLY) {
                      setStartDate(
                        getPrevOrAfterDate(startDate, 7, 'subtract')
                      );
                      setEndDate(getPrevOrAfterDate(endDate, 7, 'subtract'));
                    } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                      setStartDate(
                        getPrevOrAfterDate(startDate, 14, 'subtract')
                      );
                      setEndDate(getPrevOrAfterDate(endDate, 14, 'subtract'));
                    } else if (viewType.value === timesheetTypes.MONTHLY) {
                      setStartDate(
                        dateFormat(
                          getFirstDayOfMonth(
                            getPrevOrAfterDate(startDate, 28, 'subtract')
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                      setEndDate(
                        dateFormat(
                          getLastDayOfMonth(
                            getPrevOrAfterDate(startDate, 28, 'subtract')
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                    }
                  }}
                >
                  <span
                    className={
                      styles.ViewProject_navigationContainer_navigation_item_buttonText
                    }
                  >
                    Prev{' '}
                    {viewType.value === timesheetTypes.DAILY
                      ? 'Day'
                      : viewType.value.slice(0, -2)}
                  </span>
                </Button>

                <ControlledSelect
                  className={
                    styles.ViewProject_navigationContainer_navigation_item
                  }
                  options={[
                    {
                      value: timesheetTypes.DAILY,
                      label: `View by ${timesheetTypes.DAILY}`,
                    },
                    {
                      value: timesheetTypes.WEEKLY,
                      label: `View by ${timesheetTypes.WEEKLY}`,
                    },
                    {
                      value: timesheetTypes.FORTNIGHTLY,
                      label: `View by ${timesheetTypes.FORTNIGHTLY}`,
                    },
                    {
                      value: timesheetTypes.MONTHLY,
                      label: `View by ${timesheetTypes.MONTHLY}`,
                    },
                  ]}
                  name="viewType"
                  placeholder="View Type"
                  value={viewType}
                  onChange={(val) => {
                    if (val.value === timesheetTypes.MONTHLY) {
                      setStartDate(
                        dateFormat(
                          getFirstDayOfMonth(
                            getDateEquivalence(
                              val.value,
                              user.timesheetStartDate,
                              'start'
                            )
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                      setEndDate(
                        dateFormat(
                          getLastDayOfMonth(
                            getDateEquivalence(
                              val.value,
                              user.timesheetStartDate,
                              'start'
                            )
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                    } else {
                      setStartDate(
                        getDateEquivalence(
                          val.value,
                          user.timesheetStartDate,
                          'start'
                        )
                      );
                      setEndDate(
                        getDateEquivalence(
                          val.value,
                          user.timesheetStartDate,
                          'end'
                        )
                      );
                    }

                    setViewType(val);
                  }}
                />

                <Button
                  className={
                    styles.ViewProject_navigationContainer_navigation_item
                  }
                  icon="arrow_forward"
                  iconPosition="right"
                  onClick={() => {
                    if (viewType.value === timesheetTypes.DAILY) {
                      setStartDate(getPrevOrAfterDate(startDate, 1, 'add'));
                      setEndDate(getPrevOrAfterDate(endDate, 1, 'add'));
                    } else if (viewType.value === timesheetTypes.WEEKLY) {
                      setStartDate(getPrevOrAfterDate(startDate, 7, 'add'));
                      setEndDate(getPrevOrAfterDate(endDate, 7, 'add'));
                    } else if (viewType.value === timesheetTypes.FORTNIGHTLY) {
                      setStartDate(getPrevOrAfterDate(startDate, 14, 'add'));
                      setEndDate(getPrevOrAfterDate(endDate, 14, 'add'));
                    } else if (viewType.value === timesheetTypes.MONTHLY) {
                      setStartDate(
                        dateFormat(
                          getFirstDayOfMonth(
                            getPrevOrAfterDate(endDate, 28, 'add')
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                      setEndDate(
                        dateFormat(
                          getLastDayOfMonth(
                            getPrevOrAfterDate(endDate, 28, 'add')
                          ),
                          'yyyy-mm-dd'
                        )
                      );
                    }
                  }}
                >
                  <span
                    className={
                      styles.ViewProject_navigationContainer_navigation_item_buttonText
                    }
                  >
                    Next{' '}
                    {viewType.value === timesheetTypes.DAILY
                      ? 'Day'
                      : viewType.value.slice(0, -2)}
                  </span>
                </Button>
              </div>
            </div>

            <ButtonLink
              to={`/employer/clients/view/${clientId}/projects/view/${projectId}/create`}
              type={buttonTypes.PRIMARY.GREEN}
              icon="add"
            >
              Create Timesheet
            </ButtonLink>
          </div>

          <TimesheetContext.Provider
            value={{
              viewType: viewType.value,
              startDate,
              endDate,
              dayIds,
              isEmployeesLoading,
              employees,
              rosters: [],
              isTimesheetsLoading,
              timesheets,
              updateTimesheet,
              deleteTimesheet,
              windowSize,
            }}
          >
            <TimesheetGrid />
          </TimesheetContext.Provider>
        </>
      ) : (
        <div className={styles.ViewProject_noEmployees}>
          <img
            src={NoResults}
            alt="TimeSheet No Employees"
            className={styles.ViewProject_noEmployees_image}
          />

          <Text
            colorClass={colorClasses.RED['400']}
            type={textTypes.HEADING.XS}
          >
            YOU CURRENTLY DON'T HAVE EMPLOYEES.
          </Text>

          <Text
            type={textTypes.HEADING.XXS}
            className={styles.ViewProject_noEmployees_withMargin}
          >
            Add one by clicking the link{' '}
            <Link
              to="/employer/employees/add"
              className={styles.ViewProject_noEmployees_link}
            >
              here
            </Link>
            .
          </Text>
        </div>
      )}
    </div>
  );
};

export default ViewProject;
