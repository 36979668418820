import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import { Card, Text, TimePicker, IconButton } from '../../elements';
import { colorClasses, iconButtonTypes, textTypes } from '../../../globals';
import {
  momentTo24HourTime,
  createMomentWithDummyDate,
} from '../../../utils/datetime';

const ScheduleCard = ({
  day,
  schedule,
  schedules,
  setFieldValue,
  error,
  disabled,
}) => (
  <>
    {!schedule && (
      <button
        type="button"
        className={cn(styles.ScheduleCard, {
          [styles.ScheduleCard___disabled]: disabled,
        })}
        onClick={() =>
          setFieldValue('schedules', [
            ...schedules,
            {
              startTime: null,
              endTime: null,
              day: day.value,
            },
          ])
        }
      >
        {day.shortName}
      </button>
    )}

    {schedule && (
      <Card className={styles.ScheduleCard___toggled}>
        {!disabled && (
          <IconButton
            icon="close"
            type={iconButtonTypes.SOLID.SM}
            className={styles.ScheduleCard_close}
            onClick={() =>
              setFieldValue(
                'schedules',
                schedules.filter((s) => s.day !== day.value)
              )
            }
          />
        )}

        <Text
          type={textTypes.HEADING.XXXS}
          colorClass={colorClasses.BLUE['300']}
          className={styles.ScheduleCard_day}
        >
          {day.shortName}
        </Text>
        <div className={styles.ScheduleCard_time}>
          <div className="picker">
            <TimePicker
              time={
                schedule.startTime
                  ? createMomentWithDummyDate(schedule.startTime)
                  : null
              }
              onChange={(newStartTime) => {
                setFieldValue(
                  'schedules',
                  schedules.map((s) =>
                    s.day === day.value
                      ? {
                          ...s,
                          startTime: momentTo24HourTime(newStartTime),
                        }
                      : s
                  )
                );
              }}
              disabled={disabled}
            />
          </div>
          <Text className={styles.ScheduleCard_to}>to</Text>
          <div className="picker">
            <TimePicker
              time={
                schedule.endTime
                  ? createMomentWithDummyDate(schedule.endTime)
                  : null
              }
              onChange={(newEndTime) => {
                setFieldValue(
                  'schedules',
                  schedules.map((s) =>
                    s.day === day.value
                      ? {
                          ...s,
                          endTime: momentTo24HourTime(newEndTime),
                        }
                      : s
                  )
                );
              }}
              disabled={disabled}
            />
          </div>
        </div>
        {error && (
          <Text
            className={styles.ScheduleCard_error}
            type={textTypes.BODY.XS}
            colorClass={colorClasses.RED['400']}
          >
            {error.startTime || error.endTime}
          </Text>
        )}
      </Card>
    )}
  </>
);

ScheduleCard.defaultProps = {
  error: null,
  disabled: false,
};

ScheduleCard.propTypes = {
  day: PropTypes.shape({
    // value 0 - 6 (Sunday - Saturday)
    value: PropTypes.number.isRequired,
    // shortName (Sun - Sat)
    shortName: PropTypes.string.isRequired,
    // name (Sunday - Saturday)
    name: PropTypes.string.isRequired,
  }).isRequired,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      // day 0 - 6 (Sunday - Saturday)
      day: PropTypes.number,
    })
  ),
  schedule: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    // day 0 - 6 (Sunday - Saturday)
    day: PropTypes.number,
  }),
  error: PropTypes.shape({
    // day 0 - 6 (Sunday - Saturday)
    day: PropTypes.number,
    // the error message for the start time
    startTime: PropTypes.string,
    // the error message for the end time
    endTime: PropTypes.string,
  }),
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ScheduleCard;
