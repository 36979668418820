import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

import {
  Button,
  ControlledInput,
  IconButton,
  Text,
} from '../../../../components/elements';
import { buttonTypes, colorClasses, textTypes } from '../../../../globals';
import { isValidDecimal } from '../../../../utils/string';

const Costs = ({ className, costs, setCosts }) => {
  const [formValues, setFormValues] = useState({
    itemName: '',
    price: '',
  });

  const [formErrors, setFormErrors] = useState({
    itemName: null,
    price: null,
  });

  return (
    <div className={cn(styles.Costs, className)}>
      {costs.map(({ itemName, price }) => (
        <div className={styles.Costs_cost}>
          <Text>{itemName}</Text>
          <Text className={styles.Costs_cost_price}>${price.toFixed(2)}</Text>
          <IconButton
            className={styles.Costs_cost_delete}
            icon="close"
            onClick={() => {
              setCosts((prevCosts) =>
                prevCosts.filter((c) => c.itemName !== itemName)
              );
            }}
          />
        </div>
      ))}

      <div className={styles.Costs_addCost}>
        <div>
          <ControlledInput
            name="itemName"
            placeholder="Item Name*"
            value={formValues.itemName}
            onChange={(e) =>
              setFormValues({ ...formValues, itemName: e.target.value })
            }
          />

          {formErrors.itemName && (
            <Text
              className={styles.Costs_addCost_error}
              type={textTypes.BODY.XS}
              colorClass={colorClasses.RED['400']}
            >
              {formErrors.itemName}
            </Text>
          )}
        </div>

        <div>
          <ControlledInput
            name="price"
            placeholder="Price*"
            value={formValues.price}
            onChange={(e) =>
              setFormValues({ ...formValues, price: e.target.value })
            }
          />

          {formErrors.price && (
            <Text
              className={styles.Costs_addCost_error}
              type={textTypes.BODY.XS}
              colorClass={colorClasses.RED['400']}
            >
              {formErrors.price}
            </Text>
          )}
        </div>

        <Button
          className={styles.Costs_addCost_button}
          type={buttonTypes.PRIMARY.GREEN}
          icon="add"
          onClick={() => {
            if (!formValues.itemName) {
              setFormErrors({
                itemName: 'This is a required field.',
                price: null,
              });

              return;
            }

            if (
              formValues.itemName &&
              costs.filter((c) => c.itemName === formValues.itemName).length > 0
            ) {
              setFormErrors({
                itemName: 'This item already exists.',
                price: null,
              });

              return;
            }

            if (!formValues.price) {
              setFormErrors({
                itemName: null,
                price: 'This is a required field.',
              });

              return;
            }

            if (formValues.price && !isValidDecimal(formValues.price)) {
              setFormErrors({
                itemName: null,
                price: 'Only numbers with a maximum of 2 decimals are allowed.',
              });

              return;
            }

            setFormErrors({
              itemName: null,
              price: null,
            });

            setCosts([
              ...costs,
              {
                itemName: formValues.itemName,
                price: Number(formValues.price),
              },
            ]);

            setFormValues({
              itemName: '',
              price: '',
            });
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

Costs.defaultProps = {
  className: null,
};

Costs.propTypes = {
  className: PropTypes.string,
  costs: PropTypes.array.isRequired,
  setCosts: PropTypes.func.isRequired,
};

export default Costs;
